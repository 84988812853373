import React, { useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import _ from 'lodash';
import styled from 'styled-components';

const CustomButton = styled(Button)`
  position: absolute;
  right: 60px;
  bottom: 60px;
  height: 68px;
  width: 68px;
  background: #e98300 !important;
  i::before {
    font-size: 20px;
    color: #ffffff !important;
  }
`;

const CustomModal = styled(Modal)`
  background: transparent !important;
  iframe {
    width: inherit;
    height: 800px;
  }
  video {
    border-radius: 10px;
  }
`;

const PlayVideoButton = ({ url, className }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const renderTrigger = () => {
    return <CustomButton circular icon="play" className={className} onClick={() => setModalOpen(true)}></CustomButton>;
  };

  return (
    <CustomModal
      size="large"
      centered={true}
      open={modalOpen}
      dimmer={true}
      trigger={renderTrigger()}
      onClose={() => setModalOpen(false)}
    >
      <i className="close" onClick={() => setModalOpen(false)}></i>
      {url.includes('youtube') ? (
        <iframe
          src={url}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      ) : (
        <video controls={true} className="fit">
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </CustomModal>
  );
};

export default PlayVideoButton;
