import React from 'react';
import Study from './Study/Study';

const ElectiveModules = ({ learningId, pathId, modules }) => {
  return (
    <div className="flex flex-start pb2 overflow-auto">
      {modules.map(m => {
        if (m.studyId)
          return (
            <div className="pr3">
              <Study key={m.studyId} learningId={learningId} pathId={pathId} module={m} elective={true} />
            </div>
          );
      })}
    </div>
  );
};

export default ElectiveModules;
