import React from 'react';
import styled from 'styled-components';
import JobWidget from './JobWidget';

const Container = styled.div`
  padding-right: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
  padding-right: 10px;
  > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Jobs = ({ jobs, selectedJobId, onChange }) => {
  return (
    <Container className="col col-5">
      <Content>
        {jobs.map(j => (
          <JobWidget key={j.id} job={j} selected={j.id === selectedJobId} onClick={() => onChange(j.id)} />
        ))}
      </Content>
    </Container>
  );
};

export default Jobs;
