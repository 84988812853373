import { inject, observer } from 'mobx-react';
import React from 'react';
import { ModuleContainer, ModuleDescription, ModuleIndex, ModuleTitle } from '../parts';
import PathIcon from '../PathIcon';
import ProgressEnum from '../../../../../models/learning/ProgressEnum';
import surveyImg from '../../../../../images/survey.svg';

const Survey = ({ learningId, pathId, icon, module, elective, disabled, learningStore }) => {
  const active = module.progress === ProgressEnum.InProgress || module.progress === ProgressEnum.Completed;

  const renderSurveyIndex = () => (
    <div className="flex justify-between">
      <ModuleIndex className={`${active ? 'active' : ''}`}>
        {(module.index + 1).toString().padStart(2, '0')}
      </ModuleIndex>
      <PathIcon icon={icon} active={active} />
    </div>
  );

  const renderActionButton = () => {
    const handleFeedbackClick = async () => {
      if (module.progress === ProgressEnum.NotStarted)
        await learningStore.startUserLearningModule(learningId, pathId, module.id);
      window.open(`/learn/${learningId}/path/${pathId}/${module.id}/survey`, '_blank').focus();
    };

    if (!module.enable)
      return (
        <button type="button" className="primary-black disabled">
          Coming Soon
        </button>
      );
    return (
      <button
        type="button"
        className={`primary-black ${disabled || module.progress === ProgressEnum.Completed ? 'disabled' : ''}`}
        onClick={handleFeedbackClick}
      >
        <span role="img" aria-label="feedback">
          📝
        </span>{' '}
        Give feedback
      </button>
    );
  };

  return (
    <ModuleContainer className="p3">
      {!elective ? renderSurveyIndex() : ''}
      <div>
        <ModuleTitle>Milestone check-in: How do you feel?</ModuleTitle>
        <ModuleDescription>{module.description}</ModuleDescription>
      </div>
      <img src={surveyImg} alt="survey" className="my3 fit" />

      <div className="flex justify-between mxn2 custom-actions">
        <div className="col-12 px2">{renderActionButton()}</div>
      </div>
    </ModuleContainer>
  );
};

export default inject('learningStore')(observer(Survey));
