import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Container, Header, Icon, Segment } from 'semantic-ui-react';
import { isStoreReloading } from '@aws-ee/base-ui/dist/models/BaseStore';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import Stores from '@aws-ee/base-ui/dist/models/Stores';
import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import { runInAction } from 'mobx';
import _ from 'lodash';
import LearningMenu from './parts/LearningMenu/LearningMenu';

const LearningPage = ({ match, learningStore, currentUserScEnvironmentsStore }) => {
  let stores;
  runInAction(() => {
    stores = new Stores([learningStore, currentUserScEnvironmentsStore]);
  });

  useEffect(() => {
    swallowError(stores.load());
    currentUserScEnvironmentsStore.startHeartbeat();
    return () => currentUserScEnvironmentsStore.stopHeartbeat();
  }, []);

  const renderContent = () => {
    if (stores.error) {
      return <ErrorBox error={stores.error} className="mt3 mr0 ml0" />;
    }
    if (stores.loading || isStoreReloading(learningStore)) {
      return <BasicProgressPlaceholder segmentCount={3} className="mt3 mr0 ml0" />;
    }
    if (stores.ready && learningStore.userLearnings.length === 0) {
      return (
        <Segment placeholder className="mt3">
          <Header icon className="color-grey">
            <Icon name="clipboard outline" />
            No Learning modules available.
          </Header>
        </Segment>
      );
    }
    if (stores.ready && learningStore.userLearnings.length > 0) {
      const userLearnings = _.sortBy(learningStore.userLearnings, ul => ul.index);
      return (
        <LearningMenu
          userLearnings={userLearnings}
          defaultUserLearningId={match.params?.userLearningId ?? userLearnings[0].id}
        />
      );
    }
    return <></>;
  };

  return (
    <Container className="mt4">
      <div className="flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Header.Content className="left-align">Learn fundamental concepts</Header.Content>
        </Header>
      </div>
      {renderContent()}
    </Container>
  );
};

export default inject('learningStore', 'currentUserScEnvironmentsStore')(withRouter(observer(LearningPage)));
