import React from 'react';
import { Modal } from 'semantic-ui-react';

const CancelDataProjectModal = ({ modalOpen, onModalStateChange }) => (
  <Modal
    size="tiny"
    centered
    open={modalOpen}
    onClose={() => onModalStateChange({ openModal: false, showParent: true })}
  >
    <div className="flex flex-column p3">
      <i className="close" onClick={() => onModalStateChange({ openModal: false, showParent: true })} />
      <h2 className="m0 mt1 self-center">
        <span role="img" aria-label="blatt">
          🍂
        </span>{' '}
        Are you sure you want to quit?
      </h2>
      <p className="my3">The information you entered will not be saved if you quit now.</p>
      <div className="mt3 custom-actions">
        <div className="col col-6 pr2">
          <button
            type="button"
            className="secondary"
            onClick={() => onModalStateChange({ openModal: false, showParent: false })}
          >
            Yes, quit now
          </button>
        </div>
        <div className="col col-6 pl2">
          <button
            type="button"
            className="primary-black"
            onClick={() => onModalStateChange({ openModal: false, showParent: true })}
          >
            No, continue editing
          </button>
        </div>
      </div>
    </div>
  </Modal>
);

export default CancelDataProjectModal;
