import React, { useState } from 'react';
import { AccordionContent, AccordionItem, AccordionTitle, Parameter } from '../parts';
import { isObject } from 'lodash';

const ParametersStep = ({ template, active, disabled, onClick, onStepCompleted }) => {
  const [parameterValues, setParameterValues] = useState({});
  const [stepCompleted, setStepCompleted] = useState();

  const countTemplateKeys = template =>
    Object.keys(template).reduce((accumulator, k) => {
      if (template[k].members) return accumulator + countTemplateKeys(template[k].members);
      else return ++accumulator;
    }, 0);

  const countValuesKeys = values =>
    Object.keys(values).reduce((accumulator, k) => {
      if (isObject(values[k])) return accumulator + countTemplateKeys(values[k]);
      else return ++accumulator;
    }, 0);

  const handleParameterValueChange = (parameterName, value) => {
    const newParameterValues = { ...parameterValues };
    if (isObject(value)) {
      const nestedKey = Object.keys(value)[0];
      if (value[nestedKey]) newParameterValues[parameterName] = { ...newParameterValues[parameterName], ...value };
      else {
        delete newParameterValues[parameterName][nestedKey];
      }
    } else {
      if (value) newParameterValues[parameterName] = value;
      else delete newParameterValues[parameterName];
    }
    setParameterValues(newParameterValues);
    if (countTemplateKeys(template) === countValuesKeys(newParameterValues)) {
      setStepCompleted(true);
      onStepCompleted(newParameterValues);
    } else {
      setStepCompleted(false);
      onStepCompleted(undefined);
    }
  };

  const parameters = template
    ? Object.keys(template)
        .map(key => ({
          parameterName: key,
          ...template[key],
        }))
        .sort((a, b) => a.order - b.order)
    : null;
  return (
    <AccordionItem>
      <AccordionTitle
        index={3}
        title="Add parameters"
        description="Specify details to start the run."
        active={active}
        completed={stepCompleted}
        disabled={disabled}
        onClick={onClick}
      />
      {parameters ? (
        <AccordionContent active={active}>
          {parameters.map((p, index) => (
            <Parameter
              key={p.parameterName}
              index={index}
              defaultValue={parameterValues[p.parameterName]}
              {...p}
              onParameterChange={handleParameterValueChange}
            />
          ))}
        </AccordionContent>
      ) : (
        <></>
      )}
    </AccordionItem>
  );
};

export default ParametersStep;
