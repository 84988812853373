import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';
import EnvironmentStatus from '../../../../../models/EnvironmentStatus';

const MaxShortTicksCount = 5;
const ShortTickPeriod = 5 * 1000;
const LongTickPeriod = 30 * 1000;
const ActionButton = ({
  studyId,
  enableWorkspace,
  workspace,
  disabled,
  onStartClick,
  onConnectClick,
  scEnvironmentsStore,
  currentUserScEnvironmentsStore,
}) => {
  const [buttonInProgress, setButtonInProgress] = useState(false);

  useEffect(() => {
    if (workspace.status) setButtonInProgress(false);
  }, [workspace.status]);

  const handleAction = async fn => {
    try {
      await fn();
      currentUserScEnvironmentsStore.startHeartbeat(ShortTickPeriod, LongTickPeriod, MaxShortTicksCount);
      currentUserScEnvironmentsStore.load();
    } catch (error) {
      displayError(error);
      setButtonInProgress(false);
    }
  };

  const handleStart = async () => {
    setButtonInProgress(true);
    await handleAction(async () => {
      if (!_.isNil(onStartClick)) await onStartClick();
      if (!_.isNil(workspace.status)) {
        await scEnvironmentsStore.startScContainerEnvironment(workspace.id);
      } else {
        await scEnvironmentsStore.launchScStatelessContainerEnvironment(studyId);
      }
    });
  };

  const handleConnect = async () => {
    setButtonInProgress(true);
    await handleAction(async () => {
      if (!_.isNil(onConnectClick)) await onConnectClick();

      const url = workspace.url;
      window.open(url, '_blank', 'noopener,noreferrer');
    });
    setButtonInProgress(false);
  };

  if (enableWorkspace === false) {
    return '';
  }
  if (
    buttonInProgress ||
    workspace.status === EnvironmentStatus.Pending ||
    workspace.status === EnvironmentStatus.Starting
  ) {
    return (
      <button type="button" className="progress justify-center">
        <div className="mr2">
          <i className="sync loading icon" />
        </div>
        Setting up...
      </button>
    );
  }
  if (_.isNil(workspace.status)) {
    return (
      <button type="button" className={`primary-black ${disabled ? 'disabled' : ''}`} onClick={handleStart}>
        {disabled ? (
          <span role="img" aria-label="lock">
            🔒
          </span>
        ) : (
          <span role="img" aria-label="rocket">
            🚀
          </span>
        )}{' '}
        Start Module
      </button>
    );
  }
  if (workspace.status === EnvironmentStatus.Stopped) {
    return (
      <button type="button" className={`primary-black ${disabled ? 'disabled' : ''}`} onClick={handleStart}>
        {disabled ? (
          <span role="img" aria-label="lock">
            🔒
          </span>
        ) : (
          <span role="img" aria-label="rocket">
            🚀
          </span>
        )}{' '}
        Start Module
      </button>
    );
  }
  if (workspace.status === EnvironmentStatus.Running) {
    return (
      <button type="button" className={`primary-blue ${disabled ? 'disabled' : ''}`} onClick={handleConnect}>
        {disabled ? (
          <span role="img" aria-label="lock">
            🔒
          </span>
        ) : (
          <span role="img" aria-label="rocket">
            ✨
          </span>
        )}{' '}
        Launch Module
      </button>
    );
  }
  return null;
};

export default inject('scEnvironmentsStore', 'currentUserScEnvironmentsStore')(observer(ActionButton));
