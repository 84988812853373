import { useClickAway } from 'ahooks';
import React, { useEffect, useRef, useState } from 'react';
import { Icon, Tab } from 'semantic-ui-react';
import styled from 'styled-components';
import ActionButton from './ActionButton';
import DataTypes from './DataTypes';
import Phenotypes from './Phenotypes';

const PaneType = {
  DataTypes: 'Data Types',
  Phenotypes: 'Phenotypes',
};

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const Content = styled.div`
  display: block;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #d7dbe3;
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 1000;
  .menu {
    .item {
      font-size: 16px !important;
      line-height: 135% !important;
      font-weight: 400 !important;
      color: #969dab;

      &.active {
        font-weight: 700 !important;
        color: #2e2d29;
      }

      &:last-child {
        margin-right: 140px !important;
      }
    }
  }
  .tab {
    background: transparent !important;
    .checkbox {
      label {
        font-weight: 450;
        font-size: 16px;
        letter-spacing: 0.1px;
        color: #2e2d29;
        padding-top: 2px;
      }
    }
  }
`;

const Filter = props => {
  const [selectedPaneIndex, setSelectedPaneIndex] = useState(0);
  const [selectedDataTypes, setSelectedDataTypes] = useState([]);
  const [selectedPhenotypes, setSelectedPhenotypes] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef(null);
  useClickAway(() => {
    setShowMenu(false);
    if (selectedDataTypes.length > 0) {
      setSelectedPaneIndex(0);
    } else if (selectedPhenotypes.length > 0) {
      setSelectedPaneIndex(1);
    }
  }, ref);
  useEffect(() => {}, [selectedPaneIndex]);

  const getPanes = () => [
    {
      menuItem: PaneType.DataTypes,
      active: selectedPaneIndex === 0,
      render: () => (
        <DataTypes
          default={selectedDataTypes}
          onChange={x => {
            setSelectedDataTypes(x);
            setSelectedPhenotypes([]);
            props.onChange({ selectedDataTypes: x, selectedPhenotypes: [] });
          }}
        />
      ),
    },
    {
      menuItem: PaneType.Phenotypes,
      active: selectedPaneIndex === 1,
      render: () => (
        <Phenotypes
          default={selectedPhenotypes}
          onChange={x => {
            setSelectedPhenotypes(x);
            setSelectedDataTypes([]);
            props.onChange({ selectedDataTypes: [], selectedPhenotypes: x });
          }}
        />
      ),
    },
  ];

  const panes = getPanes();
  return (
    <Container ref={ref}>
      <ActionButton className=" flex items-center" onClick={() => setShowMenu(true)}>
        {panes[selectedPaneIndex].menuItem} <Icon name="angle down" size="small" className="ml1" />
      </ActionButton>
      <Content className={`mt1 ml1 p3 ${showMenu ? '' : 'display-none'}`}>
        <Tab
          activeIndex={selectedPaneIndex}
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          onTabChange={e => {
            if (e.currentTarget.innerHTML === PaneType.DataTypes) setSelectedPaneIndex(0);
            else if (e.currentTarget.innerHTML === PaneType.Phenotypes) setSelectedPaneIndex(1);
          }}
        />
      </Content>
    </Container>
  );
};

export default Filter;
