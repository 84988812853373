import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled.div`
  font-family: 'IBM Plex Sans';
  margin: 30px 0px -32px 0px;
  display: flex;
  justify-content: center;
  color: #2e2d29;

  > * {
    width: 40px !important;
    height: 40px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
  }

  i:last-child {
    width: unset !important;
    height: unset !important;
  }
`;

const PageIcon = styled(Icon)`
  margin: 0px !important;
  pointer-events: ${p => (p.disabled ? 'none' : 'unset')} !important;
  cursor: ${p => (p.disabled ? 'none' : 'pointer')} !important;
`;

const PageItem = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: ${p => (p.selected ? '#E98300' : '#2e2d29')};
  background-color: ${p => (p.selected ? 'rgba(249, 164, 74, 0.1)' : 'unset')};
`;

const DotsPageItem = '...';

const getPages = count => (page, total) => {
  const range = (lo, hi) => Array.from({ length: hi - lo }, (_, i) => i + lo);
  const start = Math.max(1, Math.min(page - Math.floor((count - 3) / 2), total - count + 2));
  const end = Math.min(total, Math.max(page + Math.floor((count - 2) / 2), count - 1));
  return [
    ...(start > 2 ? [1, DotsPageItem] : start > 1 ? [1] : []),
    ...range(start, end + 1),
    ...(end < total - 1 ? [DotsPageItem, total] : end < total ? [total] : []),
  ];
};

const Pagination = ({ defaultPage, pageCount, itemsCount, onChange }) => {
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [pages, setPages] = useState(getPages(itemsCount)(defaultPage, pageCount));

  const handlePageSelect = (p, i) => {
    let newCurrentPage = p - 1;
    if (p === DotsPageItem) {
      if (i === pages.length - 2) newCurrentPage = pages[i - 1];
      if (i === 1) newCurrentPage = pages[2] - 1;
    }
    setCurrentPage(newCurrentPage);
    const newPages = getPages(itemsCount)(newCurrentPage, pageCount);
    setPages(newPages);
    onChange(newCurrentPage);
  };

  return (
    <Container className="col col-12">
      <PageIcon
        name="angle left large"
        disabled={currentPage === 0}
        onClick={() =>
          handlePageSelect(
            currentPage,
            pages.findIndex(p => p === currentPage),
          )
        }
      />
      {pages.map((p, i) => (
        <PageItem selected={p === currentPage + 1} onClick={() => handlePageSelect(p, i)}>
          {p}
        </PageItem>
      ))}
      <PageIcon
        name="angle right large"
        disabled={currentPage === pageCount - 1}
        onClick={() =>
          handlePageSelect(
            currentPage + 2,
            pages.findIndex(p => p === currentPage + 2),
          )
        }
      />
    </Container>
  );
};

export default Pagination;
