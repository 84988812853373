import React from 'react';
import styled from 'styled-components';

const Email = styled.a`
  color: #f5961a;
  font-family: Roboto;
  text-decoration: underline;
`;

const SupportEmail = ({ email }) => (
  <>
    If you have questions, contact us: <Email href={`mailto: ${email}`}>{email}</Email>
  </>
);

export default SupportEmail;
