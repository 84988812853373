import styled from 'styled-components';

const ActionButton = styled.button`
  padding: 0px 16px;
  border: none;
  border-radius: 8px;
  background: #2e2d29;
  color: #fafafa;
  font-family: IBM Plex Mono;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  height: 40px;
  cursor: pointer;

  :disabled {
    background: #969dab;
    color: #fafafa;
    pointer-events: none;
  }
`;

export default ActionButton;
