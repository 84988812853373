import styled from 'styled-components';

export const QuestionHeader = styled.div`
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  line-height: 135%;
`;

export const QuestionIndex = styled.p`
  font-size: 16px;
  color: #969dab;
`;

export const QuestionStatement = styled.p`
  font-size: 24px;
  color: #2e2d29;
  margin-top: -8px;
  word-break: break-all;
  white-space: break-spaces;
`;
