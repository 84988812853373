/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import React from 'react';
import { decorate, action, computed, runInAction, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  Header,
  Button,
  Checkbox,
  Toggle,
  Radio,
  Segment,
  Accordion,
  Icon,
  Popup,
  Label,
  Modal,
} from 'semantic-ui-react';
import c from 'classnames';
import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import { isStoreReady } from '@aws-ee/base-ui/dist/models/BaseStore';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';

import StudyFilesTable from './StudyFilesTable';
import StudyPermissionsTable from './StudyPermissionsTable';
import UploadStudyFiles from './UploadStudyFiles';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'semantic-ui-react';
import { Divider } from 'semantic-ui-react';

// expected props
// - study (via props)
// - isSelectable (via props)
// - filesSelection (via injection)
class StudyRow extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.filesExpanded = false;
      this.permissionsExpanded = false;
    });

    this.state = {};
    this.handleFileSelection = this.handleFileSelection.bind(this);
    // console.log(this.props.userStore.user.isAdmin)
  }

  componentDidMount() {
    const store = this.envsStore;
    swallowError(store.load());
    store.startHeartbeat();
  }

  componentWillUnmount() {
    const store = this.envsStore;
    store.stopHeartbeat();
  }

  state = {};

  creatingWorkspace = false;

  get study() {
    return this.props.study;
  }

  get isSelectable() {
    return this.props.isSelectable && this.study.state.canSelect;
  }

  get envsStore() {
    return this.props.scEnvironmentsStore;
  }

  get environment() {
    return this.props.environment;
  }

  getConnectionStore() {
    return this.envsStore.getScEnvConnectionStore(this.environment.id);
  }

  handleFileSelection = study => {
    const selection = this.props.filesSelection;
    selection.cleanup();

    const { id, name, description } = study;
    // TODO: actually do different statuses?
    selection.setFile({ id, name, description, accessStatus: 'approved' });
    this.props.handleCreateWorkspace(study);
  };

  handleFilesExpanded = () => {
    this.filesExpanded = !this.filesExpanded;
  };

  handlePermissionsExpanded = () => {
    this.permissionsExpanded = !this.permissionsExpanded;
  };

  // handleRadioChange(event, { value }) {
  //   event.persist();
  //   console.log('VALUE: ', { value })
  //   this.setState({ value });
  // }

  handleConnect = async () => {
    await this.handleAction(async () => {
      const store = this.getConnectionStore();
      swallowError(await store.load());
      const connection = this.environment.getConnections()[0];
      let url = connection.url;
      console.log(connection);

      try {
        if (url) {
          // We use noopener and noreferrer for good practices https://developer.mozilla.org/en-US/docs/Web/API/Window/open#noopener
          console.log('in If: ', url);
          openWindow(url, 'noopener,noreferrer');
        } else {
          console.log('in else: ', connection.id);
          url = await store.createConnectionUrl(connection.id);
          // const notebookUrl = url[0] + '/notebooks/' + this.study.id + '.ipynb?' + url[1];
          // window.open(notebookUrl, '_blank');
          window.open(url, '_blank');
        }
      } catch (error) {
        displayError(error);
      }
    });
  };

  handleStart = async () => {
    await this.handleAction(async () => {
      const store = this.envsStore;
      await store.startScEnvironment(this.props.environment.id);
    });
  };

  handleAction = async fn => {
    this.processing = true;
    try {
      await fn();
    } catch (error) {
      displayError(error);
    } finally {
      runInAction(() => {
        this.processing = false;
      });
    }
  };

  renderActionButtons = study => {
    if (study.enableWorkspace)
      return (study.isComingSoon === true && this.props.userStore.user.isAdmin) ||
        (study.isComingSoon === true &&
          (this.props.userStore.user.username === 'kexhincha@stanford.edu' ||
            this.props.userStore.user.uid === 'u-N3VDmJFS5rAZL9kSC_W6m' ||
            this.props.userStore.user.uid === 'u-zU-9yt-5hfatNehfrZIzw' ||
            this.props.userStore.user.uid === 'u-yYRnHigzYmlCYhiX9rY1P' ||
            this.props.userStore.user.uid === 'u-yL6fMmAa-0bcacpCWrCkF')) ? (
        [
          <Button
            icon
            labelPosition="right"
            className="ml2 one-click-btn study-btn coming-soon-btn"
            floated="right"
            disabled
            ariaDisabled="true"
            color="blue"
          >
            <div>Coming Soon</div>
          </Button>,
          !!this.props.environment
            ? this.renderConnectButton(this.props.environment)
            : this.renderCreateWorkspaceButton(study),
        ]
      ) : study.isComingSoon === true && !this.props.userStore.user.isAdmin ? (
        <div>
          <Button
            icon
            labelPosition="right"
            className="ml2 one-click-btn study-btn coming-soon-btn"
            floated="right"
            disabled
            ariaDisabled="true"
            color="blue"
          >
            <div>Coming Soon</div>
          </Button>
        </div>
      ) : !!this.props.environment ? (
        this.renderConnectButton(this.props.environment)
      ) : (
        this.renderCreateWorkspaceButton(study)
      );
  };

  render() {
    const isSelectable = this.isSelectable; // Internal and external guests can't select studies
    const study = this.study;
    const selection = this.props.filesSelection;
    const isSelected = selection.hasFile(study.id);
    const attrs = {};
    const onClickAttr = {};

    if (isSelected) attrs.color = 'blue';
    if (isSelectable) onClickAttr.onClick = () => this.handleFileSelection(study);
    // console.log(this.props.userStore.user.uid)
    return (
      <Segment clearing padded raised className="mb3" {...attrs}>
        <div data-testid="study-card" className="flex">
          <div className="flex-auto mb1">
            {this.renderStatus(study.state)}
            {this.renderHeader(study)}
            {this.renderDescription(study)}
            {this.renderFilesAccordion(study)}
            {this.renderPermissionsAccordion(study)}
          </div>
          <div className="mr2 flex-auto study-connect-btns">{this.renderActionButtons(study)}</div>
        </div>
        <Modal
          closeIcon={false}
          onClose={() => this.setState({ surveyCheckModalOpen: false })}
          onOpen={() => this.setState({ surveyCheckModalOpen: true })}
          open={this.state.surveyCheckModalOpen}
          size="large"
          className="modal--survey"
        >
          <Modal.Header>Help us with a survey?</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <p>It will take 5 - 7 minutes to get your learning module ready.</p>
              <p>
                While you are waiting, we invite you to fill out a short survey. This survey helps us understand how
                much you already know about the knowledge in this learning module. We will use this information to
                improve the platform.
              </p>
              {/* */}
              {study.survey ? (
                <iframe
                  src={`${this.props.study.survey}?userId=${this.props.userStore.user.email}&studyName=${this.props.study.name}`}
                  height="80%"
                  width="100%"
                ></iframe>
              ) : (
                <iframe
                  src={`https://stanfordmedicine.qualtrics.com/jfe/form/SV_cT3l0DrAeRtQB5Y?userId=${this.props.userStore.user.email}&studyName=${this.props.study.name}`}
                  height="80%"
                  width="100%"
                ></iframe>
              )}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              onClick={() => {
                this.setState({ surveyCheckModalOpen: false });
              }}
            >
              Done
            </Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    );
  }

  renderCreateWorkspaceButton(study) {
    const isSelectable = this.isSelectable;
    const onClickAttr = {};

    if (isSelectable)
      onClickAttr.onClick = () => {
        this.handleFileSelection(study);
        this.setState({ surveyCheckModalOpen: true });
      };

    return (
      <div>
        {isSelectable ? (
          <div>
            <Button
              icon
              labelPosition="right"
              className="ml2 one-click-btn study-btn"
              floated="right"
              {...onClickAttr}
              color="blue"
            >
              <div>Start</div>
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
  renderConnectButton(environment) {
    const onClickAttr = {};

    if (environment.state.canStart) onClickAttr.onClick = () => this.handleStart();
    if (environment.state.canConnect) onClickAttr.onClick = () => this.handleConnect();

    return (
      <div>
        {environment.state.spinner && (
          <Button floated="right" color={environment.state.color}>
            <Icon name="spinner" loading />
            {environment.state.key == 'PENDING' ? 'STARTING' : environment.state.display}
          </Button>
        )}
        {environment.state.key == 'PENDING' && (
          <Button
            floated="right"
            basic
            color="red"
            size="small"
            className="mt1 mb1 clear--both"
            // onClick={() => window.open('https://stanfordmedicine.qualtrics.com/jfe/form/SV_cT3l0DrAeRtQB5Y?userId=' + this.props.userStore.user.email + '&studyName=' + this.props.study.name, '_blank').focus()}
            onClick={() => {
              this.setState({ surveyCheckModalOpen: true });
            }}
          >
            Take a Pre-Evaluation Survey
          </Button>
        )}
        {environment.state.canConnect && (
          <div>
            <div>
              <Button floated="right" color={environment.state.color} {...onClickAttr}>
                Connect
              </Button>
              <Divider hidden clearing />
            </div>
          </div>
        )}
        {environment.state.key == 'STOPPED' && (
          <div>
            <div>
              <Button floated="right" color="red" className="restart-btn" {...onClickAttr}>
                Start
              </Button>
              <Divider hidden clearing />
            </div>
          </div>
        )}
      </div>
    );
  }

  renderHeader(study) {
    const isSelectable = false; // Internal and external guests can't select studies
    const onClickAttr = {};

    if (isSelectable) onClickAttr.onClick = () => this.handleFileSelection(study);

    return (
      <div>
        <Header as="h3" color="black" className={c('mt2', isSelectable ? 'cursor-pointer' : '')} {...onClickAttr}>
          {study.uploadLocationEnabled && study.canUpload && <UploadStudyFiles studyId={study.id} />}
          {study.name}
          <Header.Subheader>
            <span className="pt1 fs-8 color-grey">{study.id}</span>
            {study.projectId && <span className="fs-8 color-grey"> &middot; {study.projectId}</span>}
          </Header.Subheader>
        </Header>
      </div>
    );
  }

  renderDescription(study) {
    const descriptionLinkRegex = /\((?:https?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*)\)/;
    const linkMatches = study.description && study.description.match(descriptionLinkRegex);
    const description =
      !_.isNil(linkMatches) && linkMatches.length > 0
        ? study.description.replace(descriptionLinkRegex, '')
        : study.description;

    return (
      <div className="margin-top--double">
        <p style={{ whiteSpace: 'nowrap' }}>
          {description}{' '}
          {!_.isNil(linkMatches) && linkMatches.length > 0 ? (
            <a href={linkMatches[0].substring(1, linkMatches[0].length - 1)} target="_blank">
              Read Publication &gt;
            </a>
          ) : (
            ''
          )}
        </p>
        <br />
        {/* <br /> <iframe width="560" height="315" src={`https://351869726285-sdo-prd-va-sdo-prd-study-videos.s3.us-east-2.amazonaws.com/${this.study.id}.mp4`} title="Data Ocean Video" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        <video width="620" height="380" controls="true">
          <source
            src={`https://351869726285-sdo-prd-va-sdo-prd-study-videos.s3.us-east-2.amazonaws.com/${this.study.id}.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }

  renderStatus(state) {
    // Do not show a label if it is default/reachable
    if (state && (state.key === 'default' || state.key === 'reachable')) return null;

    return (
      <div style={{ cursor: 'default' }}>
        <Popup
          trigger={
            <Label attached="top left" size="mini" color={state.color}>
              {state.spinner && <Icon name="spinner" loading />}
              {state.display}
            </Label>
          }
        >
          {state.tip}
        </Popup>
      </div>
    );
  }

  renderFilesAccordion(study) {
    if (study.isOpenDataStudy) return null;
    if (!study.uploadLocationEnabled) return null;
    const expanded = this.filesExpanded;

    if (this.props.userStore.user.isAdmin) {
      return (
        <Accordion className="mt2">
          <Accordion.Title active={expanded} index={0} onClick={this.handleFilesExpanded}>
            <Icon name="dropdown" />
            <b>Files</b>
          </Accordion.Title>
          <Accordion.Content active={expanded}>
            {expanded && study.uploadLocationEnabled && (
              <div className="mb2">
                <StudyFilesTable study={study} />
              </div>
            )}
          </Accordion.Content>
        </Accordion>
      );
    }
  }

  renderPermissionsAccordion(study) {
    if (!study.isOrganizationStudy) return null;
    const expanded = this.permissionsExpanded;

    if (this.props.userStore.user.isAdmin) {
      return (
        <Accordion className="mt0">
          <Accordion.Title active={expanded} index={0} onClick={this.handlePermissionsExpanded}>
            <Icon name="dropdown" />
            <b>Permissions</b>
          </Accordion.Title>
          <Accordion.Content active={expanded}>{expanded && <StudyPermissionsTable study={study} />}</Accordion.Content>
        </Accordion>
      );
    }
  }
}

decorate(StudyRow, {
  handleFileSelection: action,
  handleFilesExpanded: action,
  handlePermissionsExpanded: action,
  handleStart: action,
  handleConnect: action,
  handleAction: action,
  getUrl: action,
  study: computed,
  filesExpanded: observable,
  permissionsExpanded: observable,
  isSelectable: computed,
  envsStore: computed,
  environment: computed,
});

export default inject('filesSelection', 'userStore', 'scEnvironmentsStore')(observer(StudyRow));
