import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import ProgressEnum from '../../../../models/learning/ProgressEnum';
import PathProgress from './PathProgress';
import PathModules from './PathModules';
import ElectiveModules from './ElectiveModules';
import Badge from './Badge';
import CertificateAnnouncement from './CertificateAnnouncement';

const Content = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template: 1fr / 1fr;
  margin-top: 32px;
  > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
`;

const ModulePathContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22.25%;
  z-index: -1;
`;

const Electives = styled.div`
  border-top: 1px solid #d7dbe3;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.3px;
    color: #2e2d29;
  }
`;

const Path = ({ learningId, learningName, subscriptionRequired, subscription, path, certificateProgram, disabled }) => {
  const pathCompleted = path.modules.every(s => s.progress === ProgressEnum.Completed);

  return (
    <div>
      {subscriptionRequired && (subscription == null || !subscription.activated) ? (
        <CertificateAnnouncement learningId={learningId} learningName={learningName} {...certificateProgram} />
      ) : (
        <></>
      )}
      <Content>
        <ModulePathContainer>
          <PathProgress modules={path.modules} />
        </ModulePathContainer>
        <div>
          <PathModules
            learningId={learningId}
            pathId={path.id}
            modules={path.modules}
            pathIcon={path.icon}
            disabled={disabled}
          />
          <Badge pathName={path.name} modules={path.modules} />
        </div>
      </Content>
      {!_.isNil(path.electiveModules) && path.electiveModules.length > 0 && pathCompleted ? (
        <Electives className="py3">
          <h1>Electives</h1>
          <ElectiveModules learningId={learningId} pathId={path.id} modules={path.electiveModules} />
        </Electives>
      ) : (
        ''
      )}
    </div>
  );
};

export default Path;
