import React, { useState } from 'react';
import styled from 'styled-components';
import { ActionButton, Title5 } from '../../parts';
import { inject } from 'mobx-react';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';
import TestAccessState from './TestAccessState';
import StepContainer from './StepContainer';
import CopyButton from '../../parts/CopyButton';

const Container = styled(StepContainer)`
  margin: 24px 0px 0px 0px;
`;

const PolicyBlock = styled.div`
  margin: 10px auto;
  > div {
    margin-bottom: 25px;
  }
  > pre {
    position: relative;
    margin: 16px 0px;
    padding: 16px;
    background: #f5f5f5;
    border: 1px solid #d7dbe3;
    border-radius: 4px;
    overflow: auto;
    > code {
      color: #53565a;
      font-family: IBM Plex Mono;
      line-height: normal;
    }
  }
`;

const FirstStep = ({ code, paths, onStateChange, newWorkflowStore }) => {
  const [testAccessLoading, setTestAccessLoading] = useState([]);
  const [testAccessFailed, setTestAccessFailed] = useState([]);
  const [testAccessSuccess, setTestAccessSuccess] = useState([]);

  const selectedPath = i => {
    return code.length === 1 ? paths : { [Object.keys(paths)[i]]: Object.values(paths)[i] };
  };

  const handleTestAccessClick = async i => {
    setTestAccessFailed(prev => prev.filter(item => item !== i));
    setTestAccessLoading(prev => [...prev, i]);
    setTestAccessSuccess(prev => prev.filter(item => item !== i));
    try {
      const response = await newWorkflowStore.testWorkflowS3Access(selectedPath(i));
      if (response.state === TestAccessState.Error) setTestAccessFailed(prev => [...prev, i]);
      else if (response.state === TestAccessState.ErrorKMS || response.state === TestAccessState.Success)
        setTestAccessSuccess(prev => [...prev, i]);
      onStateChange(response.state);
    } catch (error) {
      displayError(error);
    }
    setTestAccessLoading(prev => prev.filter(item => item !== i));
  };

  const codeFormatting = policy => {
    return JSON.stringify(JSON.parse(policy), null, 2);
  };

  const dynamicBlockHead = (index, no) => {
    return code?.length > 1 ? (index == 0 ? 'Input' : 'Output') : no == 0 ? 'Input & output' : 'same';
  };

  return (
    <Container>
      <Title5>1. Update the S3 Bucket Policy</Title5>
      <p>We need to update the bucket policy to allow the IAM role to have read and write access.</p>
      <ol>
        <li>
          In the AWS Management Console, find the <b>“Services”</b> dropdown and select <b>“S3”</b> under{' '}
          <b>“Storage”</b>.
        </li>
        <li>Find your bucket in the list and click on its name.</li>
        <li>Click on “Permissions” tab.</li>
        <li>Click on “Bucket Policy”.</li>
        <li>
          Paste the following template, replacing <b className="green">[YourBucketName]</b> with the name of your bucket
          and <b>[YourRoleARN]</b>
          with the Amazon Resource Name (ARN) of the IAM role.If you are granting access to a specific prefix rather
          than the entire bucket, replace <b className="green">"[YourBucketName]/</b>" in the “Resource” section with{' '}
          <b className="green">"[YourBucketName]/[YourPrefix]/".</b>
        </li>
        <li>
          Click <b>“Save”</b>.
        </li>
      </ol>
      {code?.map((policy, index) => {
        return (
          <PolicyBlock key={index}>
            <p>
              <b>{dynamicBlockHead(index, 0)} bucket policy:</b> Copy the template below for the{' '}
              {dynamicBlockHead(index, 1).toLowerCase()} bucket.{' '}
            </p>
            <pre>
              <CopyButton onClick={() => navigator.clipboard.writeText(codeFormatting(policy))}>Copy</CopyButton>
              <code>{codeFormatting(policy)}</code>
            </pre>
            <ActionButton
              loading={testAccessLoading.includes(index)}
              loadingText="Testing.."
              errorText={
                testAccessFailed.includes(index) ? 'Please check all information is correct, and try again.' : undefined
              }
              successText={testAccessSuccess.includes(index) ? 'Access granted.' : undefined}
              disabled={testAccessLoading.includes(index)}
              onClick={() => handleTestAccessClick(index)}
            >
              Test access
            </ActionButton>
          </PolicyBlock>
        );
      })}
    </Container>
  );
};

export default inject('newWorkflowStore')(FirstStep);