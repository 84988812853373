import React, { useState } from 'react';
import { Accordion, Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import Title from './Title';
import QuestionsAndAnswers from './QuestionsAndAnswers';
import ActionButton from './ActionButton';
import { inject } from 'mobx-react';
import ResultModalContent from './ResultModalContent';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';

const CustomAccordion = styled(Accordion)`
  margin: 32px 0px 0px 0px;
  padding: 64px 32px 0px 32px;
  border-radius: 8px;
  border: 1px solid rgba(233, 131, 0, 0.3);
  background: #fff9f0;
`;

const CustomAccordionTitle = styled(Accordion.Title)`
  display: flex;
  flex-direction: column;
  padding: 0px !important;
  cursor: default !important;

  i {
    align-self: center;
    cursor: pointer;
    margin: 16px 0px;
    ::before {
      color: #e98300;
    }
  }
`;

const CustomAccordionContent = styled(Accordion.Content)`
  margin-top: 16px;
  padding: 0px !important;
  > div {
    display: flex;
    flex-direction: column;
    i {
      align-self: center;
      cursor: pointer;
      margin: 16px 0px;
      ::before {
        color: #e98300;
      }
    }
  }
`;

const AccessFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 32px 0px 0px 0px;

  form {
    display: flex;
    gap: 16px;
    height: 40px;

    input {
      width: 450px;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid #d7dbe3;
      background: #fff;
      font-family: IBM Plex Sans;
      font-size: 16px;
      font-weight: 450;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #2e2d29;

      ::placeholder {
        color: #969dab;
      }
    }
  }
`;

const CertificateAnnouncement = ({
  learningId,
  description,
  joinUrl,
  price,
  financialAid,
  questionsAndAnswers,
  learningStore,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [code, setCode] = useState('');
  const [isValidCode, setIsValidCode] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleCodeSubmit = async e => {
    e.preventDefault();
    try {
      await learningStore.activateUserLearningSubscription(learningId, code);
      setIsValidCode(true);
    } catch (e) {
      setIsValidCode(false);
      displayError(e);
    }
    setModalOpen(true);
  };

  const renderResultModal = () => {
    if (isValidCode == false)
      return (
        <ResultModalContent
          title="👋 Something wrong with your code."
          description="The scholarship code you entered doesn’t seem to work. Double check to see if all characters are correct."
          action="Try again"
          onClose={() => setModalOpen(false)}
        />
      );
    else if (isValidCode)
      return (
        <ResultModalContent
          title="🚀 You have full access now. Let’s go!"
          description="You scholarship code allows you to access all the leaning modules and certification exam in the Bioinformatics Certificate track."
          action="Awesome"
          onClose={() => {
            setModalOpen(false);
            window.location.reload();
          }}
        />
      );
  };

  return (
    <>
      <CustomAccordion>
        <CustomAccordionTitle active={accordionOpen}>
          <Title
            description={description}
            joinUrl={joinUrl}
            price={price}
            applicationUrl={financialAid.enabled ? financialAid.applicationUrl : undefined}
          />
          <AccessFormContainer>
            <form onSubmit={handleCodeSubmit}>
              <input
                placeholder="Add access code if you have paid or received scholarship."
                onChange={e => setCode(e.target.value)}
              />
              <ActionButton onClick={handleCodeSubmit} disabled={code.length === 0}>
                Submit
              </ActionButton>
            </form>
          </AccessFormContainer>
          {!accordionOpen ? <Icon name="angle down large" onClick={() => setAccordionOpen(true)} /> : <></>}
        </CustomAccordionTitle>
        <CustomAccordionContent active={accordionOpen}>
          <div>
            <QuestionsAndAnswers questionsAndAnswers={questionsAndAnswers} />
            <Icon name="angle down large" flipped="vertically" onClick={() => setAccordionOpen(false)} />
          </div>
        </CustomAccordionContent>
      </CustomAccordion>
      <Modal size="small" centered open={modalOpen} onClose={() => setModalOpen(false)}>
        {renderResultModal()}
      </Modal>
    </>
  );
};

export default inject('learningStore')(CertificateAnnouncement);
