import React, { useState } from 'react';
import { Button, Icon, Form, Card, Divider, Tab, Header, Message } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { handleAddNewDomain, handleAddAdmin, handleNewStudy } from './AdminPageHelpers.js/AdminPageHelpers';

const UserManagementTab = ({ usersStore, studiesStoresMap }) => {
  const [userId, setuserId] = useState('');
  const [domainName, setDomainName] = useState('');
  const [studyId, setStudyId] = useState('');

  const usersList = usersStore.list;
  const studiesList = studiesStoresMap.organization.list;

  const usersListSelectOptions = [];
  const studiesListSelectOptions = [];

  // eslint-disable-next-line no-unused-vars
  const usersListSelectOptionsMap = usersList.map(user => {
    const userArray = {};
    userArray.key = user.uid;
    userArray.text = user.email;
    userArray.value = user.uid;
    return usersListSelectOptions.push(userArray);
  });

  // eslint-disable-next-line no-unused-vars
  const studiesListSelectOptionsMap = studiesList.map(study => {
    const studiesArray = {};
    studiesArray.key = study.id;
    studiesArray.text = study.id;
    studiesArray.value = study.id;
    return studiesListSelectOptions.push(studiesArray);
  });

  // const handleUserDelete = event => {
  //   event.preventDefault();
  //   // userEmail = event.target.elements.email.value;
  //   console.log(event.target.elements.email.value);
  //   // const idp = event.target.elements.idp.value;
  //   console.log(event.target.elements.idp.value);
  // };

  return (
    <Tab.Pane attached={false}>
      <Divider horizontal>
        <Header as="h4">
          <Icon name="secret user" />
          User Management
        </Header>
      </Divider>

      {studiesListSelectOptions.length ? (
        <Message
          warning
          icon="info"
          header="Please dont refresh this page, the studies dropdown data will be lost."
          content="This is a work in progress feature."
        />
      ) : (
        <Message
          error
          icon="arrow circle left"
          header="Ooops."
          content="Please go to the Workspaces tab to re-load the studies data."
        />
      )}

      <Divider hidden />

      <Card.Group stackable itemsPerRow={3} className="p1">
        <Card fluid color="orange" className="mb3 p2">
          <Card.Header as="h3" className="mb2 custom-card-header ">
            <Icon name="at" size="large" /> | Add approved domain
          </Card.Header>
          <Card.Meta className="mb1">Email Domain (stanford.edu, .edu, .org, etc):</Card.Meta>
          <Card.Content>
            <Form onSubmit={() => handleAddNewDomain(domainName)} className="custom-card">
              <Form.Input
                placeholder="Enter domain..."
                label="Domain: "
                id="domain"
                onChange={e => setDomainName(e.target.value)}
                required
              />

              <Button color="orange" type="submit">
                Add domain
              </Button>
            </Form>
          </Card.Content>
        </Card>
        {/* 
        <Card fluid color="orange" className="mb3 p2">
          <Card.Header as="h3" className="mb2 custom-card-header">
            <Icon name="user delete" size="large" /> | Delete user
          </Card.Header>
          <Card.Meta floated="right" className="mb1">
            Work in progress
          </Card.Meta>
          <Card.Content>
            <Form onSubmit={handleUserDelete} className="custom-card">
              <Form.Input disabled placeholder="Enter user email..." label="user Email: " id="email" required />
              <Form.Input disabled placeholder="Enter User IdP..." label="User IdP: " id="idp" required />
              <Button disabled color="orange" type="submit">
                Delete User
              </Button>
              <Message
                error
                header="Action Forbidden"
                content="You can only sign up for an account once with a given e-mail address."
              />
              <Message success header="Form Completed" content="You're all signed up for the newsletter" />
            </Form>
          </Card.Content>
        </Card> */}

        <Card fluid color="orange" className="mb3 p2">
          <Card.Header as="h3" className="mb2 custom-card-header ">
            <Icon name="user secret" size="large" /> | Add backend office user
          </Card.Header>
          <Card.Meta className="mb1">Add users to back office page</Card.Meta>
          <Card.Content>
            <Form className="custom-card" onSubmit={() => handleAddAdmin(userId)}>
              <Form.Select
                label="Select user email"
                id="userId"
                name="userId"
                placeholder="Select your user email"
                options={usersListSelectOptions}
                required
                onChange={(e, data) => setuserId(data.value)}
              />

              <Button color="orange" type="submit">
                Add User
              </Button>
            </Form>
          </Card.Content>
        </Card>

        <Card fluid color="orange" className="mb3 p2">
          <Card.Header as="h3" className="mb2 custom-card-header">
            <Icon name="users" size="large" /> | Add all users to a study
          </Card.Header>
          <Card.Meta className="mb1">
            Beaware this adds <strong>all</strong> users to this study
          </Card.Meta>
          <Card.Content>
            <Form className="custom-card" onSubmit={() => handleNewStudy(studyId)}>
              <Form.Select
                label="Study ID:"
                id="studyid"
                name="studyid"
                placeholder="Select your study ID"
                options={studiesListSelectOptions}
                required
                onChange={(e, data) => setStudyId(data.value)}
              />
              <Button color="orange" type="submit">
                Add new users
              </Button>
            </Form>
          </Card.Content>
        </Card>
      </Card.Group>
    </Tab.Pane>
  );
};

export default inject('usersStore', 'studiesStoresMap')(observer(UserManagementTab));
