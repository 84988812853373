import styled from 'styled-components';

const ActionItem = styled.div`
  font-family: 'IBM Plex Sans';

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    color: #2e2d29;
  }
  p {
    font-weight: 450;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #969dab;
    word-break: break-word;
    white-space: pre-wrap;
  }
`;

export default ActionItem;
