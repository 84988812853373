import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';
import styled from 'styled-components';
import { PaginatedProgress } from '../../components';
import RatingScaleQuestion from './RatingScaleQuestion';
import TextQuestion from './TextQuestion';

const PageSize = 5;

const QuestionType = {
  RatingScale: 'RatingScale',
  Text: 'Text',
};

const Container = styled.div`
  height: 100%;
  padding: 60px 150px 30px 150px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const SurveyContent = ({ userLearningId, pathId, moduleId, survey, surveyStore }) => {
  const [answers, setAnswers] = useState(new Map());
  const [pageIndex, setPageIndex] = useState(0);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const ref = useRef(null);

  useEffect(() => {}, [answers]);
  const nextHandler = async index => {
    setPageIndex(index);
    ref.current.scroll({ top: 0, behavior: 'smooth' });
  };

  const submitHandler = async () => {
    try {
      setSubmissionInProgress(true);
      const submission = {
        userLearningId,
        pathId,
        moduleId,
        questions: Array.from(answers, ([questionId, answer]) => ({ questionId, answer })),
      };
      await surveyStore.createSurveySubmission(survey.id, submission);
      setSubmissionInProgress(false);
      window.open(`/learn/${userLearningId}`, '_self').focus();
    } catch (error) {
      setSubmissionInProgress(false);
      displayError(error);
    }
  };

  const updateAnswer = (questionId, type, value) => {
    answers.set(questionId, { type, value });
    setAnswers(new Map([...answers]));
  };

  const questions = survey.questions.slice(pageIndex * PageSize, pageIndex * PageSize + PageSize);
  return (
    <Container className="overflow-auto" ref={ref}>
      {questions.map((q, i) => {
        if (q.type === QuestionType.RatingScale)
          return (
            <RatingScaleQuestion
              key={q.id}
              index={pageIndex * PageSize + i}
              {...q}
              disabled={submissionInProgress}
              onClick={v => updateAnswer(q.id, q.type, v)}
            />
          );
        else if (q.type === QuestionType.Text)
          return (
            <TextQuestion
              key={q.id}
              index={pageIndex * PageSize + i}
              {...q}
              disabled={submissionInProgress}
              onTextChange={v => updateAnswer(q.id, q.type, v)}
            />
          );

        return <></>;
      })}
      <PaginatedProgress
        pageIndex={pageIndex}
        progress={answers.size}
        total={survey.questions.length}
        pageSize={PageSize}
        progressIds={answers}
        totalIds={questions.map(q => q.id)}
        loading={submissionInProgress}
        onNext={nextHandler}
        onSubmit={submitHandler}
      />
    </Container>
  );
};

export default inject('surveyStore')(withRouter(observer(SurveyContent)));
