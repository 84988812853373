import { types } from 'mobx-state-tree';
import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import { getPhenotypes } from '../../helpers/api';
import { Phenotype } from './Phenotype';

const PhenotypesStore = BaseStore.named('PhenotypesStore')
  .props({
    phenotypes: types.array(Phenotype),
  })
  .actions(self => {
    return {
      async doLoad() {
        const phenotypes = await getPhenotypes();
        self.runInAction(() => {
          self.phenotypes = phenotypes;
        });
      },
    };
  })
  .views(() => ({}));

function registerContextItems(appContext) {
  appContext.phenotypesStore = PhenotypesStore.create({}, appContext);
}

export { PhenotypesStore, registerContextItems };
