import React from 'react';
import styled from 'styled-components';
import Timeline from '../../parts/Timeline';
import { Grid } from 'semantic-ui-react';
import Study from './Study';

const Container = styled.div`
  display: flex;
  padding: 8.571em;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Course = styled.div`
  margin-bottom: ${p => (p.last ? '0px' : '5.714em')};
  width: 100%;
`;

const Schedule = styled.h4`
  font-family: 'IBM Plex Mono';
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  color: #e98300;
  margin: 0px 0px 16px 0px;
  min-width: 160px;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  margin: 0px 0px 24px 0px;
`;

const Topics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Topic = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const TopicTitle = styled.h4`
  width: 164px;
  margin: 50px 0px 0px 0px !important;
  color: rgba(233, 131, 0, 0.7);
  font-size: 16px;
  font-family: IBM Plex Mono;
  font-weight: 500;
  line-height: 140%;
  @media (max-width: 992px) {
    width: unset;
  }
`;

const SubTopics = styled.div`
  max-width: 720px;
  padding: 40px 50px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 25px 63px 0px rgba(89, 95, 123, 0.1);
`;

const Courses = ({ courses }) => {
  return (
    <Container>
      <Content>
        <Timeline
          items={courses.map((c, i) => ({
            key: `${c.title}-${i}`,
            render: () => (
              <Course last={i === courses.length - 1}>
                <Schedule>{c.schedule}</Schedule>
                <Title>{c.title}</Title>
                <Topics>
                  {c.topics.map((t, i) => (
                    <Topic key={`${t.title}-${i}`}>
                      <TopicTitle>{t.title}</TopicTitle>
                      <SubTopics>
                        <Grid stackable columns={t.subTopics.length > 3 ? 3 : t.subTopics.length}>
                          {_.chunk(t.subTopics, 3).map((c, cIndex) =>
                            c.map((st, i) => (
                              <Grid.Column key={`${st.title}-${i}`}>
                                <Study
                                  index={cIndex * 3 + i}
                                  title={st.title}
                                  description={st.description}
                                  icon={st.icon}
                                />
                              </Grid.Column>
                            )),
                          )}
                        </Grid>
                      </SubTopics>
                    </Topic>
                  ))}
                </Topics>
              </Course>
            ),
          }))}
        ></Timeline>
      </Content>
    </Container>
  );
};

export default Courses;
