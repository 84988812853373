import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ModuleContainer, ModuleDescription, ModuleIndex, ModuleTitle } from '../parts';
import PathIcon from '../PathIcon';
import ProgressEnum from '../../../../../models/learning/ProgressEnum';
import ActionButton from './ActionButton';
import QuizModal from './QuizModal';

const Video = styled.video`
  height: 230px;
  width: 100%;
`;

const Study = ({ learningId, pathId, icon, module, elective, disabled, learningStore }) => {
  useEffect(() => {}, [module.workspace.status]);

  const active = module.progress === ProgressEnum.InProgress || module.progress === ProgressEnum.Completed;

  const renderStudyIndex = () => (
    <div className="flex justify-between">
      <ModuleIndex className={`${active ? 'active' : ''}`}>
        {(module.index + 1).toString().padStart(2, '0')}
      </ModuleIndex>
      <PathIcon icon={icon} active={active} />
    </div>
  );

  const renderActionButton = () => {
    const startModule = async () => {
      if (module.progress === ProgressEnum.NotStarted)
        await learningStore.startUserLearningModule(learningId, pathId, module.id);
    };
    if (!module.enable)
      return (
        <ActionButton
          studyId={module.studyId}
          enableWorkspace={true}
          workspace={module.workspace}
          disabled={false}
          onStartClick={startModule}
          onConnectClick={startModule}
        />
      );
    return (
      <ActionButton
        studyId={module.studyId}
        enableWorkspace={module.enableWorkspace}
        workspace={module.workspace}
        disabled={disabled}
        onStartClick={startModule}
        onConnectClick={startModule}
      />
    );
  };

  const renderQuizButton = () => {
    if (!module.enable)
      return (
        <button type="button" className="secondary disabled">
          Take Quiz
        </button>
      );
    return <QuizModal learningId={learningId} pathId={pathId} module={module} disabled={disabled} />;
  };

  return (
    <ModuleContainer className="p3">
      {!elective ? renderStudyIndex() : ''}
      <div>
        <ModuleTitle>{module.name}</ModuleTitle>
        <ModuleDescription>{module.description}</ModuleDescription>
      </div>
      <Video controls className="my3 fit">
        <source
          src={`https://351869726285-sdo-prd-va-sdo-prd-study-videos.s3.us-east-2.amazonaws.com/${module.studyId}.mp4`}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </Video>
      <div className="flex justify-between mxn2 custom-actions">
        {module.enableWorkspace ? <div className="col-6 px2">{renderActionButton()}</div> : <div className="col-6 px2">{renderActionButton()}</div>}
        {!_.isNil(module.quizId) && !_.isEmpty(module.quizId) && !elective ? (
          <div className={`${module.enableWorkspace ? 'col-6' : 'col-12'} px2`}>{renderQuizButton()}</div>
        ) : (
          ''
        )}
      </div>
    </ModuleContainer>
  );
};

export default inject('learningStore')(observer(Study));
