import _ from 'lodash';
import React from 'react';
import { decorate, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Header, Label, Popup, Icon, Divider, Message, Table, Grid, Segment } from 'semantic-ui-react';
import TimeAgo from 'react-timeago';
import { niceNumber, swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import { isStoreLoading, isStoreNotEmpty, isStoreError } from '@aws-ee/base-ui/dist/models/BaseStore';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import ProgressPlaceHolder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';

import By from '../helpers/By';
import ScEnvironmentButtons from './parts/ScEnvironmentButtons';
import ScEnvironmentConnections from './parts/ScEnvironmentConnections';
import ScEnvironmentCost from './parts/ScEnvironmentCost';

// expected props
// - scEnvironment (via prop)
// - envTypesStore (via injection)
class ScEnvironmentCard extends React.Component {
  get envTypesStore() {
    return this.props.envTypesStore;
  }

  get environment() {
    return this.props.scEnvironment;
  }

  get envType() {
    const env = this.props.scEnvironment;
    const store = this.envTypesStore;
    const envType = store.getEnvType(env.envTypeId);

    return envType;
  }

  handleCopy = () => {
    this.textArea.select();
    document.execCommand('copy');
  };

  getEnvTypeConfigsStore() {
    const configsStore = this.envTypesStore.getEnvTypeConfigsStore(this.environment.envTypeId);
    return configsStore;
  }

  getConfiguration(envTypeConfigId) {
    const configsStore = this.getEnvTypeConfigsStore();
    const config = configsStore.getEnvTypeConfig(envTypeConfigId);
    return config;
  }

  componentDidMount() {
    const configsStore = this.getEnvTypeConfigsStore();
    swallowError(configsStore.load());
  }

  render() {
    const configsStore = this.getEnvTypeConfigsStore();
    let content = null;

    if (isStoreError(configsStore)) {
      content = <ErrorBox error={configsStore.error} className="p0" />;
    } else if (isStoreLoading(configsStore)) {
      content = <ProgressPlaceHolder segmentCount={3} />;
    } else if (isStoreNotEmpty(configsStore)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return content;
  }

  renderMain() {
    const env = this.environment;
    const state = env.state;

    return (
      <>
        <div className="flex-wrapper--row workspaces-flex flex--wrap" style={{ marginLeft: '-.75em' }}>
          {this.renderStatus(state)}
          {this.renderButtons(env)}
        </div>
        {this.renderTitle(env)}
        {this.renderError(env)}

        <Divider className="mt1" />
        {env.description || 'No description was provided for this workspace.'}
        <Grid columns={2} stackable className="mt2">
          <Grid.Row stretched>
            <Grid.Column width={12}>{this.renderDetailTable(env)}</Grid.Column>
            {this.renderCost(env)}
          </Grid.Row>
        </Grid>
      </>
    );
  }

  renderDetailTable(env) {
    const studyIds = _.get(env, 'studyIds', []);
    const studyCount = _.size(_.get(env, 'studyIds', []));
    const envType = this.envType || {};

    const renderRow = (key, value, buttonName, buttonAction, buttonIcon) => (
      <div className="workspace-details--wrapper">
        <span className="workspace-details--label">{key}</span>
        {buttonName ? (
          <div className="textarea--wrapper">
            <textarea
              className="workspace-details--content textarea"
              readonly
              ref={textarea => (this.textArea = textarea)}
              value={value}
            >
              {value}
            </textarea>
            <button onClick={buttonAction} className="workspace-details--button">
              <i aria-hidden="true" className={buttonIcon}></i>
              {buttonName}
            </button>
          </div>
        ) : (
          <div className="workspace-details--content">{value}</div>
        )}
      </div>
    );

    return (
      <div>
        {renderRow('Data from', studyCount === 0 ? 'No studies linked to this workspace' : studyIds.join(', '))}
        {renderRow('Workspace ID', env.id, 'Copy', this.handleCopy, 'copy outline icon')}
      </div>
    );
  }

  renderButtons(env) {
    return <ScEnvironmentButtons scEnvironment={env} showDetailButton />;
  }

  renderStatus(state) {
    return (
      <div style={{ cursor: 'default' }}>
        <Popup
          trigger={
            <Label attached="top left" size="mini" color={state.color}>
              {state.spinner && <Icon name="spinner" loading />}
              {state.display == 'FAILED' ? (
                <i aria-hidden="true" class="cancel icon"></i>
              ) : state.display == 'AVAILABLE' ? (
                <i aria-hidden="true" class="circle icon"></i>
              ) : state.display == 'PAUSED' ? (
                <i aria-hidden="true" class="pause icon"></i>
              ) : state.display == 'STOPPED' ? (
                <i aria-hidden="true" class="square icon"></i>
              ) : state.display == 'TERMINATED' ? (
                <i aria-hidden="true" class="ban icon"></i>
              ) : (
                <span></span>
              )}
              {state.display}
            </Label>
          }
        >
          {state.tip}
        </Popup>
      </div>
    );
  }

  renderCost(env) {
    return (
      <Grid.Column width={4}>
        <Segment className="flex items-center">
          <div className="w-100 overflow-hidden">
            <ScEnvironmentCost envId={env.id} />
          </div>
        </Segment>
      </Grid.Column>
    );
  }

  renderTitle(env) {
    return (
      <Header as="h3" className="mt1">
        {env.name}
        <Header.Subheader>
          <span className="fs-8 color-grey">
            Created <TimeAgo date={env.createdAt} className="mr2" /> <By uid={env.createdBy} className="mr2" />
          </span>
        </Header.Subheader>
      </Header>
    );
  }

  renderError(env) {
    if (_.isEmpty(env.error)) return null;

    return (
      <Message negative>
        <p>{env.error}</p>
      </Message>
    );
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(ScEnvironmentCard, {
  envTypesStore: computed,
  environment: computed,
  envType: computed,
});

export default inject('envTypesStore', 'userStore')(withRouter(observer(ScEnvironmentCard)));
