import React, { useState } from 'react';
import { Container, Header, Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import DataStudies from './parts/data-studies/DataStudies';
import MyProjects from './parts/my-projects/MyProjects';
import DataProjectModal from './parts/modals/DataProjectModal';
import CancelDataProjectModal from './parts/modals/CancelDataProjectModal';
import TabContainer from '../components/TabContainer';

const PaneType = {
  Data: 'Data',
  MyProjects: 'My Projects',
};

const DataPage = ({ dataProjectsStore }) => {
  const [selectedPane, setSelectedPane] = useState();
  const [selected, setSelected] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [createdModalChecked, setCreatedModalChecked] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const createModalStateChangeHandler = ({ openModal, showCancel }) => {
    setCreateModalOpen(openModal);
    setCancelModalOpen(showCancel);
  };

  const cancelModalStateChangeHandler = ({ openModal, showParent }) => {
    setCancelModalOpen(openModal);
    setCreateModalOpen(showParent);
  };

  const checkboxClickHandler = (_, data) => {
    let tempChecked = [];
    if (data.checked) tempChecked = [...createdModalChecked, data.value];
    else {
      const index = createdModalChecked.indexOf(data.value);
      if (index !== -1) {
        createdModalChecked.splice(index, 1);
        tempChecked = [...createdModalChecked];
      }
    }
    setCreatedModalChecked(tempChecked);
  };

  const createProjectHandler = async () => {
    await dataProjectsStore.create({
      name: projectName,
      description: projectDescription,
      studyIds: createdModalChecked,
    });
  };

  const renderTitle = () => {
    return (
      <div className="flex justify-between">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Header.Content className="left-align">Data</Header.Content>
          <Header.Subheader className="left-align">
            Use datasets from research publications to create projects.
          </Header.Subheader>
        </Header>
        <div className="custom-actions">
          <button
            type="button"
            className={`primary-black px3 py1 ${selectedPane === PaneType.MyProjects ? 'disabled' : ''}`}
            onClick={() => {
              setCreateModalOpen(true);
              setCreatedModalChecked(selected.map(s => s.id));
              setProjectName('');
              setProjectDescription('');
            }}
          >
            ⭐ Create project
          </button>
          <DataProjectModal
            modalOpen={createModalOpen && !cancelModalOpen}
            studies={selected}
            projectName={projectName}
            projectDescription={projectDescription}
            onCheckboxClick={checkboxClickHandler}
            onProjectNameChange={setProjectName}
            onProjectDescriptionChange={setProjectDescription}
            onModalStateChange={createModalStateChangeHandler}
            onSubmit={createProjectHandler}
          />
          <CancelDataProjectModal
            modalOpen={!createModalOpen && cancelModalOpen}
            onModalStateChange={cancelModalStateChangeHandler}
          />
        </div>
      </div>
    );
  };

  const panes = [
    {
      menuItem: PaneType.Data,
      render: () => <DataStudies onSelectedChange={d => setSelected([...d])} />,
    },
    {
      menuItem: PaneType.MyProjects,
      render: () => <MyProjects />,
    },
  ];
  return (
    <Container className="mt4 pb4">
      {renderTitle()}
      <TabContainer>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          className="tab-container"
          onTabChange={e => {
            if (e.currentTarget.innerHTML === PaneType.Data) setSelectedPane(PaneType.Data);
            else if (e.currentTarget.innerHTML === PaneType.MyProjects) setSelectedPane(PaneType.MyProjects);
            setSelected([]);
          }}
        />
      </TabContainer>
    </Container>
  );
};

export default withRouter(inject('dataProjectsStore')(observer(DataPage)));
