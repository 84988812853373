import { types } from 'mobx-state-tree';
import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import { getJobs, createJobApplication, toggleJobBookmark, getJob, getJobIds } from '../../helpers/api';
import { Job } from './Job';

const JobsStore = BaseStore.named('JobsStore')
  .props({
    ids: types.array(types.string),
    jobs: types.array(Job),
  })
  .actions(self => {
    return {
      async doLoad(limit, startKey) {
        const ids = await getJobIds();
        const jobs = await getJobs(limit, startKey);
        self.runInAction(() => {
          self.ids = ids;
          self.jobs = jobs;
        });
      },

      async getJob(jobId) {
        const job = await getJob(jobId);
        const old = self.jobs.find(j => j.id === jobId);
        self.runInAction(() => {
          old.set(job);
        });
        return old;
      },

      async createJobApplication(jobId) {
        const result = await createJobApplication(jobId);
        if (result)
          self.runInAction(() => {
            const job = self.jobs.find(j => j.id === jobId);
            const application = result.jobs.find(j => j.jobId === jobId).application;
            job.set({
              ...job,
              application,
            });
          });
      },

      async toggleJobBookmark(jobId) {
        const result = await toggleJobBookmark(jobId);
        if (result)
          self.runInAction(() => {
            const job = self.jobs.find(j => j.id === jobId);
            const bookmark = result.jobs.find(j => j.jobId === jobId).bookmark;
            job.set({
              ...job,
              bookmark,
            });
          });
      },
    };
  })
  .views(() => ({}));

function registerContextItems(appContext) {
  appContext.jobsStore = JobsStore.create({}, appContext);
}

export { JobsStore, registerContextItems };
