import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { observable, action, decorate, runInAction, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Button, Form, Grid, Header, Segment, Label, Input, Image } from 'semantic-ui-react';
import { displayError } from '../helpers/notification';
import { branding } from '../helpers/settings';
import PlayVideoButton from './PlayVideoButton';
import styled from 'styled-components';
import localStorageKeys from '../models/constants/local-storage-keys';
import { storage } from '../helpers/utils';

const Container = styled.div`
  height: 100%;
  > div {
    height: 100%;
    > div {
      height: inherit;
      margin: 0px !important;
    }
    .row {
      padding: 0px !important;
    }
  }
`;

const Left = styled(Grid.Column)`
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background-color: #ffffff;
  height: 100%;
  padding: 0 15% !important;
  > form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .segment {
      border: none;
      max-width: 403px;
      img {
        width: 70%;
      }

      &::after {
        content: none !important;
      }
    }
  }
`;

const Right = styled(Grid.Column)`
  background-color: #fff6eb;
  height: 100%;
`;

const Description = styled.h3`
  font-weight: 450;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: #2e2d29;
  margin: 32px 0px 0px 0px;
`;

const AuthenticationProviders = styled.div`
  margin-top: 54px;
  button {
    font-weight: 700;
    font-family: 'IBM Plex Sans';
    height: 40px;
    width: 100%;
    border-radius: 4px;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    margin-bottom: 16px;

    &.primary-black {
      background: #2e2d29;
      border: none;
      color: #fafafa;
    }
    &.secondary {
      background: #ffffff;
      color: #2e2d29;
      cursor: pointer;
      border: 2px solid;
    }
  }
`;

const SignUp = styled.h3`
  margin-top: 16px;
  font-size: 16px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: #e98300;
  a {
    font-weight: 400;
    text-decoration: underline;
    color: #e98300;
  }
`;

const TermsOfUseContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  text-align: center;
  padding: 0px 4px;
`;

const TermsOfUse = styled.p`
  color: #969dab;
  font-size: 16px;
  font-family: IBM Plex Sans;
  font-weight: 450;
  letter-spacing: 0.4px;
  max-width: 410px;
  margin: 0px 0px 64px 0px !important;
  a {
    color: #969dab;
    font-size: 16px;
    font-weight: 450;
    letter-spacing: 0.4px;
    text-decoration: underline;
  }
`;

class Login extends React.Component {
  constructor(props) {
    super(props);

    runInAction(() => {
      this.username = '';
      this.password = '';
      this.loading = false;

      this.authenticationProviderError = '';
      this.usernameError = '';
      this.passwordError = '';

      const authentication = this.props.authentication;
      authentication.setSelectedAuthenticationProviderId(_.get(this.authProviderOptions, '[0].key', ''));
    });
  }

  getStore() {
    return this.props.authenticationProviderPublicConfigsStore;
  }

  get authProviderOptions() {
    const params = new URL(document.location).searchParams;
    const showInternal = _.isString(params.get('internal'));
    const store = this.getStore();
    const options = store.authenticationProviderOptions;
    const size = _.size(options);

    if (size <= 1) return options;

    return _.filter(store.authenticationProviderOptions, config => {
      if (config.key === 'internal' && showInternal) return true;
      return config.key !== 'internal';
    });
  }

  handleChange = name =>
    action(event => {
      this[name] = event.target.value;
      if (name === 'username') this.usernameError = '';
      if (name === 'password') this.passwordError = '';
    });

  handleAuthenticationProviderChange = action((_event, { value }) => {
    console.log(value);
    this.props.authentication.setSelectedAuthenticationProviderId(value);
  });

  handleAuthenticationProviderChangeButton = value => {
    console.log(value);
    this.props.authentication.setSelectedAuthenticationProviderId(value);
    console.log(this.props.authentication.selectedAuthenticationProviderId);
    this.handleLoginFromButtons();
  };

  handleLoginFromButtons = () => {
    const username = _.trim(this.username) || '';
    const password = this.password || '';
    const selectedAuthenticationProviderId = this.props.authentication.selectedAuthenticationProviderId || '';
    console.log(selectedAuthenticationProviderId);
    let error = false;
    if (_.isEmpty(selectedAuthenticationProviderId)) {
      this.authenticationProviderError = 'please select authentication provider';
      error = true;
    }

    const authentication = this.props.authentication;

    Promise.resolve()
      .then(() =>
        authentication.login({
          username,
          password,
        }),
      )
      .catch(err => displayError(err))
      .finally(
        action(() => {
          this.loading = false;
          storage.setItem(localStorageKeys.newLogin, 'true');
        }),
      );
  };

  handleLogin = action(event => {
    event.preventDefault();
    event.stopPropagation();

    this.authenticationProviderError = '';
    this.usernameError = '';
    this.passwordError = '';
    const username = _.trim(this.username) || '';
    const password = this.password || '';
    const selectedAuthenticationProviderId = this.props.authentication.selectedAuthenticationProviderId || '';
    let error = false;

    if (_.isEmpty(selectedAuthenticationProviderId)) {
      this.authenticationProviderError = 'please select authentication provider';
      error = true;
    }

    const collectUserNamePassword = this.props.authentication.shouldCollectUserNamePassword;
    if (collectUserNamePassword) {
      if (_.isEmpty(username)) {
        this.usernameError = 'username is required';
        error = true;
      }

      if (!_.isEmpty(username) && username.length < 3) {
        this.usernameError = 'username must be at least 3 characters long';
        error = true;
      }

      if (_.isEmpty(password)) {
        this.passwordError = 'password is required';
        error = true;
      }
      if (!_.isEmpty(password) && password.length < 4) {
        this.passwordError = 'password must be at least 4 characters long';
        error = true;
      }
    }

    if (error) return;

    const authentication = this.props.authentication;
    this.loading = true;

    Promise.resolve()
      .then(() =>
        authentication.login({
          username,
          password,
        }),
      )
      .catch(err => displayError(err))
      .finally(
        action(() => {
          this.loading = false;
          storage.setItem(localStorageKeys.newLogin, 'true');
        }),
      );
  });

  render() {
    const error = !!(this.usernameError || this.passwordError || this.authenticationProviderError);
    const authenticationProviderOptions = this.authProviderOptions;

    const renderAuthenticationProviders = () => {
      if (authenticationProviderOptions && authenticationProviderOptions.length > 0) {
        return (
          <AuthenticationProviders>
            {authenticationProviderOptions.map((item, i) => (
              <button
                type="submit"
                className={`${i % 2 === 0 ? 'primary-black' : 'secondary'}`}
                onClick={() => this.handleAuthenticationProviderChangeButton(item.key)}
              >
                {item.text}
              </button>
            ))}
          </AuthenticationProviders>
        );
      }
      return '';
    };

    const collectUserNamePassword = this.props.authentication.shouldCollectUserNamePassword;

    return (
      <Container className="animated fadeIn">
        <div className="desktop-only">
          <Grid textAlign="center" columns={2} verticalAlign="middle">
            <Grid.Row>
              <Left width={10}>
                <Form
                  error={error}
                  loading={this.loading}
                  onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Segment stacked>
                    <Image centered src={this.props.assets.images.logoImage} />
                    <Description>{branding.login.subtitle}</Description>
                    {renderAuthenticationProviders()}
                    <SignUp>
                      Don't have an account?{' '}
                      <a href="https://stanfordmedicine.qualtrics.com/jfe/form/SV_e9B59rQNQUIzIdU">
                        Sign up here.
                      </a>
                    </SignUp>
                    {collectUserNamePassword && (
                      <Form.Field error={!!this.usernameError} required>
                        <Input
                          fluid
                          icon="user"
                          iconPosition="left"
                          placeholder="Username"
                          data-testid="username"
                          value={this.username}
                          onChange={this.handleChange('username')}
                        />
                        {this.usernameError && (
                          <Label basic color="red" pointing className="float-left mb2">
                            {this.usernameError}
                          </Label>
                        )}
                      </Form.Field>
                    )}
                    {collectUserNamePassword && (
                      <Form.Field error={!!this.passwordError} required>
                        <Input
                          fluid
                          icon="lock"
                          iconPosition="left"
                          placeholder="Password"
                          data-testid="password"
                          value={this.password}
                          type="password"
                          onChange={this.handleChange('password')}
                        />
                        {this.passwordError && (
                          <Label basic color="red" pointing className="float-left mb2">
                            {this.passwordError}
                          </Label>
                        )}
                      </Form.Field>
                    )}
                    {collectUserNamePassword && (
                      <Button
                        data-testid="login"
                        type="submit"
                        color="blue"
                        fluid
                        basic
                        size="large"
                        className="mb2"
                        onClick={this.handleLogin}
                      >
                        Login
                      </Button>
                    )}
                  </Segment>
                </Form>
                <TermsOfUse>
                  By signing up, you acknowledge that you have read, understood, and agreed to Data Ocean’s{' '}
                  <a href="https://stanforduniversity.qualtrics.com/ControlPanel/File.php?F=F_ZRR4Ponbyg2izGC">
                    Terms of Use
                  </a>
                  .
                </TermsOfUse>
              </Left>
              <Right width={6} className="flex justify-center px0">
                <img src={this.props.assets.images.loginBackground} alt="login-background" />
                <PlayVideoButton url="https://www.youtube.com/embed/b1mGDUeSB04" />
              </Right>
            </Grid.Row>
          </Grid>
        </div>
        <div className="mobile-only px2 py4">
          <div className="flex flex-column">
            <img src={this.props.assets.images.logoImage} alt="logo" className="col-5 ml2 mb4" />
            <img src={this.props.assets.images.mobileLoginBackground} alt="login-background" className="col-12" />
            <Header as="h2" textAlign="center">
              Visit on large screens.
              <Header.Subheader className="mt2">
                We are not optimized for small screens yet.
                <br />
                Please visit us on desktop-sized browsers.
              </Header.Subheader>
            </Header>
            <TermsOfUseContainer>
              <TermsOfUse>
                By clicking “Stanford email/other school-issued email”, you acknowledge that you have read, understood,
                and agreed to Data Ocean’s{' '}
                <a href="https://stanforduniversity.qualtrics.com/ControlPanel/File.php?F=F_ZRR4Ponbyg2izGC">
                  Terms of Use
                </a>
                .
              </TermsOfUse>
            </TermsOfUseContainer>
          </div>
        </div>
      </Container>
    );
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(Login, {
  username: observable,
  password: observable,
  loading: observable,
  authenticationProviderError: observable,
  usernameError: observable,
  passwordError: observable,
  authProviderOptions: computed,
});

export default inject(
  'authentication',
  'authenticationProviderPublicConfigsStore',
  'assets',
)(withRouter(observer(Login)));
