import React, { useState } from 'react';
import { Accordion } from 'semantic-ui-react';
import styled from 'styled-components';
import SequenceDataStep from './SequenceDataStep';
import WorkflowStep from './WorkFlowStep';
import ParametersStep from './ParametersStep';
import { ActionButton } from './parts';
import ReviewModal from './ReviewModal';

const Container = styled.div`
  background: #fff;
  border-radius: 8px;
`;

const Content = styled.div``;

const Action = styled.div`
  display: flex;
  padding: 30px;
  align-items: center;
  justify-content: center;
  > button {
    padding: 12px 32px;
  }
`;

const SetupRun = ({ onNewRun }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [lastCompletedStep, setLastCompletedStep] = useState(-1);
  const [renderNextPage, setRenderNextPage] = useState();
  const [config, setConfig] = useState();
  const [selectedWorkflow, setSelectedWorkflow] = useState();
  const [parameters, setParameters] = useState();
  const [reviewModalOpen, setReviewModalOpen] = useState();

  const handleClick = index => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Container>
      {renderNextPage ? renderNextPage() : <></>}
      <Content hidden={Boolean(renderNextPage)}>
        <Accordion>
          <SequenceDataStep
            active={activeIndex === 0}
            onClick={() => handleClick(0)}
            onShowNextPage={render => setRenderNextPage(render)}
            onStepCompleted={config => {
              setActiveIndex(1);
              setLastCompletedStep(0);
              setConfig(config);
            }}
          />
          <WorkflowStep
            defaultWorkflow={selectedWorkflow}
            active={activeIndex === 1}
            disabled={lastCompletedStep < 0}
            onClick={() => handleClick(1)}
            onStepCompleted={workflow => {
              setActiveIndex(2);
              setLastCompletedStep(1);
              setSelectedWorkflow(workflow);
            }}
          />
          <ParametersStep
            template={selectedWorkflow?.template}
            active={activeIndex === 2}
            disabled={lastCompletedStep < 1}
            onClick={() => handleClick(2)}
            onStepCompleted={parameters => {
              if (parameters) {
                setLastCompletedStep(2);
              } else {
                setLastCompletedStep(1);
              }
              setParameters(parameters);
            }}
          />
        </Accordion>
        <Action>
          <ActionButton disabled={lastCompletedStep !== 2} onClick={() => setReviewModalOpen(true)}>
            🔎 Review before run
          </ActionButton>
          {reviewModalOpen ? (
            <ReviewModal
              config={config}
              workflow={selectedWorkflow}
              parameters={parameters}
              open={reviewModalOpen}
              onClose={() => setReviewModalOpen(false)}
              onComplete={onNewRun}
            />
          ) : (
            <></>
          )}
        </Action>
      </Content>
    </Container>
  );
};

export default SetupRun;
