import React from 'react';
import { Modal } from 'semantic-ui-react';

const DeleteConfirmationModal = ({ modalOpen, onSubmit, onCancel }) => (
  <Modal size="tiny" centered open={modalOpen} onClose={onCancel}>
    <div className="flex flex-column p3">
      <i className="close" onClick={onCancel} />
      <h2 className="m0 mt1 self-center">
        <span role="img" aria-label="trash">
          🗑️
        </span>{' '}
        Are you sure you want to delete the VM?
      </h2>
      <p className="my3">
        Your project on the virtual machine will be permanently deleted. This action is not reversible.
        <br />
        <br />
        If you still need to access the project at a later time, please choose to pause the machine instead.
      </p>
      <div className="mt3 custom-actions">
        <div className="col col-6 pr2">
          <button type="button" className="secondary" onClick={onSubmit}>
            Yes, delete VM
          </button>
        </div>
        <div className="col col-6 pl2">
          <button type="button" className="primary-black" onClick={onCancel}>
            No, do later
          </button>
        </div>
      </div>
    </div>
  </Modal>
);

export default DeleteConfirmationModal;
