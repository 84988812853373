import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';
import ChatBot from './ChatBot';
import styled from 'styled-components';

const ChatbotModal = styled(Modal)`
  width: 100% !important;
  height: 100%;
  margin: 0px !important;
  top: 0px;
  background: #f5f5f5 !important;
  overflow: hidden;
  > div {
    height: 100%;
  }
`;

const ChatbotPage = ({}) => {
  return (
    <ChatbotModal open>
      <ChatBot showMaximize={false} />
    </ChatbotModal>
  );
};

export default inject()(withRouter(observer(ChatbotPage)));
