import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  > h2 {
    font-size: 20px;
    font-weight: 700;
    margin: 0px;
  }
  > p {
    font-size: 16px;
    font-weight: 400;
    margin: 0px;
    > a {
      color: #2e2d29;
      text-decoration: underline;
    }
  }
`;

const TitleAndDescription = ({ title, description }) => (
  <Container>
    <h2>{title}</h2>
    <p>{description}</p>
  </Container>
);

export default TitleAndDescription;
