import { default as styled } from 'styled-components';
import correctAnswerImg from '../../../../images/correct-answer.svg';
import wrongAnswerImg from '../../../../images/wrong-answer.svg';
import React from 'react';

const Container = styled.div`
  border-top: 1px solid #d7dbe3;
  margin-top: 44px;
  padding-top: 32px;
  font-family: IBM Plex Sans;
  > div {
    display: flex;
    border-radius: 4px;
    line-height: 135%;
    padding: 12px 32px;
    width: fit-content;
  }
`;

const Correct = styled.div`
  align-items: center;
  background: rgba(39, 153, 137, 0.1);
  color: #279989;
`;

const Wrong = styled.div`
  flex-direction: column;
  gap: 8px;
  background: rgba(224, 79, 57, 0.1);
  color: #e04f39;
  > div {
    display: flex;
    align-items: center;
  }
`;

const Title = styled.h4`
  font-size: 16px;
  font-weight: 700;
  margin: 0px 0px 0px 8px;
`;

const Description = styled.p`
  color: #2e2d29;
  font-size: 16px;
  line-height: 135%;
  margin-left: 32px;
`;

const QuestionResult = ({ correct, subject }) => (
  <Container>
    {correct ? (
      <Correct>
        <img src={correctAnswerImg} alt="correct-answer" /> <Title> Correct!</Title>
      </Correct>
    ) : (
      <Wrong>
        <div>
          <img src={wrongAnswerImg} alt="wrong-answer" />{' '}
          <Title>You missed it. Study the following to try again.</Title>
        </div>
        <Description>{subject}</Description>
      </Wrong>
    )}
  </Container>
);

export default QuestionResult;
