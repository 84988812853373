import React, { useState, useEffect } from 'react';
import { AccordionContent, AccordionItem, AccordionTitle } from '../parts';
import { inject } from 'mobx-react';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';
import WorkflowsTable from './WorkflowsTable';
import styled from 'styled-components';

const CustomAccordionItem = styled(AccordionItem)`
  .content {
    margin: 24px 0px 0px 0px !important;
  }
`;

const WorkflowStep = ({ defaultWorkflow, active, disabled, onClick, onStepCompleted, newWorkflowStore }) => {
  const [loading, setLoading] = useState();
  const [workflows, setWorkflows] = useState();
  const [stepCompleted, setStepCompleted] = useState();

  useEffect(() => {
    const getWorkflows = () => newWorkflowStore.getWorkflows();

    if (active) {
      setLoading(true);
      getWorkflows()
        .then(workflows => {
          setWorkflows(workflows);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          displayError(error);
        });
    }
  }, [active]);

  const handleStepCompleted = workflow => {
    setStepCompleted(true);
    onStepCompleted(workflow);
  };
  return (
    <CustomAccordionItem>
      <AccordionTitle
        index={2}
        title="Select workflow"
        description="Process data with pre-built pipelines from open-source communities."
        active={active}
        completed={stepCompleted}
        disabled={disabled}
        onClick={onClick}
      />
      <AccordionContent active={active}>
        {loading ? (
          <BasicProgressPlaceholder segmentCount={3} />
        ) : workflows ? (
          <WorkflowsTable
            defaultWorkflow={defaultWorkflow}
            workflows={workflows}
            onStepCompleted={handleStepCompleted}
          />
        ) : (
          <></>
        )}
      </AccordionContent>
    </CustomAccordionItem>
  );
};

export default inject('newWorkflowStore')(WorkflowStep);
