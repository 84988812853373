import { types } from 'mobx-state-tree';
import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import { getSurvey, createSurveySubmission } from '../../helpers/api';
import { Survey } from './Survey';

// ==================================================================
// SurveyStore
// ==================================================================
const SurveyStore = BaseStore.named('SurveyStore')
  .props({
    surveys: types.optional(types.map(Survey), {}),
  })
  .actions(self => {
    return {
      async getSurvey(id) {
        if (self.surveys.has(id)) return self.surveys.get(id);

        const survey = await getSurvey(id);
        self.runInAction(() => self.surveys.set(id, survey));

        return survey;
      },

      async createSurveySubmission(id, submission) {
        return createSurveySubmission(id, submission);
      },
    };
  })
  .views(() => ({}));

function registerContextItems(appContext) {
  appContext.surveyStore = SurveyStore.create({}, appContext);
}

export { SurveyStore, registerContextItems };
