import React, { useState } from 'react';
import styled from 'styled-components';

const Menu = styled.div`
  border-bottom: 1px solid #d7dbe3;
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 32px 0px 24px 0px;
    margin: 0px;
  }
`;

const Stepper = props => {
  const [activeStep, setActiveStep] = useState(props.activeStep);

  return (
    <div className="stepper">
      <Menu>
        <ul>
          {props.steps.map((s, i) =>
            React.cloneElement(s.stepItem, {
              active: i === activeStep,
              onClick: () => setActiveStep(i),
            }),
          )}
        </ul>
      </Menu>
      {props.steps[activeStep].stepContent}
    </div>
  );
};

export default Stepper;
