/* eslint-disable max-classes-per-file */
import React, { useEffect } from 'react';
import { Container, Icon, Header, Segment } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { runInAction } from 'mobx';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';
import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import Stores from '@aws-ee/base-ui/dist/models/Stores';
import { isStoreLoading, isStoreError, isStoreReady } from '@aws-ee/base-ui/dist/models/BaseStore';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import { categories } from '@aws-ee/base-raas-ui/dist/models/studies/categories';
import Study from './parts/Study';

const ResearchPage = props => {
  const studiesStore = props.studiesStoresMap[categories.organization.id];

  let stores;
  runInAction(() => {
    stores = new Stores([studiesStore, props.currentUserScEnvironmentsStore, props.userStore]);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    swallowError(stores.load());
    props.currentUserScEnvironmentsStore.startHeartbeat();
    return () => props.currentUserScEnvironmentsStore.stopHeartbeat();
  }, []);

  useEffect(() => {
    studiesStore.load();
    props.currentUserScEnvironmentsStore.load();
  }, [studiesStore.studies, props.currentUserScEnvironmentsStore.environments]);

  const getWorkspaceMap = () => {
    const map = new Map();
    const userId = props.userStore.user.uid;

    props.currentUserScEnvironmentsStore.list
      .filter(
        workspace =>
          workspace.state.display !== 'TERMINATED' &&
          workspace.state.display !== 'TERMINATION FAILED' &&
          workspace.state.display !== 'FAILED' &&
          workspace.createdBy === userId &&
          !_.isNil(workspace.studyIds) &&
          workspace.studyIds.length === 1,
      )
      .forEach(workspace => {
        workspace.studyIds.forEach(studyId => {
          map.set(studyId, workspace);
        });
      });

    return map;
  };

  const renderTitle = () => {
    return (
      <div className="flex">
        <Header as="h3" className="color-grey mt1 mb0 mb3 flex-auto">
          <Header.Content className="left-align">Research</Header.Content>
          <Header.Subheader className="left-align">
            Explore advanced topics in precision medicine developed from recently published research studies.
          </Header.Subheader>
        </Header>
      </div>
    );
  };

  const renderNoLearning = () => {
    return (
      <Segment placeholder className="mt3">
        <Header icon className="color-grey">
          <Icon name="clipboard outline" />
          No research studies available.
        </Header>
      </Segment>
    );
  };

  const renderStudies = studies => {
    const workspaceMap = getWorkspaceMap();
    studies.forEach(s => {
      s.studyId = s.id;
      s.workspace = workspaceMap.get(s.studyId);
      if (_.isNil(s.workspace)) s.workspace = {};
    });
    return (
      <div className="research">
        {studies.map(s => (
          <Study key={s.id} study={s} />
        ))}
      </div>
    );
  };

  let content = null;
  if (isStoreError(studiesStore) || isStoreError(props.currentUserScEnvironmentsStore)) {
    content = <ErrorBox error={studiesStore.error.message} className="mt3 mr0 ml0" />;
  } else if (isStoreLoading(studiesStore) || isStoreLoading(props.currentUserScEnvironmentsStore)) {
    content = <BasicProgressPlaceholder segmentCount={5} className="mt3 mr0 ml0" />;
  } else if (isStoreReady(studiesStore) && isStoreReady(props.currentUserScEnvironmentsStore)) {
    const studies = studiesStore.find('Research');
    content = _.isNil(studies) || studies.length === 0 ? renderNoLearning() : renderStudies(studies);
  }

  return (
    <Container className="mt4 pb4">
      {renderTitle()}
      {content}
    </Container>
  );
};

export default inject(
  'studiesStoresMap',
  'currentUserScEnvironmentsStore',
  'userStore',
)(withRouter(observer(ResearchPage)));
