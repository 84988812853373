import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import _ from 'lodash';
import ChatBot from './ChatBot';
import chatbotImg from '../../../images/chatbot/chatbot.svg';
import arrowDownImg from '../../../images/chatbot/arrow-down.svg';
import localStorageKeys from '../../models/constants/local-storage-keys';
import { storage } from '../../helpers/utils';

const ChatPopup = styled(Popup)`
  width: 540px !important;
  max-width: 540px !important;
  margin-bottom: 16px !important;
  padding: 0px !important;
  border-radius: 8px !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: 40px 40px 80px rgba(46, 45, 41, 0.1) !important;
  border-radius: 8px !important;
  font-family: 'IBM Plex Sans';

  > div {
    height: 720px;
    max-height: 720px;
    width: 540px !important;
    max-width: 540px !important;
  }

  &:before {
    content: unset !important;
  }
`;

const MessagePopup = styled(Popup)`
  width: 372px !important;
  max-width: 372px !important;
  border-radius: 8px !important;
  border: 1px solid #d7dbe3 !important;
  padding: 8px !important;
  background: #fff !important;
  box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.1) !important;
  font-family: 'IBM Plex Sans';

  > div {
    display: flex;
    align-items: center;
    gap: 14px;
    margin: 24px 32px 16px 16px;
    > span {
      font-size: 32px;
      width: 32px;
      height: 32px;
    }
    > div {
      > p {
        color: #2e2d29;
        font-size: 16px;
        font-weight: 450;
        line-height: 145%;
        letter-spacing: 0.2px;
        margin: 0px !important;
      }
    }
  }

  &:before {
    content: unset !important;
  }
`;

const ChatBotButton = styled.button`
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 64px;
  height: 64px;
  padding: 18px;
  background: #2e2d29;
  border-radius: 12px;
  border: none;
  background-image: url(${p => p.icon});
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
`;

const ChatBotPopup = ({ userStore }) => {
  const [open, setOpen] = useState(false);
  const [messageOpen, setMesageOpen] = useState(!storage.getItem(localStorageKeys.chatbotPopup));

  const handleChatBotClick = () => {
    setMesageOpen(false);
    setOpen(true);
  };

  if (userStore.user.chatbotFeature === 'Disabled' && !userStore.user.isRootUser) return <></>;
  return messageOpen ? (
    <MessagePopup
      pinned
      position="top right"
      open={messageOpen}
      trigger={<ChatBotButton icon={chatbotImg} onClick={handleChatBotClick} />}
      style={{ zIndex: 10 }}
    >
      <i
        className="close"
        onClick={() => {
          storage.setItem(localStorageKeys.chatbotPopup, true);
          setMesageOpen(false);
        }}
      />
      <div>
        <span>🙋</span>
        <div>
          <p>
            Have a question about your study?
            <br />
            Ask
            <b> AI Tutor </b>
            to answer now.
          </p>
        </div>
      </div>
    </MessagePopup>
  ) : (
    <ChatPopup
      pinned
      on="click"
      position="top right"
      open={open}
      trigger={<ChatBotButton icon={open ? arrowDownImg : chatbotImg} onClick={handleChatBotClick} />}
      onClose={() => setOpen(false)}
    >
      <ChatBot />
    </ChatPopup>
  );
};

export default inject('userStore')(observer(ChatBotPopup));
