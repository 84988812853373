import React from 'react';
import styled from 'styled-components';
import browserImg from '../../../../images/static/campaign/browser.png';
import Section from './Section';
import { Grid } from 'semantic-ui-react';
import PlayVideoButton from '@aws-ee/base-ui/dist/parts/PlayVideoButton';
import Title from './Title';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8.571em 0px 0px 0px;
  align-items: center;
  > div {
    width: 100%;
    > div {
      > img {
        width: 100%;
        max-width: 100%;
      }
    }
    div:nth-child(2) {
      padding-right: 0px !important;
    }
  }
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.642em;
  padding-left: 48px;
`;

const CustomPlayVideoButton = styled(PlayVideoButton)`
  width: 165px;
  height: 165px;
  position: absolute;
  left: 50%;
  top: 50%;
  background: rgba(249, 164, 74, 0.3) !important;
  backdrop-filter: blur(15px) !important;
  i::before {
    font-size: 55px !important;
    color: #e98300 !important;
    position: relative;
    left: -75%;
    top: 50%;
  }

  @media (max-width: 992px) {
    width: 100px;
    height: 100px;
    i::before {
      font-size: 22px !important;
      color: #e98300 !important;
      position: relative;
      left: -10%;
      top: 20%;
    }
  }
`;

const Practice = ({ practiceNotes }) => {
  return (
    <Container>
      <Title>Practice with Stanford Data Ocean</Title>
      <Grid>
        <Grid.Column floated="left" verticalAlign="middle" computer={8} tablet={16} mobile={16}>
          <Sections>
            {practiceNotes.map(note => (
              <Section
                key={note.title}
                icon={note.icon}
                title={note.title}
                description={note.description}
                useRelativePath={true}
              />
            ))}
          </Sections>
        </Grid.Column>
        <Grid.Column floated="right" verticalAlign="middle" computer={8} tablet={16} mobile={16}>
          <img src={browserImg} alt="browser" />
          <CustomPlayVideoButton url="https://www.youtube.com/embed/b1mGDUeSB04" />
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default Practice;
