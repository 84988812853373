import styled from 'styled-components';

const Choice = styled.div`
  display: flex;
  border-radius: 4px;
  background: #f6e9db;
  border: 1px solid #f6e9db;

  label {
    font-family: 'IBM Plex Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: #2e2d29;
  }
  &.checked {
    background: #f9a44a;
    border: 1px solid #f9a44a;
    label {
      font-weight: 700;
      color: #ffffff;
    }
  }
`;

export default Choice;
