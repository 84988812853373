import React from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

const Title = styled.h2`
  white-space: pre;
`;

const PauseConfirmationModal = ({ modalOpen, onSubmit, onCancel }) => (
  <Modal size="tiny" centered open={modalOpen} onClose={onCancel}>
    <div className="flex flex-column p3">
      <i className="close" onClick={onCancel} />
      <Title className="m0 mt1 self-center">
        <span role="img" aria-label="pause">
          ⏸️
        </span>{' '}
        Are you sure you want to pause the module?
      </Title>
      <p className="my3">
        Your work will be saved, and can be accessed at a later time.
        <br />
        <br />
        Please always pause the module when you are not actively working on the project. This allows Stanford Data Ocean
        to spend the saved cost to other students who are in need of the computing resources.
      </p>
      <div className="mt3 custom-actions">
        <div className="col col-6 pr2">
          <button type="button" className="primary-black" onClick={onSubmit}>
            Yes, pause module
          </button>
        </div>
        <div className="col col-6 pl2">
          <button type="button" className="secondary" onClick={onCancel}>
            No, I’m still working
          </button>
        </div>
      </div>
    </div>
  </Modal>
);

export default PauseConfirmationModal;
