import { inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FeedbackCategory from '../../../../models/chatbot/FeedbackType';
import CodeSnippit from './CodeSnippit';
import heartImg from '../../../../../images/chatbot/heart.svg';
import heartFilledImg from '../../../../../images/chatbot/heart-filled.svg';
import thumbsDownImg from '../../../../../images/chatbot/thumbs-down.svg';
import thumbsDownFilledImg from '../../../../../images/chatbot/thumbs-down-filled.svg';
import { Accordion, Icon } from 'semantic-ui-react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  background: #fde8d1;
  border-radius: 8px;
  font-family: 'IBM Plex Sans';
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
  align-self: flex-start;
  width: fit-content;
  max-width: 100%;
  > p {
    white-space: pre-wrap;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 14px;
`;

const ActionButton = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  background-color: #53565a;
  margin: 0px 0px 0px 18px;
  padding: 0px;
  -webkit-mask-image: url(${p => p.img});
  mask-image: url(${p => p.img});
  cursor: pointer;
`;

const DislikeDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  border-top: solid 1px rgba(46, 45, 41, 0.15);
`;

const CommentArea = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  background: linear-gradient(0deg, rgba(233, 131, 0, 0.1), rgba(233, 131, 0, 0.1)), #ffffff;
  border: 1px solid rgba(233, 131, 0, 0.3);
  border-radius: 4px;

  > textarea {
    width: 100%;
    margin-bottom: 16px;
    resize: none;
    border: none;
    font-family: 'IBM Plex Sans';
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    background: linear-gradient(0deg, rgba(233, 131, 0, 0.1), rgba(233, 131, 0, 0.1)), #ffffff;
    &::placeholder {
      color: rgba(46, 45, 41, 0.3);
    }
    &:focus {
      outline: none !important;
    }
  }

  > button {
    align-self: flex-end;
    border: none;
    background: transparent;
    font-family: 'IBM Plex Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: rgba(46, 45, 41, 0.15);
    cursor: pointer;
    pointer-events: ${p => (p.disabled ? 'none' : 'unset')};
  }

  :focus-within {
    border: 1px solid #e98300;

    > button {
      color: ${p => (p.disabled ? 'rgba(46, 45, 41, 0.15)' : '#e98300')};
      pointer-events: ${p => (p.disabled ? 'none' : 'unset')};
    }
  }
`;

const SubCategory = styled.div`
  display: inline-block;
  margin: 4px 4px 0px 0px;
  padding: 12px 16px;
  height: 44px;
  width: fit-content;
  background: linear-gradient(0deg, rgba(233, 131, 0, 0.1), rgba(233, 131, 0, 0.1)), #ffffff;
  border: ${p => (p.selected ? '1px solid #E98300' : '1px solid rgba(46, 45, 41, 0.15)')};
  border-radius: 4px;
  color: ${p => (p.selected ? '#E98300' : 'rgba(46, 45, 41, 0.3)')};
  cursor: pointer;
`;

const FeedbackSubCategory = {
  Inaccurate: 'Inaccurate',
  Irrelevant: 'Irrelevant',
  Biased: 'Biased',
  DifficultToUnderstand: 'Difficult to understand',
  NotEngagingOrInteresting: 'Not engaging or interesting',
};

const Sources = styled(Accordion)`
  width: fit-content;
  margin-top: 8px;
  padding: 8px 16px;
  border-radius: 20px;
  background: #fffae6;
  * {
    color: #53565a;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
  }

  ul {
    margin: 0px !important;
    * {
      font-weight: 400;
    }
  }
`;

const AIMessage = ({ message, chatbotStore }) => {
  const [showDislikeBody, setShowDislikeBody] = useState(false);
  const [comment, setComment] = useState();
  const [subCategories, setSubCategories] = useState([]);
  const [showSources, setShowSources] = useState();
  const [codeMatches, setCodeMatches] = useState([]);

  useEffect(() => {}, [message.feedback?.category]);

  useEffect(() => {
    const regex = /(?<before>.*?)\n```(?<language>\w+)\n(?<code>.*?)\n```\n(?<after>.*?)(?=\n```|$)/gs;
    const matches = [];
    let result;
    while ((result = regex.exec(message.message)) !== null) {
      matches.push(result.groups);
    }
    setCodeMatches(matches);
  }, [message]);

  const handleLikeClick = async () => {
    await chatbotStore.updateMessageFeedback(message.idx, { category: FeedbackCategory.Like });
    setShowDislikeBody(undefined);
  };

  const handleDislikeClick = async () => {
    await chatbotStore.updateMessageFeedback(message.idx, { category: FeedbackCategory.Dislike });
    setShowDislikeBody(true);
  };

  const handleSubCategoryClick = subCategory => {
    const index = subCategories.indexOf(subCategory);
    if (index > -1) {
      subCategories.splice(index, 1);
      setSubCategories([...subCategories]);
    } else {
      subCategories.push(subCategory);
      setSubCategories([...subCategories]);
    }
  };

  const handleSubmitFeedback = async () => {
    await chatbotStore.updateMessageFeedback(message.idx, {
      category: FeedbackCategory.Dislike,
      subCategories: subCategories,
      comment: comment,
    });
    setShowDislikeBody(false);
  };

  return (
    <div>
      <Container>
        {codeMatches.length ? (
          codeMatches.map(match => {
            return (
              <CodeSnippit
                language={match['language']}
                code={match['code']}
                beforeText={match['before']}
                afterText={match['after']}
              />
            );
          })
        ) : (
          <p>{message.message}</p>
        )}
        {message.disableFeedback ? (
          <></>
        ) : (
          <>
            <Actions>
              <ActionButton
                img={message.feedback?.category === FeedbackCategory.Like ? heartFilledImg : heartImg}
                onClick={handleLikeClick}
              />
              <ActionButton
                img={message.feedback?.category === FeedbackCategory.Dislike ? thumbsDownFilledImg : thumbsDownImg}
                onClick={handleDislikeClick}
              />
            </Actions>
            {message.feedback?.category === FeedbackCategory.Dislike && showDislikeBody ? (
              <DislikeDetails onSubmit={handleSubmitFeedback}>
                <CommentArea disabled={!comment}>
                  <textarea
                    rows={3}
                    placeholder="Select below or tell us more why you down-voted this answer..."
                    onChange={e => setComment(e.target.value)}
                  ></textarea>
                  <button type="button" onClick={handleSubmitFeedback} disabled={!comment}>
                    Submit
                  </button>
                </CommentArea>
                <div>
                  {Object.keys(FeedbackSubCategory).map(k => (
                    <SubCategory
                      key={k}
                      selected={subCategories.includes(FeedbackSubCategory[k])}
                      onClick={() => handleSubCategoryClick(FeedbackSubCategory[k])}
                    >
                      {FeedbackSubCategory[k]}
                    </SubCategory>
                  ))}
                </div>
              </DislikeDetails>
            ) : (
              <></>
            )}
          </>
        )}
      </Container>
      <Sources>
        <Accordion.Title onClick={() => setShowSources(!showSources)}>
          See sources{' '}
          <Icon name={`angle ${showSources ? 'down' : 'right'}`} onClick={() => setShowSources(!showSources)} />
        </Accordion.Title>
        <Accordion.Content active={showSources}>
          <ul>
            {[message.source].map(s => (
              <li key={s}>{s}</li>
            ))}
          </ul>
        </Accordion.Content>
      </Sources>
    </div>
  );
};

export default inject('chatbotStore')(AIMessage);