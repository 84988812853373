import { types } from 'mobx-state-tree';
import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import { getDataTypes } from '../../helpers/api';
import { DataType } from './DataType';

// ==================================================================
// DataTypesStore
// ==================================================================
const DataTypesStore = BaseStore.named('DataTypesStore')
  .props({
    dataTypes: types.array(DataType),
  })
  .actions(self => {
    return {
      async doLoad() {
        const dataTypes = await getDataTypes();
        self.runInAction(() => {
          self.dataTypes = dataTypes;
        });
      },
    };
  })
  .views(() => ({}));

function registerContextItems(appContext) {
  appContext.dataTypesStore = DataTypesStore.create({}, appContext);
}

export { DataTypesStore, registerContextItems };
