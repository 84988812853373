import React, { useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import OrderType from '../../../../models/OrderType';
import ActionButton from './ActionButton';

const SortButton = styled(ActionButton)`
  span {
    margin-top: -8px;
    margin-left: 3px;
    i {
      &.active {
        color: #2e2d29;
      }
    }
  }
`;

const Sort = props => {
  const [orderAscending, setOrderAscending] = useState(true);

  const Trigger = (
    <SortButton
      className="flex"
      onClick={() => {
        setOrderAscending(!orderAscending);
        props.onChange(!orderAscending ? OrderType.Ascending : OrderType.Descending);
      }}
    >
      {props.value}
      <span className="flex flex-column">
        <Icon name="angle up" className={orderAscending ? 'active' : ''} />
        <Icon name="angle down" className={!orderAscending ? 'active' : ''} />
      </span>
    </SortButton>
  );

  return <Popup trigger={Trigger} content={orderAscending ? OrderType.Ascending : OrderType.Descending} basic />;
};

export default Sort;
