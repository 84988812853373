import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Stepper, StepItem, StepContent } from '../Stepper';
import { Path, PathIcon, CertificatePathIcon } from '../Path';
import ProgressEnum from '../../../../models/learning/ProgressEnum';
import CertificatePath from '../Path/CertificatePath';

const UserLearning = ({
  id,
  name,
  subscriptionRequired,
  subscription,
  paths,
  certificateProgram,
  currentUserScEnvironmentsStore,
}) => {
  const isPathCompleted = path => path.modules.every(s => s.progress === ProgressEnum.Completed);

  const workspaceMap = new Map();
  currentUserScEnvironmentsStore.list
    .filter(
      workspace =>
        workspace.state.display !== 'TERMINATED' &&
        workspace.state.display !== 'TERMINATION FAILED' &&
        workspace.state.display !== 'FAILED' &&
        !_.isNil(workspace.studyIds) &&
        workspace.studyIds.length === 1,
    )
    .forEach(workspace => {
      workspaceMap.set(workspace.studyIds[0], workspace);
    });

  let activeStep = 0;
  let nonCompletedPathAlreadyMeet = false;
  const stepsItems = paths.map((path, i) => {
    const pathCompleted = isPathCompleted(path);
    if (!pathCompleted) nonCompletedPathAlreadyMeet = true;
    if (!nonCompletedPathAlreadyMeet && pathCompleted && i < paths.length - 1) activeStep++;

    const pathDisabled = i !== 0 && !isPathCompleted(paths[i - 1]);
    const createStepItem = p => {
      const completed = p.modules.every(m => m.progress === ProgressEnum.Completed);
      const inProgress =
        p.modules.filter(m => m.progress === ProgressEnum.InProgress || m.progress === ProgressEnum.Completed).length >
        0;

      return (
        <StepItem
          key={p.id}
          title={p.name}
          isCertificatePath={p.isCertificatePath}
          inProgress={inProgress}
          completed={completed}
        >
          {p.isCertificatePath ? <CertificatePathIcon /> : <PathIcon icon={p.icon} />}
        </StepItem>
      );
    };

    const createStepContent = p => {
      if (p.isCertificatePath)
        return (
          <CertificatePath
            learningId={id}
            path={p}
            disabled={pathDisabled || (subscriptionRequired && !subscription?.activated)}
          />
        );
      p.modules
        .filter(m => m.studyId)
        .forEach(m => {
          m.workspace = workspaceMap.get(m.studyId);
          if (_.isNil(m.workspace)) m.workspace = {};
        });
      if (!_.isNil(p.electiveModules))
        p.electiveModules
          .filter(m => m.studyId)
          .forEach(m => {
            m.workspace = workspaceMap.get(m.studyId);
            if (_.isNil(m.workspace)) m.workspace = {};
          });

      return (
        <StepContent key={p.id}>
          <Path
            learningId={id}
            subscriptionRequired={subscriptionRequired}
            subscription={subscription}
            path={p}
            certificateProgram={certificateProgram}
            disabled={pathDisabled}
          />
        </StepContent>
      );
    };

    return {
      stepItem: createStepItem(path),
      stepContent: createStepContent(path),
    };
  });

  return <Stepper steps={stepsItems} activeStep={activeStep} />;
};

export default inject('currentUserScEnvironmentsStore')(withRouter(observer(UserLearning)));
