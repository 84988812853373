import React from 'react';
import styled from 'styled-components';
import mapImg from '../../../../images/static/campaign/map.png';
import externalLinkImg from '../../../../images/static/campaign/external-link.svg';
import copyImg from '../../../../images/static/campaign/copy.svg';
import locationImg from '../../../../images/static/campaign/location.svg';
import buildingImg from '../../../../images/static/campaign/building.svg';
import parkingImg from '../../../../images/static/campaign/parking.svg';
import railwayStationImg from '../../../../images/static/campaign/railway-station.svg';
import { Grid } from 'semantic-ui-react';
import Title from '../parts/Title';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8.571em 14px;
  align-items: center;
  > div {
    width: 100%;
  }
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 150%;
  margin: 0px 0px 5.714em 0px;
  max-width: 600px;

  a {
    color: #2e2d29;
    text-decoration: underline;
  }
`;

const Map = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 20px;
  overflow: hidden;
  max-width: 839px;
  background-image: url(${mapImg});
  background-size: contain;
  background-repeat: no-repeat;
`;

const Address = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 54px 64px;
  gap: 10px;
  height: 172px;
  background: linear-gradient(0deg, rgba(233, 131, 0, 0.1), rgba(233, 131, 0, 0.1)), rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  color: #d1660f;
  line-height: 150%;

  a {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 24px;
    text-decoration-line: underline;
    margin-bottom: 4px;
    color: #d1660f;
    line-height: 24px;
  }
  span {
    font-weight: 600;
    font-size: 16px;
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 32px;
    gap: 8px;
    border-radius: 8px;
    font-family: 'IBM Plex Mono';
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #d1660f;
    background: #fff9f0;
    border: 1px solid rgba(233, 131, 0, 0.3);
    cursor: pointer;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    gap: 20px;
    height: unset;
    padding: 40px 20px;
  }
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  max-width: 450px;
`;

const SectionTitle = styled.div`
  display: flex;
  margin-bottom: 24px;
  gap: 16px;
  h2 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    margin: 0px;
  }
`;

const SectionDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  a {
    color: #2e2d29;
    text-decoration: underline;
  }
`;

const FindUs = () => {
  const address = '3165 Porter Drive, Palo Alto, CA, 94304';

  return (
    <Container>
      <Title>How to Find Us?</Title>
      <Description>
        This 3-day workshop is hosted at the{' '}
        <a href="https://med.stanford.edu/content/sm/snyderlab.html.html" target="_blank">
          Snyder Lab
        </a>{' '}
        at Stanford University. Snyder Lab is led by the Chair of the Genetics Department, Dr. Michael Snyder.{' '}
        <a
          href="https://www.youtube.com/watch?v=wG4AVe_JzXY&ab_channel=StanfordHealthcareInnovationLab"
          target="_blank"
        >
          Watch this video
        </a>{' '}
        to see how the teams are exploring the frontier of precision medicine.
      </Description>
      <Grid stretched>
        <Grid.Column computer={7} tablet={16} mobile={16}>
          <Map>
            <Address>
              <div>
                <a
                  href="https://www.google.com/maps/place/3165+Porter+Drive/@37.408008,-122.150186,15z/data=!4m6!3m5!1s0x808fba964663ce5d:0xc6890e465244055d!8m2!3d37.408008!4d-122.150186!16s%2Fg%2F11h0w6441"
                  target="_blank"
                >
                  Snyder Lab at Stanford University
                  <img src={externalLinkImg} alt="external-link" />
                </a>
                <span>{address}</span>
              </div>
              <button type="button" onClick={() => navigator.clipboard.writeText(address)}>
                Copy address <img src={copyImg} alt="copy" />
              </button>
            </Address>
          </Map>
        </Grid.Column>
        <Grid.Column floated="right" computer={4} tablet={16} mobile={16}>
          <Sections>
            <div>
              <SectionTitle>
                <img src={locationImg} alt="location" />
                <h2>Classroom</h2>
              </SectionTitle>
              <SectionDescription>Snyder Lab Conference Room 2200</SectionDescription>
            </div>
            <div>
              <SectionTitle>
                <img src={buildingImg} alt="building" />
                <h2>Access the building.</h2>
              </SectionTitle>
              <SectionDescription>
                The building will be locked at all times. We will have someone available to let folks in at the start of
                each day through the front entrance.
              </SectionDescription>
            </div>
            <div>
              <SectionTitle>
                <img src={parkingImg} alt="parking" />
                <h2>Get Parking.</h2>
              </SectionTitle>
              <SectionDescription>
                There are bike racks and free parking in the parking lot behind the building.
              </SectionDescription>
            </div>
            <div>
              <SectionTitle>
                <img src={railwayStationImg} alt="railway-station" />
                <h2>Take CalTrain.</h2>
              </SectionTitle>
              <SectionDescription>
                <ul>
                  <li>
                    The CalTrain schedule can be found{' '}
                    <a
                      href="https://www.caltrain.com/rider-information/how-ride-caltrain?active_tab=route_explorer_tab"
                      target="_blank"
                    >
                      here
                    </a>
                    .
                  </li>
                  <li>Get off at the Palo Alto stop.</li>
                  <li>
                    Catch the{' '}
                    <a href="https://transportation.stanford.edu/marguerite/rp" target="_blank">
                      Research Park shuttle
                    </a>{' '}
                    (RP shuttle) to the 3170 Porter Drive stop.
                  </li>
                  <li>The RP shuttle is free, and open to the public. However, it will not run on Saturday.</li>
                </ul>
              </SectionDescription>
            </div>
          </Sections>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default FindUs;
