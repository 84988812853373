import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

const DropdownWrapper = styled(Dropdown)`
  display: flex !important;
  padding: 0px !important;
  align-items: stretch !important;
  border-radius: 4px !important;
  line-height: 20px !important;
  width: 281px;
  height: 40px;
  margin: 0px !important;

  &.primary-black {
    background: #2e2d29 !important;
    color: #fafafa !important;
  }

  &.progress {
    color: #fafafa !important;
    background: #e98300 !important;
    pointer-events: none !important;
    .menu {
      display: none !important;
    }
  }

  &.primary-blue {
    background: #4298b5 !important;
    color: #fafafa !important;
  }

  > button {
    font-family: 'IBM Plex Mono' !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    border: none;
    cursor: pointer;
    display: block;
    align-items: center;
    padding: 0px 12px;
    width: 100%;
    border-radius: 4px 0px 0px 4px;
    border-right: 2px solid !important;
    span {
      margin-right: 5px;
    }

    &.primary-black {
      background: #2e2d29 !important;
      color: #fafafa !important;
    }

    &.progress {
      color: #fafafa;
      background: #e98300;
      border: none;
      div {
        display: inline-block !important;
        background: #7ea2c3;
        border-radius: 5px;
        padding: 4px 0px 4px 4px;
      }
    }

    &.primary-blue {
      background: #4298b5;
      border: none;
      color: #fafafa;

      &.disabled {
        background: #969dab;
        color: #fafafa;
      }
    }
  }

  > i {
    margin: 0px !important;
    min-width: 45px;
    ::before {
      position: relative;
      top: 12px;
    }
    &.disabled {
      cursor: default !important;
    }
  }

  > .menu {
    width: calc(2 * 100%) !important;
    margin: 8px 0px !important;
    background: #ffffff;
    border: 1px solid #d7dbe3;
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px;
    > .item {
      margin-right: 0px !important;
      &:not(:last-child) {
        border-bottom: 1px solid #d7dbe3;
      }
    }
  }
`;

export default DropdownWrapper;
