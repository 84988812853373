import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import StepCircle from './StepCircle';
import TitleAndDescription from '../TitleAndDescription';

const Container = styled(Accordion.Title)`
  display: flex;
  align-items: center;
  padding: 0px !important;
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
  > {
    color: #2e2d29;
    font-family: IBM Plex Sans;
    letter-spacing: 0.4px;
  }

  > i {
    align-self: flex-start;
  }
`;

const AccordionTitle = ({ index, title, description, active, completed, disabled, onClick }) => (
  <Container active={active} disabled={disabled} onClick={onClick}>
    <StepCircle index={index} completed={completed} />
    <TitleAndDescription title={title} description={description} />
    <Icon name={`angle ${active ? 'down' : 'right'}`} size="large" disabled={disabled} />
  </Container>
);

export default AccordionTitle;
