import React from 'react';
import { Image } from 'semantic-ui-react';
import styled from 'styled-components';
import supportedByImg from '../../../../images/static/campaign/supported-by.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5.714em 0px;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    color: #2e2d29;
    margin: 0px;
  }
`;

const CustomImage = styled(Image)`
  margin-top: 4.642em;
  width: 955px;
  height: 100%;
  padding: 0px 10px;
`;

const Thanks = () => {
  return (
    <Container>
      <p>Supported by</p>
      <CustomImage src={supportedByImg} alt="supported-by" />
    </Container>
  );
};

export default Thanks;
