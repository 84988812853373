import React from 'react';
import styled from 'styled-components';
import HumanMessage from './HumanMessage';
import AIMessage from './AIMessage';
import AIIntroMessage from './AIIntroMessage';
import TypingMessage from './TypingMessage';
import MessageType from '../../../models/chatbot/MessageType';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 24px;
  }
`;

const Messages = ({ messages, maximized }) => (
  <Container>
    {messages.map(m => {
      switch (m.type) {
        case MessageType.Human:
          return <HumanMessage key={m.idx} message={m.message} alignLeft={maximized} />;
        case MessageType.AI_Intro:
          return <AIIntroMessage key={m.idx} message={m.message} />;
        case MessageType.AI:
          return <AIMessage key={m.idx} message={m} />;
        case MessageType.Typing:
          return <TypingMessage key={m.idx} />;
      }
    })}
  </Container>
);

export default Messages;
