import styled from 'styled-components';
import React from 'react';

const Bubble = styled.div`
  background-color: #fde8d1;
  padding: 16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
  width: fit-content;

  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);
      background-color: #f9a44a; // rgba(20,105,69,.7);
    }
    28% {
      transform: translateY(-7px);
      background-color: #e98300; //rgba(20,105,69,.4);
    }
    44% {
      transform: translateY(0px);
      background-color: #ffffff; //rgba(20,105,69,.2);
    }
  }
`;

const Dot = styled.div`
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #f9a44a; //rgba(20,105,69,.7);
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
`;

const Dots = styled.div`
  align-items: center;
  display: flex;
  height: 17px;

  div:nth-child(1) {
    animation-delay: 200ms;
  }
  div:nth-child(2) {
    animation-delay: 300ms;
  }
  div:nth-child(3) {
    animation-delay: 400ms;
  }
  div:last-child {
    margin-right: 0;
  }
`;

const TypingMessage = () => {
  return (
    <Bubble>
      <Dots>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
      </Dots>
    </Bubble>
  );
};

export default TypingMessage;
