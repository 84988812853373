/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';
import { storage } from '../../helpers/utils';
const uuid = require('uuid/v4');

import {
  createChatbotMessage,
  createChatbotSession,
  getChatbotMessages,
  getChatbotSessions,
  updateResponseFeedback,
} from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import { Message } from './Message';
import MessageType from './MessageType';
import ChatBotEngine from './ChatbotEngine';
import localStorageKeys from '../constants/local-storage-keys';

const AIBasedIntroMessage = engine => {
  return JSON.stringify(ChatBotEngine.engines.find(modelIntro => modelIntro.value === engine));
};

const DefaultMessage = {
  idx: _.uniqueId('m'),
  type: MessageType.AI_Intro,
  message: AIBasedIntroMessage(ChatBotEngine.default),
  disableFeedback: true,
};
const DefaultChatBotEngine = ChatBotEngine.default;

const ChatbotStore = BaseStore.named('ChatbotStore')
  .props({
    sessionId: types.maybeNull(types.string),
    messages: types.optional(types.array(Message), [DefaultMessage]),
    engine: types.optional(types.string, DefaultChatBotEngine),
  })
  .actions(self => {
    return {
      async doLoad() {
        let defaultEnginePayload = { engine: DefaultChatBotEngine };
        if (storage.getItem(localStorageKeys.newLogin)) {
          const session = await createChatbotSession(defaultEnginePayload);
          self.runInAction(() => {
            self.sessionId = session.sessionId;
            self.engine = session.engine;
          });
          storage.removeItem(localStorageKeys.newLogin);
        } else {
          const sessions = await getChatbotSessions();
          if (sessions.length > 0) {
            self.runInAction(() => {
              self.sessionId = sessions[sessions.length - 1].sessionId;
              self.engine = sessions[sessions.length - 1].engine;
            });
          } else {
            const session = await createChatbotSession(defaultEnginePayload);
            self.runInAction(() => {
              self.sessionId = session.sessionId;
              self.engine = session.engine;
            });
          }
        }
      },

      async updateChatbotSession(chatBotEngine) {
        const session = await createChatbotSession({ engine: chatBotEngine });
        self.runInAction(() => {
          self.sessionId = session.sessionId;
          self.engine = session.engine;
        });
      },


      async getMessages() {
        const messages = await getChatbotMessages(self.sessionId);
        self.runInAction(() => {
          self.messages = [
            { ...DefaultMessage, message: AIBasedIntroMessage(self.engine) },
            ...messages.filter(m => !_.isEmpty(m.message)),
          ];
        });
      },

      async createMessage(message) {
        const messages = [...self.messages];
        self.runInAction(() => {
          self.messages = [
            ...messages,
            { idx: _.uniqueId('m'), message: message.content, type: MessageType.Human },
            { idx: _.uniqueId('m'), message: 'typing', type: MessageType.Typing },
          ];
        });
        message.requestId = uuid();
        const response = await createChatbotMessage(self.sessionId, message);
        self.runInAction(() => {
          self.messages = [
            ...messages,
            { idx: _.uniqueId('m'), message: message.content, type: MessageType.Human },
            response,
          ];
        });
        return response;
      },

      async updateMessageFeedback(messageId, feedback) {
        const response = await updateResponseFeedback(self.sessionId, messageId, feedback);
        self.runInAction(() => {
          const oldMessage = self.messages.find(m => m.idx === messageId);
          const newMessage = { ...oldMessage, feedback: feedback };
          oldMessage.set(newMessage);
        });
        return response;
      },
    };
  })
  .views(self => ({}));

function registerContextItems(appContext) {
  appContext.chatbotStore = ChatbotStore.create({}, appContext);
}

export { ChatbotStore, registerContextItems };