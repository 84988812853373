import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Annoncement from './Annoncement';
import Partners from './Partners';
import Team from '../parts/Team';
import Benefits from '../parts/Benefits';
import Notice from '../parts/Notice';
import Courses from './Courses';
import Practice from '../parts/Practice';
import FindUs from './FindUs';
import Footer from '../parts/Footer';
import Thanks from './Thanks';

const Container = styled.div`
  overflow: hidden;
  font-family: 'IBM Plex Sans';
  color: #2e2d29;

  > div {
    background-color: white;
  }

  @media (max-width: 992px) {
    font-size: 10px;
  }
`;

const Campaign = () => {
  const [data, setData] = useState();

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(`${window.location.origin}/static/campaign/data.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
      const json = await response.json();
      setData(json);
    };

    getData();
  }, []);

  return data ? (
    <Container>
      <Annoncement
        title={data.title}
        duration={data.duration}
        schedule={data.schedule}
        registerUrl={data.registerUrl}
      />
      <Partners partners={data.partners} />
      <Team title="Meet Your Teaching and Support Team" team={data.team} columns={3} />
      <Benefits title="In This Workshop" benefits={data.benefits} />
      <Notice
        title={data.notice.title}
        description={data.notice.description}
        actionText={data.notice.actionText}
        url={data.registerUrl}
      />
      <Courses courses={data.courses} />
      <Practice practiceNotes={data.practice} />
      <FindUs />
      <Thanks />
      <Footer />
    </Container>
  ) : (
    'Loading...'
  );
};

export default Campaign;
