import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

const CheckboxWrapper = styled(Checkbox)`
  background: #f6e9db !important;
  border: 1px solid #f6e9db !important;
  margin-right: 15px !important;

  label:before {
    background: #f6e9db !important;
    width: 18px !important;
    height: 18px !important;
    border: 2px solid #2e2d29 !important;
    border-radius: 2px !important;
  }
  label:hover:before {
    border-color: #2e2d29 !important;
    background: #f6e9db !important;
  }

  &.checked {
    background: #f9a44a !important;
    border: 1px solid #f9a44a !important;
    font-weight: 700 !important;
    color: #ffffff !important;
    label:before {
      border-color: #ffffff !important;
      background: #f9a44a !important;
    }
    label:after {
      color: #ffffff !important;
      font-size: 12px;
      top: -1px;
      font-weight: normal;
      width: 18px !important;
      height: 18px !important;
    }
    label:focus::before {
      border-color: #ffffff !important;
      background: #f9a44a !important;
    }
  }
`;

export default CheckboxWrapper;
