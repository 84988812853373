/* eslint-disable import/prefer-default-export */
import { applySnapshot, types } from 'mobx-state-tree';
import { getProgressTypes } from './ProgressEnum';

const Director = types.model('Director', {
  name: types.string,
  degree: types.string,
  titles: types.array(types.string),
});

const CertificateIssuer = types.model('CertificateIssuer', {
  name: types.string,
  department: types.string,
  director: Director,
});

const StudyModule = types.model('StudyModule', {
  id: types.identifier,
  studyId: types.string,
  quizId: types.maybeNull(types.string),
  index: types.number,
  name: types.string,
  description: types.string,
  workspaceTypes: types.string,
  enableWorkspace: types.optional(types.boolean, true),
  enable: types.boolean,
  progress: types.enumeration('ProgressTypes', getProgressTypes()),
  updatedAt: types.string,
  noActiveSubscriptionLimit: types.maybeNull(types.boolean),
});

const SurveyModule = types.model('SurveyModule', {
  id: types.identifier,
  surveyId: types.string,
  index: types.number,
  name: types.string,
  description: types.string,
  enable: types.boolean,
  progress: types.enumeration('ProgressTypes', getProgressTypes()),
  updatedAt: types.string,
  noActiveSubscriptionLimit: types.maybeNull(types.boolean),
});

const CertificateQuizModule = types.model('CertificateQuizModule', {
  id: types.identifier,
  certificateQuizId: types.string,
  index: types.number,
  name: types.string,
  programDuration: types.number,
  enable: types.boolean,
  progress: types.enumeration('ProgressTypes', getProgressTypes()),
  updatedAt: types.string,
  issuer: CertificateIssuer,
  minValidPercentage: types.optional(types.number, 100),
  maxNumberOfTrials: types.optional(types.number, 0),
  numberOfTrials: types.optional(types.number, 0),
  quizDuration: types.optional(types.number, 0),
});

const ElectiveStudyModule = types.model('ElectiveStudyModule', {
  id: types.identifier,
  studyId: types.string,
  index: types.number,
  name: types.string,
  description: types.string,
  workspaceTypes: types.string,
});

const ElectiveSurveyModule = types.model('ElectiveSurveyModule', {
  id: types.identifier,
  surveyId: types.string,
  index: types.number,
  name: types.string,
  description: types.string,
});

const Path = types.model('Path', {
  id: types.identifier,
  index: types.number,
  name: types.string,
  icon: types.string,
  isCertificatePath: types.optional(types.boolean, false),
  modules: types.array(types.union(StudyModule, SurveyModule, CertificateQuizModule)),
  electiveModules: types.optional(types.array(types.union(ElectiveStudyModule, ElectiveSurveyModule)), []),
});

const FinancialAid = types.model('FinancialAid', { applicationUrl: types.string, enabled: types.boolean });

const QuestionAndAnswer = types.model('QuestionAndAnswer', {
  icon: types.string,
  question: types.string,
  answer: types.string,
});

const Subscription = types.model('Subscription', {
  activated: types.boolean,
});

const CertificateProgram = types.model('CertificateProgram', {
  description: types.string,
  enabledModulesCount: types.number,
  joinUrl: types.string,
  price: types.number,
  financialAid: FinancialAid,
  questionsAndAnswers: types.array(QuestionAndAnswer),
});

const Learning = types
  .model('Learning', {
    id: types.identifier,
    name: types.string,
    index: types.number,
    description: types.string,
    pathCount: types.number,
    moduleCount: types.number,
    certificateCount: types.number,
    subscriptionRequired: types.optional(types.boolean, false),
    subscription: types.maybeNull(Subscription),
    paths: types.maybeNull(types.array(Path)),
    certificateProgram: types.maybeNull(CertificateProgram),
  })
  .actions(self => ({
    set(raw) {
      applySnapshot(self, raw);
    },
  }))
  .views(() => ({}));

export { Learning };
