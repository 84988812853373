import React from 'react';
import styled from 'styled-components';
import warningImg from '../../../../../images/warning.svg';
import { Icon } from 'semantic-ui-react';

const Button = styled.button`
  display: flex;
  justify-content: center;
  padding: 12px 16px;
  border: none;
  border-radius: 4px;
  background: #2e2d29;
  color: #fff;
  font-family: IBM Plex Mono;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
  &.secondary {
    border: 1px solid #2e2d29;
    background: #fff;
    color: #2e2d29;
  }

  :disabled {
    background: #969dab;
    color: #fff;
    cursor: none;
  }
`;

const LoadingText = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
  margin-top: 8px;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 500;
`;

const Spinner = styled.div`
  position: relative;
  left: 10px;
  width: 4.8px;
  height: 4.8px;
  border-radius: 4.8px;
  box-shadow: 12px 0px 0 0 rgba(46, 45, 41, 0.2), 9.7px 7.1px 0 0 rgba(46, 45, 41, 0.4),
    3.7199999999999998px 11.4px 0 0 rgba(46, 45, 41, 0.6), -3.7199999999999998px 11.4px 0 0 rgba(46, 45, 41, 0.8),
    -9.7px 7.1px 0 0 #2e2d29;
  animation: spinner 1s infinite linear;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Error = styled.span`
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: #e04f39;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 500;

  ::before {
    content: '';
    width: 12px;
    height: 12px;
    margin-right: 4px;
    background-image: url(${warningImg});
  }
`;

const Success = styled.span`
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: #2fb15b;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 500;

  > i {
    display: flex;
    align-items: center;
    color: #2fb15b;
  }
`;

const ActionButton = ({
  secondary,
  inlineLoading,
  loading,
  loadingText,
  errorText,
  successText,
  children,
  ...props
}) => (
  <div>
    <Button disabled={inlineLoading} {...props} className={secondary ? 'secondary' : ''}>
      <>
        {inlineLoading ? (
          <div className="mr2">
            <i className="sync loading icon" />
          </div>
        ) : (
          <></>
        )}
        {children}
      </>
    </Button>
    {loading ? (
      <LoadingText>
        <Spinner /> {loadingText}
      </LoadingText>
    ) : (
      <></>
    )}
    {errorText ? <Error>{errorText}</Error> : <></>}
    {successText ? (
      <Success>
        <Icon name="checkmark" /> {successText}
      </Success>
    ) : (
      <></>
    )}
  </div>
);
export default ActionButton;
