import styled from 'styled-components';

const TabContainer = styled.div`
  .tab-container > .menu {
    border-bottom: 1px solid #d7dbe3 !important;
    padding-bottom: 2px !important;
    margin: 32px 0px;

    > .item {
      margin-right: 80px !important;
      padding: 0px !important;
      padding-bottom: 18px !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      color: #969dab !important;

      &.active {
        border-bottom: 3px solid #2e2d29 !important;
        padding-bottom: 18px !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        color: #2e2d29 !important;
      }
    }
  }

  .tab {
    padding: 0px !important;
    border: 0px !important;
    background: #f5f5f5;
  }
`;

export default TabContainer;
