import React from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import jobImg from '../../../images/job.svg';

const Container = styled.div`
  padding: 32px 30px;
  background: #ffffff;
  border: ${p => (p.selected ? '1px solid #E98300' : '1px solid #d7dbe3')};
  box-shadow: ${p => (p.selected ? '0px 5.64706px 13.1765px rgba(255, 159, 71, 0.05)' : 'none')};
  border-radius: 8px;
  img {
    float: left;
    width: 45px;
    height: 55px;
    margin-right: 24px;
  }
  > i {
    float: right;
    margin-right: 0px;
    cursor: pointer;
  }
`;

const Content = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 272px;
  cursor: pointer;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 135%;
  color: #2e2d29;
  margin: 16px;
  margin: 0px 0px 16px 0px;
`;

const CompanyName = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin-top: 16px;
  margin: 0px;
`;

const Tags = styled.div`
  display: flex;
  flex-direction: column;
`;

const Location = styled.div`
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #969dab;
  margin: 16px 24px 0px 0px;
  display: flex;
  i {
    margin-right: 12px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
`;

const Position = styled.div`
  padding: 8px 10px;
  background: rgba(249, 164, 74, 0.1);
  border-radius: 4px;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #e98300;
  margin-right: 8px;
`;

const Salary = styled.div`
  padding: 8px 10px;
  background: rgba(249, 164, 74, 0.1);
  border-radius: 4px;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #e98300;
`;

const JobWidget = ({ selected, job, className, onClick, jobsStore }) => {
  const handleBookmarkClick = async () => {
    await jobsStore.toggleJobBookmark(job.id);
  };

  return (
    <Container selected={selected} className={className}>
      <img src={jobImg} alt="job" />
      <Content onClick={onClick}>
        <Title>{job.title}</Title>
        <CompanyName>{job.companyName}</CompanyName>
        <Tags>
          <Location>
            <i className="map marker alternate icon large" />
            {job.location}
          </Location>
          <Row>
            <Position>{job.position}</Position>
            <Salary>{job.salary}</Salary>
          </Row>
        </Tags>
      </Content>
      <i className={`bookmark icon large ${job?.bookmark?.value ? '' : 'outline'}`} onClick={handleBookmarkClick} />
    </Container>
  );
};

export default inject('jobsStore')(observer(JobWidget));
