import React, { useState } from 'react';
import _ from 'lodash';
import { QuestionHeader, QuestionIndex, QuestionStatement } from '../../components';
import styled from 'styled-components';
import QuestionContainer from './QuestionContainer';

const TextArea = styled.textarea`
  padding: 12px 16px;
  width: 100%;
  min-height: 320px;
  border-radius: 8px;
  border: 1px solid #d7dbe3;
  background: #fff;
  resize: none;
  outline: none;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 400;
  ::placeholder {
    color: #969dab;
  }
`;

const TextQuestion = ({ index, statement, hint, attachments, disabled, onTextChange }) => {
  const [text, setText] = useState();

  const textChangeHandler = e => {
    setText(e.target.value);
    onTextChange(e.target.value);
  };

  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionIndex>{`Question ${index + 1}`}</QuestionIndex>
        <QuestionStatement>{statement}</QuestionStatement>
        {!_.isNil(attachments) && attachments.length > 0 && attachments[0].type === 'Image' ? (
          <img className="col-9" src={attachments[0].path} alt="attachment" />
        ) : (
          ''
        )}
      </QuestionHeader>
      <div className="mt3">
        <TextArea value={text} placeholder={hint} disabled={disabled} onChange={textChangeHandler} />
      </div>
    </QuestionContainer>
  );
};

export default TextQuestion;
