import styled from 'styled-components';

const StepContainer = styled.div`
  p,
  li {
    color: #2e2d29;
    font-family: IBM Plex Sans;
    font-size: 16px;
    line-height: 145%;
  }
  > p {
    margin: 16px 0px;
  }

  ol {
    margin: 0px 0px 32px 0px;
    padding-left: 18px;

    > li {
      a {
        color: #2e2d29;
        text-decoration-line: underline;
      }

      b {
        &.green {
          color: #017e7c;
          font-family: IBM Plex Mono;
        }
      }

      > pre {
        position: relative;
        margin: 16px 0px;
        padding: 16px;
        background: #f5f5f5;
        border: 1px solid #d7dbe3;
        border-radius: 4px;
        overflow: auto;
        > code {
          color: #53565a;
          font-family: IBM Plex Mono;
          line-height: normal;
        }
      }
    }
  }
`;

export default StepContainer;
