import React from 'react';
import styled from 'styled-components';
import ActionButton from './ActionButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`;

const Title = styled.h2`
  color: #2e2d29;
  font-family: IBM Plex Sans;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.48px;
  margin: 0px 0px 32px 0px;
  align-self: center;
`;

const Description = styled.p`
  color: #2e2d29;
  font-family: IBM Plex Sans;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin: 0px 0px 48px 0px;
`;

const ResultModalContent = ({ title, description, action, onClose }) => {
  return (
    <Container>
      <i className="close" onClick={onClose} />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <ActionButton onClick={onClose}>{action}</ActionButton>
    </Container>
  );
};

export default ResultModalContent;
