import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Button, Icon, Input, Select } from 'semantic-ui-react';
import { swallowError } from '../../helpers/utils';
import { isStoreReloading } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import Messages from './Messages';
import sendImg from '../../../images/chatbot/send.svg';
import maximizeImg from '../../../images/chatbot/maximize.svg';
import _ from 'lodash';

const chatBotEngineOptions = [
  {
    text: 'Anthropic with AWS',
    value: 'claude-v2',
  },
  {
    text: 'GPT 4 by Open AI',
    value: 'gpt4',
  },
  {
    text: 'GPT 3.5 by Open AI',
    value: 'gpt3',
  },
];

const Container = styled.div`
  background: #ffffff;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0px 0px;
  padding: 0px 16px;
  height: 64px;
  background: #2e2d29;

  > div {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #e98300;

    > i {
      height: 20px;
      margin-right: 16px;
    }
  }

  > img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

const Content = styled.div`
  padding: 16px 16px 32px 16px;
  height: 100%;
  max-height: ${p => `calc(100% - ${p.valueToSubtractFromHeight}px)`};
  overflow: auto;
`;

const Prompt = styled.div`
  height: 104px;
  width: 100%;
  padding: 24px 16px;
  display: inline-block;
  position: relative;
  > button {
    position: absolute;
    right: 24px;
    bottom: 36px;
    border: none;
    background: transparent;
    cursor: pointer;
    :disabled {
      pointer-events: none;
    }
  }
  > textarea {
    padding: 16px;
    height: 56px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    background: #f6f6f6;
    font-family: IBM Plex Sans;
    font-weight: 450;
    font-size: 16px;
    resize: none;
    outline: none;
    ::placeholder {
      color: rgba(46, 45, 41, 0.3) !important;
    }
    :focus {
      border: 2px solid #e98300;
    }
  }
`;

const SelectBox = styled(Select)`
  margin: 0 1rem;
  lineheight: 2em;
`;

const ChatBot = ({ showMaximize = true, chatbotStore }) => {
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [loadingMessagesError, setLoadingMessagesError] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const [responseLoading, setResponseLoading] = useState(false);

  const contentRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!chatbotStore.sessionId) swallowError(chatbotStore.load());
  }, []);

  useEffect(() => {
    const loadMessages = async () => await chatbotStore.getMessages();

    if (chatbotStore.sessionId) {
      setLoadingMessages(true);
      loadMessages()
        .then(() => {
          setLoadingMessages(false);
        })
        .catch(e => {
          setLoadingMessages(false);
          setLoadingMessagesError(e);
        });
    }
  }, [chatbotStore.sessionId]);

  useEffect(() => {
    if (chatbotStore.messages.length > 0)
      contentRef.current?.scroll({ top: contentRef.current.scrollHeight, behavior: 'smooth' });
  });

  const handleEngineSelect = async (_, selected) => {
    setLoadingMessages(true);
    setResponseLoading(true);
    await chatbotStore.updateChatbotSession(selected.value);
    setLoadingMessages(false);
    setResponseLoading(false);
    inputRef.current?.focus();
  };

  const handleCreateResponse = async e => {
    e.preventDefault();
    if (chatbotStore.sessionId) {
      setResponseLoading(true);
      setCurrentMessage('');
      inputRef.current.style.height = '56px';
      await chatbotStore.createMessage({ content: currentMessage });
      setResponseLoading(false);
      inputRef.current?.focus();
    }
  };

  const handleMessageChange = e => {
    setCurrentMessage(e.target.value);
    if (e.target.scrollHeight >= 160) inputRef.current.style.height = '160px';
    else if (e.target.scrollHeight < 56) inputRef.current.style.height = '56px';
    else inputRef.current.style.height = `${e.target.scrollHeight}px`;
  };

  const handleKeyPress = e => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleCreateResponse(e);
    }
  };

  const renderContent = () => {
    if (chatbotStore.error || loadingMessagesError) {
      return <ErrorBox error={chatbotStore.error || loadingMessagesError} />;
    } else if (chatbotStore.loading || isStoreReloading(chatbotStore) || loadingMessages) {
      return <BasicProgressPlaceholder segmentCount={3} />;
    } else if (chatbotStore.ready && chatbotStore.sessionId) {
      return <Messages messages={chatbotStore.messages} maximized={!showMaximize} />;
    }
    return <BasicProgressPlaceholder segmentCount={3} />;
  };

  return (
    <Container>
      <Header>
        <div>
          <Icon name="ellipsis vertical" /> Ask AI Tutor with model:
          <SelectBox options={chatBotEngineOptions} defaultValue={chatbotStore.engine} onChange={handleEngineSelect} />
        </div>
        {showMaximize ? (
          <img
            src={maximizeImg}
            alt="maximize"
            link={true}
            onClick={() => window.open('/chatbot', '_blank', 'noopener,noreferrer')}
          />
        ) : (
          <></>
        )}
      </Header>
      <Content
        ref={contentRef}
        valueToSubtractFromHeight={
          inputRef.current?.style
            ? 184 + (inputRef.current.style.height === '' ? 0 : parseInt(inputRef.current.style.height) - 56)
            : 184
        }
      >
        {renderContent()}
      </Content>
      <form>
        <Prompt>
          <button
            type="button"
            onClick={handleCreateResponse}
            disabled={!chatbotStore.sessionId || currentMessage.length === 0 || responseLoading}
          >
            <img src={sendImg} alt="send" />
          </button>
          <textarea
            ref={inputRef}
            autoFocus
            placeholder="What do you like to know today?"
            value={currentMessage}
            disabled={responseLoading}
            onKeyUp={handleKeyPress}
            onChange={handleMessageChange}
          ></textarea>
        </Prompt>
      </form>
    </Container>
  );
};

export default inject('chatbotStore')(observer(ChatBot));