import React from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { ActionButton, Parameter, Title3 } from '../parts';
import { ParameterType } from '../../../../../models/workflow/ParameterType';
import { useState } from 'react';
import { inject } from 'mobx-react';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px;
  border-radius: 8px;
  border: 1px solid rgba(14, 14, 44, 0.05);
  background: #fff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 20px 36px -8px rgba(14, 14, 44, 0.1);

  h1,
  h3,
  h5,
  p,
  label,
  span {
    color: #2e2d29;
    font-family: IBM Plex Sans;
  }
  h1 {
    margin: 0px;
    font-size: 24px;
    font-weight: 700;
  }
  p {
    margin: 0px;
    font-size: 16px;
    font-weight: 450;
    line-height: 140%;
  }

  hr {
    width: 100%;
    border-top: 1px solid #d7dbe3;
  }
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-height: 600px;
  padding-right: 8px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Section = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 24px;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 16px;
  > div {
    width: 100%;
    > button {
      width: 100%;
    }
  }
`;

const ReviewModal = ({ open, config, workflow, parameters, onClose, onComplete, newWorkflowStore }) => {
  const [loading, setLoading] = useState();

  const handleCreateRunClick = () => {
    setLoading(true);
    newWorkflowStore
      .createWorkflowRun(workflow.workflowName, parameters)
      .then(() => {
        setLoading(false);
        onClose();
        onComplete();
      })
      .catch(error => {
        displayError(error);
        setLoading(false);
      });
  };
  return (
    <Modal open={open} size="small" onClose={onClose}>
      <Container>
        <i className="close" onClick={onClose} />
        <h1>Review run details.</h1>
        <p>Make sure the run details are correct.</p>
        <Sections>
          <Section>
            <Title3>Select genomics data</Title3>
            <div>
              <Parameter
                index={0}
                type={ParameterType.String}
                label="Input location"
                defaultValue={config.inputBucketPath}
                readonly={true}
              />
              <Parameter
                index={1}
                type={ParameterType.String}
                label="Output location"
                defaultValue={config.outputBucketPath}
                readonly={true}
              />
              <Parameter index={2} label="Read and write are access granted." />
            </div>
          </Section>
          <div>
            <Title3>Select workflow</Title3>
            <Parameter type={ParameterType.String} defaultValue={workflow.workflowName} readonly={true} />
          </div>
          <hr />
          <Section>
            <Title3>Add parameters</Title3>
            <div>
              {Object.keys(workflow.template)
                .map(key => ({
                  parameterName: key,
                  ...workflow.template[key],
                }))
                .sort((a, b) => a.order - b.order)
                .map((p, index) => (
                  <Parameter
                    key={p.parameterName}
                    index={index}
                    {...p}
                    defaultValue={parameters[p.parameterName]}
                    readonly={true}
                  />
                ))}
            </div>
          </Section>
        </Sections>
        <Actions>
          <ActionButton secondary={true} onClick={onClose}>
            No, do later
          </ActionButton>
          <ActionButton inlineLoading={loading} onClick={handleCreateRunClick}>
            <span role="img" aria-label="rocket">
              🚀
            </span>{' '}
            Start run
          </ActionButton>
        </Actions>
      </Container>
    </Modal>
  );
};

export default inject('newWorkflowStore')(ReviewModal);
