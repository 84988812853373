function getProgressTypes() {
  return [ProgressEnum.NotStarted, ProgressEnum.InProgress, ProgressEnum.Completed];
}

const ProgressEnum = {
  NotStarted: 'NotStarted',
  InProgress: 'InProgress',
  Completed: 'Completed',
  getProgressTypes,
};

module.exports = ProgressEnum;
