import React from 'react';
import styled from 'styled-components';
import certificate from '../../../../images/certificate-path.svg';
import certificateActive from '../../../../images/certificate-path-active.svg';
import certificateCompleted from '../../../../images/certificate-path-completed.svg';

const getUrl = (active, completed) => {
  if (completed) return certificateCompleted;
  if (active) return certificateActive;
  return certificate;
};

const StepIcon = styled.span`
  background-image: url(${p => getUrl(p.active, p.completed)});
  background-repeat: no-repeat;
  width: ${p => (p.completed ? '102px' : '75px')};
  height: 90px;
`;

const Container = styled.div`
  place-items: center;
  display: inline-flex;
  height: 90px;
`;

const CertificatePathIcon = ({ active, completed }) => {
  return (
    <Container>
      <StepIcon active={active} completed={completed} />
    </Container>
  );
};

export default CertificatePathIcon;
