import React from 'react';
import Filter from '../actions/Filter';
import Sort from '../actions/Sort';
import ResultCount from '../actions/ResultCount';
import Title from '../actions/Title';

const DataActions = props => {
  return (
    <div className="data-actions pt2">
      <div className="col col-3">
        <Title className="pr3">Filter</Title>
        <Filter onChange={props.onFilterChange} />
      </div>
      <div className="col col-3 flex">
        <Title className="pr3">Sort</Title>
        <Sort value="New Projects" onChange={props.onSortChange} />
      </div>
      {props.resultsCount > 0 ? (
        <ResultCount className="col col-1 col-right">{props.resultsCount} project</ResultCount>
      ) : (
        ''
      )}
    </div>
  );
};

export default DataActions;
