import React from 'react';
import styled from 'styled-components';
import RocketButton from './RocketButton';

const Container = styled.div`
  display: flex;
  padding: 8.571em;
  justify-content: center;
  @media (max-width: 1199px) {
    padding: 8.571em 60px;
  }
  @media (max-width: 992px) {
    padding: 8.571em 14px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 40px;
  background: #fff9f0;
  border: 1px solid rgba(233, 131, 0, 0.3);
  border-radius: 20px;
  justify-content: space-between;
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    max-width: 46.428em;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    gap: 20px;
    text-align: center;
    padding: 20px;
  }
`;

const Title = styled.h2`
  font-weight: 700;
  line-height: 150%;
  margin: 0px 0px 14px 0px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: justify;
  margin: 0px;
`;

const Notice = ({ title, description, actionText, url }) => {
  return (
    <Container>
      <Content>
        <div>
          <Title>👋 {title}</Title>
          <Description>{description}</Description>
        </div>
        <RocketButton url={url}>{actionText}</RocketButton>
      </Content>
    </Container>
  );
};

export default Notice;
