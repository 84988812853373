import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Index = styled.span`
  color: #fbbf80;
  font-size: 10px;
  font-family: IBM Plex Mono;
  font-weight: 700;
  line-height: 8px;
`;

const Header = ({ index, icon }) => (
  <Container>
    <Index>{(index + 1).toString().padStart(2, '0')}</Index>
    <Icon icon={icon} />
  </Container>
);

export default Header;
