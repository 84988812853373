import { types } from 'mobx-state-tree';
import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import {
  getUserLearnings,
  getUserLearning,
  startUserLearningModule,
  activateUserLearningSubscription,
} from '../../helpers/api';
import { Learning } from './Learning';

// ==================================================================
// LearningStore
// ==================================================================
const LearningStore = BaseStore.named('LearningStore')
  .props({
    userLearnings: types.array(Learning),
  })
  .actions(self => {
    return {
      async doLoad() {
        const userLearnings = await getUserLearnings();
        self.runInAction(() => {
          self.userLearnings = userLearnings;
        });
      },

      async startUserLearningModule(userLearningId, moduleId, studyId) {
        const result = await startUserLearningModule(userLearningId, moduleId, studyId);
        const old = self.userLearnings.find(ul => ul.id === result.id);
        if (old) {
          self.runInAction(() => {
            old.set(result);
          });
          return old;
        }
        self.runInAction(() => {
          self.userLearnings.push(result);
        });
        return self.userLearnings.at(-1);
      },

      async getUserLearning(userLearningId) {
        const result = await getUserLearning(userLearningId);
        if (result.subscriptionRequired && !result.subscription?.activated) {
          let currentModulesCount = 0;
          for (let pIndex = 0; pIndex < result.paths.length; pIndex++) {
            const path = result.paths[pIndex];
            if (path.isCertificatePath) continue;
            for (let mIndex = 0; mIndex < path.modules.length; mIndex++) {
              if (!path.modules[mIndex].surveyId) currentModulesCount++;
              path.modules[mIndex].noActiveSubscriptionLimit =
                currentModulesCount > result.certificateProgram.enabledModulesCount;
            }
          }
        }

        const old = self.userLearnings.find(ul => ul.id === result.id);
        if (old) {
          self.runInAction(() => {
            old.set(result);
          });
          return old;
        }
        self.runInAction(() => {
          self.userLearnings.push(result);
        });
        return self.userLearnings.at(-1);
      },

      async activateUserLearningSubscription(userLearningId, code) {
        await activateUserLearningSubscription(userLearningId, { code });
      },
    };
  })
  .views(() => ({}));

function registerContextItems(appContext) {
  appContext.learningStore = LearningStore.create({}, appContext);
}

export { LearningStore, registerContextItems };
