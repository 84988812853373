import React from 'react';
import styled from 'styled-components';

const Container = styled.li`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #969dab;
  cursor: pointer;

  &:after {
    content: '';
    border-top: 4px dashed #d7dbe3;
    order: -1;
    position: relative;
    top: 2.5rem;
    left: 50%;
    z-index: -1;
  }

  &:last-child:after {
    content: '';
    width: 0px;
  }

  &.in-progress:not(:last-child):after {
    border-top: 4px dashed #f9a44a;
  }

  &.completed:not(:last-child):after {
    border-top: 4px solid #f9a44a;
  }
`;

const Title = styled.div`
  padding-top: ${p => (p.isCertificatePath ? '0px' : '14px')};
  margin-top: ${p => (p.isCertificatePath ? '-17px' : '0px')};

  &.completed {
    font-weight: 700;
    color: #e98300;
  }
`;
const StepItem = props => {
  return (
    <Container className={`${props.inProgress ? 'in-progress' : ''} ${props.completed ? 'completed' : ''}`}>
      <div className="flex-column items-center" onClick={props.onClick}>
        {React.cloneElement(props.children, { active: props.active, completed: props.completed })}
        <Title
          isCertificatePath={props.isCertificatePath}
          className={`${props.active || props.completed ? 'completed' : ''}`}
        >
          {props.title}
        </Title>
      </div>
    </Container>
  );
};

export default StepItem;
