import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8.571em;
  align-items: center;
`;

const Title = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.11em;
  margin: 0px 0px 40px 0px;
`;

const Logo = styled(Image)`
  height: 40px;
  cursor: pointer;
`;

const Partners = ({ partners }) => {
  return (
    <Container>
      <Title>Partnered with</Title>
      <Grid container stackable centered stretched>
        {partners.map(p => (
          <Grid.Column key={p.name} computer={3} tablet={5} mobile={16}>
            <Logo src={`${window.location.origin}/static/${p.logo}`} alt={p.name} href={p.link} />
          </Grid.Column>
        ))}
      </Grid>
    </Container>
  );
};

export default Partners;
