import styled from 'styled-components';

export const ModuleContainer = styled.div`
  background: #ffffff;
  border: 1px solid #d7dbe3;
  border-radius: 8px;
  width: 450px;
`;

export const ModuleIndex = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  color: #969dab;

  &.active {
    color: #fbbf80;
  }
`;

export const ModuleTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #2e2d29;
`;

export const ModuleDescription = styled.p`
  min-height: 40px;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;
