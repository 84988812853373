import React from 'react';
import { useState } from 'react';
import { Radio, Table } from 'semantic-ui-react';
import styled from 'styled-components';

const CustomTable = styled(Table)`
  border: none !important;

  * {
    font-family: IBM Plex Sans;
    font-size: 16px;
    line-height: 135%;
  }
  > tbody {
    ::before {
      content: '';
      display: block;
      height: 24px;
    }

    > tr {
      &.checked {
        > td {
          background: #e9f5f3 !important;
        }
        > :nth-child(1) {
          border-radius: 8px 0px 0px 8px;
        }
        > :last-child {
          border-radius: 0px 8px 8px 0px;
        }
      }
    }
  }

  th,
  td {
    padding: 16px !important;
    border: none !important;
    background: #fff !important;
  }

  th {
    color: #53565a !important;
    font-weight: 500 !important;
  }

  td {
    color: #2e2d29;
    font-weight: 400;

    &.title {
      overflow: hidden;
      color: #017e7c !important;
      text-overflow: ellipsis;
      text-decoration-line: underline;
    }
  }
`;

const WorkflowsTable = ({ defaultWorkflow, workflows, onStepCompleted }) => {
  const [selectedWorkflow, setSelectedWorkflow] = useState(defaultWorkflow);

  const hanldeCheckBoxClick = workflow => {
    setSelectedWorkflow(workflow);
    onStepCompleted(workflow);
  };
  return (
    <CustomTable singleLine>
      <Table.Header>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell>Workflow Name</Table.HeaderCell>
        <Table.HeaderCell>Cost per run</Table.HeaderCell>
        <Table.HeaderCell>Estimated Time</Table.HeaderCell>
        <Table.HeaderCell>Version</Table.HeaderCell>
        <Table.HeaderCell>Last Published</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {workflows.map(w => (
          <Table.Row
            key={w.workflowName}
            className={selectedWorkflow?.workflowName === w.workflowName ? 'checked' : ''}
          >
            <Table.Cell collapsing>
              <Radio
                checked={selectedWorkflow?.workflowName === w.workflowName}
                onClick={() => hanldeCheckBoxClick(w)}
              />
            </Table.Cell>
            <Table.Cell className="title">{w.workflowName}</Table.Cell>
            <Table.Cell collapsing>{w.priceRange}</Table.Cell>
            <Table.Cell collapsing> - </Table.Cell>
            <Table.Cell collapsing textAlign="right">
              {' - '}
            </Table.Cell>
            <Table.Cell collapsing> - </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </CustomTable>
  );
};

export default WorkflowsTable;
