import React from 'react';
import { Header } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';
import ActionButton from './ActionsButton';

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #d7dbe3;
  border-radius: 8px;
`;

const Content = styled.div`
  width: 100%;
`;

const Title = styled(Header)`
  font-size: 20px !important;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.4px;
  color: #2e2d29;
`;

const Subtitle = styled.div`
  span {
    font-weight: 450;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #969dab;
  }
`;

const Description = styled.div`
  h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #2e2d29;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: #969dab;
    .disabled {
      color: #2e2d29;
    }
  }
`;

const Studies = styled.div`
  background: #fafafa;
  border-radius: 8px;
  h2 {
    border-bottom: 1px solid #d7dbe3;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.4px;
    color: #252733;
  }
  ul {
    li {
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 135%;
        color: #2e2d29;
        display: inline-block;
        margin: 0px;
      }
      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        color: #969dab;
        font-style: italic;
        &.disabled {
          pointer-events: none;
        }
      }
    }
  }
`;

const DataProject = ({ id, name, description, workspace, studies, instanceSpec, createdAt }) => (
  <Container className="flex mt3 p3">
    <Content>
      <div className="flex flex-column">
        <div className="flex justify-between">
          <div className="col col-6">
            <Title>{name}</Title>
            <Subtitle className="sub-header flex flex-column mt3">
              <span className="mb1">Workspace Id: {id}</span>
              <span>Created on: {moment(createdAt).format('MM/D/yyyy')}</span>
            </Subtitle>
          </div>
          <div className="col-right">
            <ActionButton
              id={id}
              name={name}
              description={description}
              workspace={workspace}
              studies={studies}
              instanceSpec={instanceSpec}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <Description className="col col-7 pt3">
            <h2>Overview</h2>
            {_.isNil(description) ? (
              <p className="disabled">No project description available.</p>
            ) : (
              <p>{description}</p>
            )}
          </Description>
          {_.isEmpty(studies) ? (
            <></>
          ) : (
            <>
              <Studies className="flex flex-column col col-4 p3">
                <h2 className="pb2 mb2">Source data</h2>
                <ul className="pl3 m0">
                  {studies.map((s, i) => (
                    <li key={s.id} className={i > 0 ? 'mt1' : ''}>
                      <p>{s.name}.</p>{' '}
                      <a
                        href={_.isNil(s.publication) ? '' : s.publication.url}
                        target={_.isNil(s.publication) ? '' : s.publication.url}
                        rel="noreferrer"
                        className={_.isNil(s.publication) ? 'disabled' : ''}
                      >
                        {_.isNil(s.publication) ? 'Not Available' : s.publication.source}
                        {', '}
                        {moment(s.createdAt).format('MMMM yyyy')}
                      </a>
                    </li>
                  ))}
                </ul>
              </Studies>
            </>
          )}
        </div>
      </div>
    </Content>
  </Container>
);

export default DataProject;
