import React, { useState } from 'react';
import { Accordion } from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled.div`
  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.4px;
    color: #252733;
    border-top: 1px solid #d7dbe3;
    span {
      float: right;
      font-size: 16px;
    }
  }
  .content {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.1px;
    .tag {
      display: inline-block;

      border-radius: 4px;
      backdrop-filter: blur(3.5px);

      &.datatype-1 {
        color: #016895;
        background: rgba(66, 152, 181, 0.1);
      }
      &.datatype-2 {
        color: #e04f39;
        background: rgba(224, 79, 57, 0.1);
      }
      &.datatype-3 {
        color: #734ce1;
        background: rgba(115, 76, 225, 0.1);
      }
      &.datatype-4 {
        color: #70581a;
        background: rgba(112, 88, 26, 0.15);
      }
      &.datatype-5 {
        color: #7f2d48;
        background: rgba(101, 28, 50, 0.1);
      }
      &.datatype-6 {
        color: #b48806;
        background: rgba(255, 231, 129, 0.25);
      }
      &.datatype-7 {
        color: #2666b6;
        background: rgba(97, 144, 202, 0.15);
      }
      &.datatype-8 {
        color: #417865;
        background: rgba(111, 162, 135, 0.15);
      }
      &.phenotype {
        color: #544948;
        background: rgba(127, 119, 118, 0.1);
      }
    }
  }
`;
const AccordionItem = ({ title, active, children }) => {
  const [activeState, setActive] = useState(active);

  return (
    <Container>
      <Accordion.Title active={activeState} className="pt2 pb3 pr1" onClick={() => setActive(!activeState)}>
        {title}
        <span>{activeState ? '−' : '+'}</span>
      </Accordion.Title>
      <Accordion.Content active={activeState} className="p0 mb3">
        {children}
      </Accordion.Content>
    </Container>
  );
};

export default AccordionItem;
