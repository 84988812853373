import React from 'react';
import swal from '@sweetalert/with-react';

const sendtoAPIGateway = (apigateway, data) => {
  fetch(apigateway, {
    method: 'post',
    mode: 'cors',
    contentType: 'application/json',
    body: JSON.stringify(data),
  })
    .then(response => {
      return response.json();
    })
    .then(json =>
      swal(
        <div>
          <h1>{json}</h1>
        </div>,
      ),
    );
};

export default sendtoAPIGateway;
