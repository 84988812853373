import React, { useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import ActionItem from './ActionItem';
import DeleteConfirmationModal from './DeleteConfirmationModal';

const DeleteAction = ({ onDelete }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Dropdown.Item>
        <div
          className="flex items-center px1 py2"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <Icon name="trash alternate outline" />
          <ActionItem className="flex flex-column pl3">
            <h3 className="mb1">Delete VM</h3>
            <p>Remove the machine will delete your work permanently. This action is not reversible.</p>
          </ActionItem>
        </div>
      </Dropdown.Item>
      <DeleteConfirmationModal
        modalOpen={modalOpen}
        onSubmit={() => {
          setModalOpen(false);
          onDelete();
        }}
        onCancel={() => setModalOpen(false)}
      />
    </>
  );
};

export default DeleteAction;
