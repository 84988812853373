import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Container, Header, Tab } from 'semantic-ui-react';
import _ from 'lodash';
import TabContainer from '../components/TabContainer';
import { useState } from 'react';
import SetupRun from './parts/SetupRun';
import AllRuns from './parts/AllRuns';

const PaneType = {
  SetupRun: 'Set up a run',
  AllRuns: 'All runs',
};

const WorkflowPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const panes = [
    {
      menuItem: PaneType.SetupRun,
      render: () => <SetupRun onNewRun={() => setActiveIndex(1)} />,
    },
    {
      menuItem: PaneType.AllRuns,
      render: () => <AllRuns />,
    },
  ];
  const renderTitle = () => {
    return (
      <div className="flex justify-between">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Header.Content className="left-align">Workflow</Header.Content>
          <Header.Subheader className="left-align">Process sequencing data with pre-built pipelines.</Header.Subheader>
        </Header>
      </div>
    );
  };

  return (
    <Container className="mt4 pb4">
      {renderTitle()}
      <TabContainer>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={activeIndex}
          className="tab-container"
          onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
        />
      </TabContainer>
    </Container>
  );
};

export default withRouter(observer(WorkflowPage));
