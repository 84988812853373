import React from 'react';
import domtoimage from 'dom-to-image';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';
import styled from 'styled-components';
import ProgressEnum from '../../../../models/learning/ProgressEnum';
import badgeImg from '../../../../images/badge.svg';
import badgeCompletedImg from '../../../../images/badge-completed.svg';

const BadgeCotainer = styled.div`
  background: #fffaf1;
  border: 2.23299px solid #f9a44a;
  box-shadow: 0px 41.0634px 68.4389px rgba(249, 164, 74, 0.1);
  border-radius: 17.8639px;
  height: 440px;
`;

const PathInfo = styled.div`
  span {
    line-height: 135%;
    color: #2e2d29;
    text-align: center;
    width: 100%;
  }
`;

const PathTitle = styled.span`
  font-weight: 700;
  font-size: 24px;
`;

const PathSubtitle = styled.span`
  font-weight: 400;
  font-size: 16px;
`;

const Details = styled.div`
  margin-top: 18px;
  span {
    line-height: 135%;
    color: #2e2d29;
    text-align: center;
  }
`;

const DetailsTitle = styled.span`
  font-family: 'IBM Plex Serif';
  font-weight: 700;
  font-size: 34px;
`;

const DetailsSubtitle = styled.span`
  font-weight: 400;
  font-size: 16px;
`;

const DetailsDate = styled.span`
  font-weight: 400;
  font-size: 16px;
`;

const BadgeCompletedImgContainer = styled.div`
  background-image: url(${badgeCompletedImg});
  background-repeat: no-repeat;
  height: 215px;
`;

const BadgeImgContainer = styled.div`
  background-image: url(${badgeImg});
  background-repeat: no-repeat;
  background-position: center;
  height: 440px;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 135%;
  text-align: center;
  color: #2e2d29;

  &.disabled {
    color: #969dab;
  }
`;

const Description = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #2e2d29;

  &.disabled {
    color: #969dab;
  }
`;

const Download = styled.button`
  border: none;
  padding: 12px 16px;
  height: 46px;
  background: #f9a44a;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

const Badge = ({ pathName, modules, userStore }) => {
  const pathCompleted = modules.every(m => m.progress === ProgressEnum.Completed);

  const renderBadge = () => {
    const currentUser = userStore.user;
    const displayName = `${currentUser.firstName.toUpperCase()} ${currentUser.lastName.toUpperCase()}`;

    if (pathCompleted)
      return (
        <BadgeCotainer className="sm-col-6 md-col-6 lg-col-6 mx-auto my3 px1">
          <BadgeCompletedImgContainer className="block mx-auto">
            <PathInfo className="py4 flex flex-column items-center">
              <PathTitle>{pathName}</PathTitle>
              <PathSubtitle className="pt1">Badge for completing training modules</PathSubtitle>
            </PathInfo>
            <Details className="sm-col-8 md-col-8 lg-col-8 mx-auto flex flex-column items-center">
              <DetailsTitle className="title">{displayName}</DetailsTitle>
              <DetailsSubtitle className="pt1">
                is awarded this badge as a proof of the training completion by Stanford Data Ocean on
              </DetailsSubtitle>
              <DetailsDate>
                {moment(_.orderBy(modules, [item => item.updatedAt], ['desc'])[0].updatedAt).format('MMMM DD, yyyy')}.
              </DetailsDate>
            </Details>
          </BadgeCompletedImgContainer>
        </BadgeCotainer>
      );
    return <BadgeImgContainer className="sm-col-6 md-col-6 lg-col-6 mx-auto my3" />;
  };

  const handleDownload = () => {
    const element = document.getElementById('moduleBadge');
    const scale = 2;
    const style = {
      transform: `scale(${scale})`,
      transformOrigin: 'top left',
      width: `${element.offsetWidth}px`,
      height: `${element.offsetHeight}px`,
    };
    const param = {
      width: element.offsetWidth * scale,
      height: (element.offsetHeight + 100) * scale,
      quality: 1,
      style,
    };

    domtoimage.toPng(element, param).then(dataUrl => {
      const link = document.createElement('a');
      link.download = pathName;
      link.href = dataUrl;
      link.click();
    });
  };

  return (
    <>
      <div id="moduleBadge" className="clearfix my4">
        {renderBadge()}
      </div>
      <div className="clearfix">
        <div className="sm-col-5 md-col-5 lg-col-5 mx-auto flex flex-column items-center">
          <Title className={pathCompleted ? '' : 'disabled'}>
            {pathCompleted
              ? `You've completed training for ${pathName}!`
              : 'Earn training badge by collecting badges in this module.'}
          </Title>
          <Description className={pathCompleted ? '' : 'disabled'}>
            Add the training badge on college applications, resume, and LinkedIn to demonstrate your qualifications.
          </Description>
          {pathCompleted ? (
            <Download type="button" onClick={handleDownload}>
              <span role="img" aria-label="diploma">
                🎓
              </span>{' '}
              Download Badge
            </Download>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default inject('userStore')(observer(Badge));
