import React from 'react';
import _ from 'lodash';
import ProgressEnum from '../../../../models/learning/ProgressEnum';

const HeightMultiplier = 584;

const getStroke = active => (active ? '#F9A44A' : '#D7DBE3');
const getStrokeDash = active => (active ? '#FEF1E4' : '#969DAB');

const PathStartPart = ({ active }) => {
  const d = 'M1 8  H260';
  return (
    <>
      <path stroke={getStroke(active)} strokeWidth="16" d={d} />;
      <path stroke={getStrokeDash(active)} strokeWidth="2" strokeDasharray="8 8" strokeLinecap="round" d={d} />;
      <circle cx="15" cy="8" r="12" stroke={getStroke(active)} fill={getStrokeDash(active)} strokeWidth="8" />
    </>
  );
};

const PathMiddlePart = ({ active, index, reverse, middle }) => {
  let start = 260;
  let end = 854;
  const newMiddle = 552.5;
  if (reverse) {
    start = 854;
    end = 260;
  }
  if (middle) end = newMiddle;

  const d = `M${start} ${8 + index * HeightMultiplier}  H${end}`;
  return (
    <>
      <path stroke={getStroke(active)} strokeWidth="16" d={d} />
      <path stroke={getStrokeDash(active)} strokeWidth="2" strokeDasharray="8 8" strokeLinecap="round" d={d} />
    </>
  );
};

const PathRightCurvePart = ({ active, index }) => {
  const d = `M845 ${8 + index * HeightMultiplier}  H1025 C1070 ${8 + index * HeightMultiplier} 1105 ${42 +
    index * HeightMultiplier} 1105 ${88 + index * HeightMultiplier} V${512 + index * HeightMultiplier} C1105 ${558 +
    index * HeightMultiplier} 1070 ${8 + (index + 1) * HeightMultiplier} 1025 ${8 +
    (index + 1) * HeightMultiplier} H845`;
  return (
    <>
      <path stroke={getStroke(active)} strokeWidth="16" d={d} />
      <path stroke={getStrokeDash(active)} strokeWidth="2" strokeDasharray="8 8" strokeLinecap="round" d={d} />
    </>
  );
};

const PathLeftCurvePart = ({ active, index }) => {
  const d = `M260 ${8 + index * HeightMultiplier}  H80 C35 ${8 + index * HeightMultiplier}  0 ${42 +
    index * HeightMultiplier}  0 ${88 + index * HeightMultiplier}  V${512 + index * HeightMultiplier} C0 ${558 +
    index * HeightMultiplier} 35 ${8 + (index + 1) * HeightMultiplier} 80 ${8 + (index + 1) * HeightMultiplier} H260`;
  return (
    <>
      <path stroke={getStroke(active)} strokeWidth="16" d={d} />
      <path stroke={getStrokeDash(active)} strokeWidth="2" strokeDasharray="8 8" strokeLinecap="round" d={d} />
    </>
  );
};

const PathProgress = ({ modules }) => {
  const modulesCount = modules.length;
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`-8 -8 1121 ${(Math.floor(modulesCount / 2) + 1) * HeightMultiplier + 24}`}
    >
      {_.chunk(modules, 2).map((c, cIndex) => {
        const reverseRow = cIndex % 2 !== 0;
        const paths = [];
        if (cIndex === 0) paths.push(<PathStartPart active={c[0].progress === ProgressEnum.Completed} />);

        if (c.length === 1) {
          if (cIndex > 0 && modules[cIndex * 2 - 1].progress === ProgressEnum.Completed) {
            paths.push(<PathMiddlePart index={cIndex} active reverse={reverseRow} middle />);
            paths.push(
              <PathMiddlePart
                index={cIndex}
                active={c[0].progress === ProgressEnum.Completed}
                reverse={!reverseRow}
                middle
              />,
            );
          } else {
            paths.push(
              <PathMiddlePart index={cIndex} active={c[0].progress === ProgressEnum.Completed} reverse={reverseRow} />,
            );
          }
          if (reverseRow)
            paths.push(<PathLeftCurvePart index={cIndex} active={c[0].progress === ProgressEnum.Completed} />);
          else paths.push(<PathRightCurvePart index={cIndex} active={c[0].progress === ProgressEnum.Completed} />);
          paths.push(
            <PathMiddlePart
              index={cIndex + 1}
              active={c[0].progress === ProgressEnum.Completed}
              reverse={!reverseRow}
              middle
            />,
          );
        } else {
          paths.push(
            <PathMiddlePart index={cIndex} active={c[0].progress === ProgressEnum.Completed} reverse={reverseRow} />,
          );
          if (reverseRow)
            paths.push(<PathLeftCurvePart index={cIndex} active={c[1].progress === ProgressEnum.Completed} />);
          else paths.push(<PathRightCurvePart index={cIndex} active={c[1].progress === ProgressEnum.Completed} />);
          if ((cIndex + 1) * 2 === modulesCount)
            paths.push(
              <PathMiddlePart
                index={cIndex + 1}
                active={c[1].progress === ProgressEnum.Completed}
                reverse={!reverseRow}
                middle
              />,
            );
        }

        return paths;
      })}
    </svg>
  );
};

export default PathProgress;
