const EnvironmentStatus = {
  Starting: 'STARTING',
  Pending: 'PENDING',
  Stopped: 'STOPPED',
  Stopping: 'STOPPING',
  Terminating: 'TERMINATING',
  ShuttingDown: 'SHUTTING-DOWN',
  Terminated: 'TERMINATED',
  Running: 'RUNNING',
};

export default EnvironmentStatus;
