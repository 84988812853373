import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { inject, observer } from 'mobx-react';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';
import TimeAgo from 'react-timeago';
import JobWidget from './JobWidget';

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #d7dbe3;
  border-radius: 8px;
  padding-right: 4px;
`;

const Content = styled.div`
  padding: 32px 30px;
  display: flex;
  flex-direction: column;
`;

const ActionButtons = styled.div`
  margin: 0px 30px 32px 30px;
`;

const CustomJobWidget = styled(JobWidget)`
  border: none;
  padding: 0px;
  > div {
    cursor: default;
    max-width: unset;
    > div {
      width: 100%;
      flex-direction: row;
      align-items: center;
    }
  }
`;

const JobDescription = styled.div`
  margin: 24px -28px 0px 0px;
  padding-right: 18px;
  height: 100vh;
  overflow: auto;
`;

const JobDescriptionContent = styled(ReactMarkdown)`
  font-family: 'IBM Plex Sans';
  color: #2e2d29;
  letter-spacing: 0.2px;
  line-height: 20px;
  h3 {
    font-weight: 700;
    font-size: 18px;
  }
  h4 {
    font-weight: 700;
    font-size: 16px;
  }
  p,
  li {
    font-weight: 450;
    font-size: 16px;
  }
`;

const JobDetails = ({ job, jobsStore }) => {
  const [loading, setLoading] = useState(false);
  const [buttonInProgress, setButtonInProgress] = useState(false);
  const [error, setError] = useState();
  const [fullJob, setFullJob] = useState();

  useEffect(() => {
    const getJob = async id => {
      const newJob = await jobsStore.getJob(id);
      setFullJob(newJob);
      setLoading(false);
    };

    setLoading(true);
    getJob(job.id).catch(error => {
      setLoading(false);
      setError(error.message);
    });
  }, [job.id]);

  const handleApplyClick = async () => {
    try {
      setButtonInProgress(true);
      await jobsStore.createJobApplication(job.id);
      window.open('https://deepdata.stanford.edu/careers/', '_blank', 'noopener,noreferrer');
    } catch (error) {
      displayError(error);
    }
    setButtonInProgress(false);
  };

  const renderContent = () => {
    if (error) {
      return <ErrorBox error={error} className="mr0 ml0" />;
    }
    if (loading) {
      return <BasicProgressPlaceholder segmentCount={3} className="mr0 ml0" />;
    }
    if (fullJob) {
      return (
        <>
          <JobDescriptionContent children={fullJob.description} />
        </>
      );
    }
    return <></>;
  };

  const renderButton = () => {
    if (buttonInProgress)
      return (
        <button type="button" className="progress">
          <div className="mr2">
            <i className="sync loading icon" />
          </div>
          Applying...
        </button>
      );
    if (job.application)
      return (
        <button type="button" disabled className="primary-black disabled">
          Applied&nbsp;
          <TimeAgo date={job.application.createdAt} />
        </button>
      );
    return (
      <button type="button" className="primary-black" onClick={handleApplyClick}>
        Apply
      </button>
    );
  };

  return (
    <Container className="col col-7">
      <Content>
        <CustomJobWidget job={job} />
        <JobDescription>{renderContent()}</JobDescription>
      </Content>
      <ActionButtons className="custom-actions">{renderButton()}</ActionButtons>
    </Container>
  );
};

export default inject('jobsStore')(observer(JobDetails));
