import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled.div`
  span {
    display: flex;
    width: 48px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    margin-right: 30px;
    background: ${p => (p.completed ? '#2FB15B' : '#F5F5F5')};
    font-size: 16px;
    font-weight: 700;
    > i {
      margin: 0px;
      color: #fff;
    }
  }
`;

const StepCircle = ({ index, completed }) => (
  <Container completed={completed}>
    <span>{completed ? <Icon name="checkmark" /> : index}</span>
  </Container>
);

export default StepCircle;
