import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  * {
    color: #2e2d29;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-weight: 450;
    line-height: 145%;
    letter-spacing: 0.2px;
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    justify-content: center;
    align-items: start;
    gap: 10px;
    border-radius: 8px;
    background: #fde8d1;
    > p {
      margin: 0px;
      > a {
        text-decoration: underline;
      }
    }

    > ul {
      display: grid;
      margin: 10px 0px 6px 0px;
      gap: 16px;
    }
  }
`;

const Hello = styled.p`
  font-size: 32px;
  margin: 0px;
`;

const QuestionFormat = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  gap: 10px;
  font-weight: 600;
  > span {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 12px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.7);
    color: #53565a;
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 0.75px solid #53565a;
      font-size: 8px;
    }
  }

  * {
    font-family: IBM Plex Mono;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
`;

const Example = styled.div`
  > span {
    > span {
      position: relative;
      top: -1px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid #d1660f;
      color: #d1660f;
      font-size: 12px;
      font-weight: 700;
    }
  }
`;

const AIIntroMessage = ({ message }) => {
  let { webLink, modelName } = JSON.parse(message);
  return (
    <Container>
      <Hello>👋</Hello>
      <div>
        <p>
          I’m your personal tutor powered by generative AI, {' '}
          <a href={webLink} target="_blank" rel="noopener noreferrer">
            {modelName}
          </a>
          .
        </p>
      </div>
      <div>
        <p>Ask me questions at any time. Please know:</p>
        <ul>
          <li>I only answer questions relevant to the lessons on Stanford Data Ocean.</li>
          <li>
            If you find any answer unhelpful, please press the “😕” button and explain why so that I can learn to do
            better.
          </li>
          <li>
            The more specific you can describe your question, the better answer I can provide. Now, try typing in your
            first question using this format:
          </li>
        </ul>
        <QuestionFormat>
          <span>
            <span>1</span>Context
          </span>
          +
          <span>
            <span>2</span>Your Intent
          </span>
          +
          <span>
            <span>3</span>Answer Format
          </span>
        </QuestionFormat>
      </div>
      <div>
        <p>Here is an example:</p>
        <Example>
          <span>
            <span>1</span> I’m a beginner in Python programming.{' '}
          </span>
          <span>
            <span>2</span> I want to know what more about loop.{' '}
          </span>
          <span>
            <span>3</span> Can you make a list of the use cases?
          </span>
        </Example>
      </div>
      <div>
        <p>You can also use generative AI for visualization and exploration of your data. Try our Beta data exploration bot: 
          &nbsp;<a href={'https://llm-visualization.dataocean.stanford.edu/'} target="_blank" rel="noopener noreferrer">
            {'SDO Data Visualization'}
          </a>
          .
        </p>
      </div>        
    </Container>
  );
};

export default AIIntroMessage;