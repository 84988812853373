import React, { useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import ActionItem from './ActionItem';
import PauseConfirmationModal from './PauseConfirmationModal';

const PauseAction = ({ onPause }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Dropdown.Item>
        <div
          className="flex items-center px1 py2"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <Icon name="pause" />
          <ActionItem className="flex flex-column pl3">
            <h3 className="mb1">Pause Module</h3>
            <p>Pause this module when you are not working on the project. Your work is saved..</p>
          </ActionItem>
        </div>
      </Dropdown.Item>
      <PauseConfirmationModal
        modalOpen={modalOpen}
        onSubmit={() => {
          setModalOpen(false);
          onPause();
        }}
        onCancel={() => setModalOpen(false)}
      />
    </>
  );
};

export default PauseAction;
