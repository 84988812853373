import React from 'react';
import swal from '@sweetalert/with-react';

const putToS3 = (fileObject, presignedUrl) => {
  let filetype;
  let headersBlock;
  // If file is Notebook
  if (fileObject.type === '') {
    filetype = 'application/x-ipynb';
    headersBlock = {
      'access-control-allow-origin': '*',
      'Content-Type': filetype,
    };
  }
  // file is not notebook
  else {
    filetype = fileObject.type;
    headersBlock = {
      'access-control-allow-origin': '*',
      'Content-Type': filetype,
      'x-amz-acl': 'public-read',
    };
  }
  const requestOptions = {
    method: 'PUT',
    headers: headersBlock,
    body: fileObject,
  };

  swal(
    <div>
      <h1>Uploading file...</h1>
      <p>Please be patient, this can take a few minutes.</p>
    </div>,
  );

  fetch(presignedUrl, requestOptions)
    .then(() => {
      return console.log('uploading file, this may take a while please be patient');
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      console.log(json);
    });
};

const getPresignedUrl = (fileObject, apigateway, studyId) => {
  let filetype;
  if (fileObject.type === '') {
    filetype = 'application/x-ipynb';
  } else {
    filetype = fileObject.type;
    studyId = `${studyId}.mp4`;
  }
  fetch(`${apigateway}?filename=${studyId}&filetype=${filetype}`, {
    'method': 'get',
    'mode': 'cors',
    'access-control-allow-origin': '*',
    'contentType': 'application/json',
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      putToS3(fileObject, json);
    });
};

export default getPresignedUrl;
