import { types } from 'mobx-state-tree';

const DataProject = types
  .model('DataProject', {
    id: types.identifier,
    name: types.string,
    description: types.maybeNull(types.string),
    studyIds: types.maybeNull(types.array(types.string)),
    createdAt: types.string,
    instanceSpec: types.maybeNull(types.string),
  })
  .actions(() => ({})) // eslint-disable-line no-unused-vars
  .views(() => ({})); // eslint-disable-line no-unused-vars

export { DataProject };
