import React, { useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import CancelDataProjectModal from '../../modals/CancelDataProjectModal';
import DataProjectModal from '../../modals/DataProjectModal';
import ActionItem from './ActionItem';

const EditAction = ({ id, name, description, studies, onEdit }) => {
  const [projectName, setProjectName] = useState(name);
  const [projectDescription, setProjectDescription] = useState(description);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const updateModalStateChangeHandler = ({ openModal, showCancel }) => {
    setUpdateModalOpen(openModal);
    setCancelModalOpen(showCancel);
  };

  const cancelModalStateChangeHandler = ({ openModal, showParent }) => {
    setCancelModalOpen(openModal);
    setUpdateModalOpen(showParent);
  };

  return (
    <>
      <Dropdown.Item>
        <div
          className="flex items-center px1 py2"
          onClick={() => {
            setUpdateModalOpen(true);
          }}
        >
          <Icon name="Edit" />
          <ActionItem className="flex flex-column pl3">
            <h3 className="mb1">Edit VM</h3>
            <p>Edit the machine when you are not working on the project. Your work is saved.</p>
          </ActionItem>
        </div>
      </Dropdown.Item>
      <DataProjectModal
        isEdit
        id={id}
        studies={studies}
        projectName={projectName}
        projectDescription={projectDescription}
        modalOpen={updateModalOpen && !cancelModalOpen}
        onModalStateChange={updateModalStateChangeHandler}
        onProjectNameChange={setProjectName}
        onProjectDescriptionChange={setProjectDescription}
        onSubmit={() => onEdit(id, projectName, projectDescription)}
      />
      <CancelDataProjectModal
        modalOpen={!updateModalOpen && cancelModalOpen}
        onModalStateChange={cancelModalStateChangeHandler}
      />
    </>
  );
};

export default EditAction;
