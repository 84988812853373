import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { ActionButton, TitleAndDescription } from '../../parts';
import FirstStep from './FirstStep';
import TestAccessState from './TestAccessState';
import SecondStep from './SecondStep';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  > i {
    cursor: pointer;
  }
  > div {
    margin: 0px 80px;
  }
`;

const SelectWorkflow = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
`;

const PolicyConfiguration = ({ paths, policy, onBack, onComplete }) => {
  const [showSecondStep, setShowSecondStep] = useState(false);
  const [showSelectWorkflowButton, setShowSelectWorkflowButton] = useState(false);

  const handleStateChange = state => {
    if (state === TestAccessState.ErrorKMS) {
      setShowSecondStep(true);
    } else if (state === TestAccessState.Success) {
      setShowSelectWorkflowButton(true);
    }
  };

  return (
    <Container>
      <Icon name="angle left" size="large" onClick={onBack} />
      <div>
        <TitleAndDescription
          title="Provide read and write access"
          description="Follow the next two steps to provide us read and write access.
           Please contact support team for questions."
        />
        <FirstStep paths={paths} code={policy} onStateChange={handleStateChange} />
        {showSecondStep ? <SecondStep paths={paths} onStateChange={handleStateChange} /> : <></>}
        {showSelectWorkflowButton ? (
          <SelectWorkflow>
            <ActionButton onClick={onComplete}>Continue to select workflow</ActionButton>
          </SelectWorkflow>
        ) : (
          <></>
        )}
      </div>
    </Container>
  );
};

export default PolicyConfiguration;