import styled from 'styled-components';

const Title5 = styled.h4`
  color: #2e2d29;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  margin: 0px;
`;

export default Title5;
