import React from 'react';
import styled from 'styled-components';

var Container = styled.div`
  place-items: center;
  display: grid;
  grid-template: 1fr / 1fr;
  > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
`;

var IconContainer = styled.span`
  width: 19px;
  height: 20px;
  -webkit-mask-image: url('${window.location.origin}/static/icons/step.svg');
  mask-image: url('${window.location.origin}/static/icons/step.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background: #f9a44a;
`;

var IconContent = styled.span`
  width: 9px;
  height: 9px;
  -webkit-mask-image: url('${window.location.origin}/static/icons/${(p) => p.icon}.svg');
  mask-image: url('${window.location.origin}/static/icons/${(p) => p.icon}.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background: #fff;
`;

const Icon = ({ icon }) => (
  <Container>
    <IconContainer />
    <IconContent icon={icon} />
  </Container>
);

export default Icon;
