import React from 'react';
import _ from 'lodash';
import Study from './Study/Study';
import ProgressEnum from '../../../../models/learning/ProgressEnum';
import Survey from './Survey/Survey';

const PathModules = ({ learningId, pathId, modules, pathIcon, disabled }) => {
  const isDisabled = (module, moduleIndex) => {
    const isFirstStudy = module.index === 0;
    const isStudyNotStarted = module.progress === ProgressEnum.NotStarted;
    const areAllPreviousModulesCompleted =
      !isFirstStudy && modules.slice(0, moduleIndex).every(m => m.progress === ProgressEnum.Completed);
    return disabled || !(isFirstStudy || !isStudyNotStarted || areAllPreviousModulesCompleted);
  };

  return _.chunk(
    modules.slice().sort((a, b) => a.index - b.index),
    2,
  ).map((c, cIndex) => {
    if (c.length === 2)
      return (
        <div className="clearfix mx4 mb4">
          {c.map((m, i) => {
            const moduleIndex = cIndex * 2 + i;
            const disabled = isDisabled(m, moduleIndex) || m.noActiveSubscriptionLimit === true;
            if (m.studyId)
              return (
                <div
                  key={m.studyId}
                  className={`sm-col-6 md-col-6 lg-col-6 px3 ${cIndex % 2 !== 0 ? 'col-right' : 'sm-col'}`}
                >
                  <Study
                    learningId={learningId}
                    pathId={pathId}
                    module={{ ...m }}
                    icon={pathIcon}
                    disabled={disabled}
                  />
                </div>
              );
            if (m.surveyId)
              return (
                <div
                  key={m.studyId}
                  className={`sm-col-6 md-col-6 lg-col-6 px3 ${cIndex % 2 !== 0 ? 'col-right' : 'sm-col'}`}
                >
                  <Survey
                    learningId={learningId}
                    pathId={pathId}
                    module={{ ...m }}
                    icon={pathIcon}
                    disabled={disabled}
                  />
                </div>
              );

            return <></>;
          })}
        </div>
      );
    return (
      <div className="clearfix mx4 mb4">
        {c.map((m, i) => {
          const moduleIndex = cIndex * 2 + i;
          const disabled = isDisabled(m, moduleIndex);
          if (m.studyId)
            return (
              <div key={m.studyId} className="sm-col-6 md-col-6 lg-col-6 mx-auto px3">
                <Study learningId={learningId} pathId={pathId} module={m} icon={pathIcon} disabled={disabled} />
              </div>
            );
          if (m.surveyId)
            return (
              <div key={m.studyId} className="sm-col-6 md-col-6 lg-col-6 mx-auto px3">
                <Survey learningId={learningId} pathId={pathId} module={m} icon={pathIcon} disabled={disabled} />
              </div>
            );

          return <></>;
        })}
      </div>
    );
  });
};

export default PathModules;
