import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';
import styled from 'styled-components';
import { QuestionHeader, QuestionIndex, QuestionStatement } from '../../components';
import Scale from './Scale';
import QuestionContainer from './QuestionContainer';

const QuestionDescription = styled(ReactMarkdown)`
  font-family: 'IBM Plex Serif';
  font-weight: 400;
  font-size: 24px;
  line-height: 135%;
  color: #2e2d29;
  margin: 24px 0px 44px 0px;
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    flex-basis: ${p => p.gab}%;
    text-align: center;
  }

  > span {
    flex-basis: 7%;
  }
`;

const RangeItem = styled.span`
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #f6e9db;
  border-radius: 4px;
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  padding: 16px 32px;
  cursor: pointer;
  background-color: ${p => (p.active ? '#f9a44a' : '#f6e9db')};
  color: ${p => (p.active ? '#ffffff' : '#2e2d29')};
  &[disabled] {
    background-color: #969dab;
    color: #ffffff;
    border-color: #969dab;
    pointer-events: none;
  }
`;

const QuestionSubtitle = styled.div`
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  color: #2e2d29;
`;

const Keyword = {
  Agree: 'Agree',
  Confident: 'Confident',
  Likely: 'Likely',
};

const RatingScaleQuestion = ({ index, statement, description, keyword, scale, disabled, onClick }) => {
  const [value, setValue] = useState();

  const getTwoKeywordParts = keyword => {
    switch (keyword) {
      case Keyword.Agree:
        return [];
      case Keyword.Confident:
        return [];
      case Keyword.Likely:
        return ['Not at all likley', 'Extremely likely'];
      default:
        return [];
    }
  };

  const getFiveKeywordParts = keyword => {
    switch (keyword) {
      case Keyword.Agree:
        return ['Do not agree at all', 'Slightly agree', 'Moderately agree', 'Very agree', 'Completely agree'];
      case Keyword.Confident:
        return [
          'Not at all confident',
          'Slightly confident',
          'Moderately confident',
          'Very confident',
          'Extremely confident',
        ];
      case Keyword.Likely:
        return [];
      default:
        return [];
    }
  };

  const renderItems = (scale, keyword) => {
    if (scale === Scale.Five) {
      const start = 1;
      const end = 6;
      const parts = getFiveKeywordParts(keyword);

      return (
        <ItemsContainer gab={18}>
          {_.range(start, end).map(i => (
            <div>
              <RangeItem key={i} active={i === value} disabled={disabled} onClick={() => handleClick(i)}>
                {i}
              </RangeItem>
              <QuestionSubtitle className="mt2">
                <span>{parts[i - 1]}</span>
              </QuestionSubtitle>
            </div>
          ))}
        </ItemsContainer>
      );
    }
    if (scale === Scale.Six) {
      const start = 0;
      const end = 6;
      const parts = ['Not applicable', ...getFiveKeywordParts(keyword)];

      return (
        <ItemsContainer gab={15}>
          {_.range(start, end).map(i => (
            <div>
              <RangeItem key={i} active={i === value} disabled={disabled} onClick={() => handleClick(i)}>
                {i}
              </RangeItem>
              <QuestionSubtitle className="mt2">
                <span>{parts[i]}</span>
              </QuestionSubtitle>
            </div>
          ))}
        </ItemsContainer>
      );
    }
    if (scale === Scale.Eleven) {
      const start = 0;
      const end = 11;
      const parts = getTwoKeywordParts(keyword);

      return (
        <>
          <ItemsContainer>
            {_.range(start, end).map(i => (
              <RangeItem key={i} active={i === value} disabled={disabled} onClick={() => handleClick(i)}>
                {i}
              </RangeItem>
            ))}
          </ItemsContainer>
          <QuestionSubtitle className="flex justify-between mt2">
            <span>{parts[0]}</span>
            <span>{parts[1]}</span>
          </QuestionSubtitle>
        </>
      );
    }
  };
  const handleClick = i => {
    setValue(i);
    onClick(i);
  };

  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionIndex>{`Question ${index + 1}`}</QuestionIndex>
        <QuestionStatement>{statement}</QuestionStatement>
      </QuestionHeader>
      <div className="my3">
        <QuestionDescription children={description} />
        {renderItems(scale, keyword)}
      </div>
    </QuestionContainer>
  );
};

export default RatingScaleQuestion;
