import React, { useEffect, useState } from 'react';
import { Tab } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import Stores from '@aws-ee/base-ui/dist/models/Stores';
import { runInAction } from 'mobx';
import { isStoreReady } from '@aws-ee/base-ui/dist/models/BaseStore';
import { categories } from '@aws-ee/base-raas-ui/dist/models/studies/categories';
import styled from 'styled-components';
import DataProject from './DataProject';
import rocketImage from '../../../../images/rocket.svg';
import OrderType from '../../../../models/OrderType';
import DataActions from './DataActions';

const NoDataProjects = styled.div`
  img {
    margin-top: 150px;
  }
  h2 {
    line-height: 135%;
    color: #969dab;
    margin-top: 64px;
    margin-bottom: 0px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #969dab;
  }
`;

const MyProjects = props => {
  let stores;
  const studiesStore = props.studiesStoresMap[categories.organization.id];
  runInAction(() => {
    stores = new Stores([
      studiesStore,
      props.dataProjectsStore,
      props.dataTypesStore,
      props.phenotypesStore,
      props.currentUserScEnvironmentsStore,
      props.userStore,
    ]);
  });

  const [filter, setFilter] = useState({});
  const [sortOrder, setSortOrder] = useState(OrderType.Ascending);

  useEffect(() => {
    swallowError(stores.load());
    props.currentUserScEnvironmentsStore.startHeartbeat();
    return () => props.currentUserScEnvironmentsStore.stopHeartbeat();
  });

  useEffect(() => {
    props.dataProjectsStore.load();
    props.currentUserScEnvironmentsStore.load();
  }, [studiesStore.dataProjects, props.currentUserScEnvironmentsStore.environments]);

  const filterAndSortProjects = projects => {
    projects = projects.map(p => {
      p.studies = p.studyIds.map(sId => studiesStore.list.find(s => s.id === sId));
      return p;
    });
    if (!_.isEmpty(filter.selectedDataTypes))
      projects = projects.filter(p =>
        p.studies.some(s => !_.isEmpty(s.dataTypes) && _.some(s.dataTypes, x => filter.selectedDataTypes.includes(x))),
      );

    if (!_.isEmpty(filter.selectedPhenotypes))
      projects = projects.filter(p =>
        p.studies.some(
          s => !_.isEmpty(s.phenotypes) && _.some(s.phenotypes, x => filter.selectedPhenotypes.includes(x)),
        ),
      );

    return projects.sort((a, b) => {
      const order = sortOrder === OrderType.Ascending ? 1 : -1;
      return a.createdAt > b.createdAt ? order : -order;
    });
  };

  const renderNoDataProjects = () => {
    return (
      <NoDataProjects className="flex flex-column items-center">
        <img src={rocketImage} alt="rocket" className="col col-4" />
        <h2>You don’t have a project yet.</h2>
        <h3 className="mt2">Selects public datasets on the platform to create your own project.</h3>
      </NoDataProjects>
    );
  };

  const renderDataProjects = dataProjects => {
    const map = new Map();
    const userId = props.userStore.user.uid;

    props.currentUserScEnvironmentsStore.list
      .filter(
        e =>
          !_.isNil(e.dataProjectId) &&
          e.state.display !== 'TERMINATED' &&
          e.state.display !== 'TERMINATION FAILED' &&
          e.state.display !== 'FAILED' &&
          e.createdBy === userId,
      )
      .forEach(e => {
        map.set(e.dataProjectId, e);
      });

    return dataProjects.map(p => {
      p.workspace = map.get(p.id);
      if (_.isNil(p.workspace)) p.workspace = {};
      return <DataProject key={p.id} {...p} />;
    });
  };

  let content = null;
  let dataProjects = [];
  if (stores.error) {
    content = <ErrorBox error={props.dataProjectsStore.error} className="mt3 mr0 ml0" />;
  } else if (stores.loading) {
    content = <BasicProgressPlaceholder segmentCount={5} className="mt3 mr0 ml0" />;
  } else if (
    isStoreReady(studiesStore) &&
    isStoreReady(props.dataProjectsStore) &&
    isStoreReady(props.dataTypesStore) &&
    isStoreReady(props.phenotypesStore) &&
    isStoreReady(props.currentUserScEnvironmentsStore) &&
    isStoreReady(props.userStore)
  ) {
    dataProjects = filterAndSortProjects(props.dataProjectsStore.dataProjects);
    content =
      _.isNil(dataProjects) || dataProjects.length === 0 ? renderNoDataProjects() : renderDataProjects(dataProjects);
  }

  return (
    <Tab.Pane loading={stores.loading} attached={false} className="flex flex-column">
      <DataActions
        resultsCount={dataProjects.length}
        onFilterChange={e => setFilter(e)}
        onSortChange={e => setSortOrder(e)}
      />
      {content}
    </Tab.Pane>
  );
};

export default inject(
  'dataProjectsStore',
  'studiesStoresMap',
  'dataTypesStore',
  'phenotypesStore',
  'currentUserScEnvironmentsStore',
  'userStore',
)(observer(MyProjects));
