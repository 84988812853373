import { types } from 'mobx-state-tree';
import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import { createDataProjects, deleteDataProject, getDataProjects, updateDataProject } from '../../helpers/api';
import { DataProject } from './DataProject';

const DataProjectsStore = BaseStore.named('DataProjectsStore')
  .props({
    dataProjects: types.array(DataProject),
  })
  .actions(self => {
    return {
      async doLoad() {
        const dataProjects = await getDataProjects();
        self.runInAction(() => {
          self.dataProjects = dataProjects.map(d => {
            return {
              ...d,
              instanceSpec: JSON.stringify(d.instanceSpec),
            };
          });
        });
      },

      async create(project) {
        return createDataProjects(project);
      },

      async update(id, project) {
        const result = await updateDataProject(id, project);
        if (result)
          self.runInAction(() => {
            self.dataProjects.filter(d => d.id === id)[0] = result;
          });
      },

      async delete(id) {
        await deleteDataProject(id);
        self.runInAction(() => {
          self.dataProjects = self.dataProjects.filter(d => d.id !== id);
        });
      },
    };
  })
  .views(() => ({}));

function registerContextItems(appContext) {
  appContext.dataProjectsStore = DataProjectsStore.create({}, appContext);
}

export { DataProjectsStore, registerContextItems };
