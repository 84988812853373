import React from 'react';
import { Container, Divider, Tab, Header, Message, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import UserManagementTab from './UserManagementTab';
import StudyManagementTab from './StudyManagementTab';

const panes = [
  {
    menuItem: 'User Management',
    render: () => <UserManagementTab />,
  },
  {
    menuItem: 'Study Management',
    render: () => <StudyManagementTab />,
  },
];

const AdminPage = ({ userStore }) => {
  return userStore.user.isBackOffice ? (
    <Container className="mt3 animated fadeIn">
      <Header as="h2">Welcome to the SDO back office page</Header>
      <p>Switch between the below tabs to either update users or studies</p>
      <Divider hidden />
      <Tab menu={{ color: 'orange', inverted: true, attached: false, tabular: true }} panes={panes} />
      <Divider />
    </Container>
  ) : (
    <Container className="mt3 animated fadeIn">
      <Message negative>
        <Message.Header>
          <Icon name="thumbs down" />
          Sorry! You dont have permission to view this page.
        </Message.Header>
        <Divider />
        <p>Please contact an administrator.</p>
        <a href="/studies">Take me back to studies</a>
      </Message>
    </Container>
  );
};

export default inject(
  'userStore',
  'filesSelection',
  'scEnvironmentsStore',
  'studiesStoresMap',
)(withRouter(observer(AdminPage)));
