import styled from 'styled-components';

const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
`;

export default Title;
