import { types } from 'mobx-state-tree';

const Phenotype = types
  .model('Phenotype', {
    id: types.identifier,
    name: types.string,
    category: types.string,
  })
  .actions(() => ({})) // eslint-disable-line no-unused-vars
  .views(() => ({})); // eslint-disable-line no-unused-vars

export { Phenotype };
