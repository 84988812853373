import { types } from 'mobx-state-tree';
import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import { NewWorkflowRun } from './NewWorkflowRun';
import {
  getWorkflows,
  createWorkflowPolicy,
  getCurrentWorkflowConfig,
  testWorkflowS3Access,
  createWorkflowRun,
  getWorkflowRuns,
} from '../../helpers/api';

const NewWorkflowStore = BaseStore.named('NewWorkflowStore')
  .props({
    runs: types.array(NewWorkflowRun),
  })
  .actions(self => {
    return {
      async getWorkflows() {
        const workflows = await getWorkflows();
        self.runInAction(() => {
          self.workflows = workflows;
        });
        return workflows;
      },
      async createWorkflowPolicy(policyConfig) {
        return await createWorkflowPolicy(policyConfig);
      },
      async getCurrentWorkflowconfig() {
        const currentWorkflowConfig = await getCurrentWorkflowConfig();
        self.runInAction(() => {
          self.currentWorkflowConfig = currentWorkflowConfig;
        });
        return currentWorkflowConfig;
      },
      async testWorkflowS3Access(accessConfig) {
        return await testWorkflowS3Access(accessConfig);
      },
      async createWorkflowRun(name, workflowRun) {
        return await createWorkflowRun(name, workflowRun);
      },
      async getWorkflowRuns() {
        const workflowRuns = await getWorkflowRuns();
        self.runInAction(() => {
          self.runs = workflowRuns;
        });
        return workflowRuns;
      },
    };
  })
  .views(() => ({}));

function registerContextItems(appContext) {
  appContext.newWorkflowStore = NewWorkflowStore.create({}, appContext);
}

export { NewWorkflowStore, registerContextItems };
