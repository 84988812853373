import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  height: 40px;
  border-radius: 4px;
  font-family: 'IBM Plex Mono';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  background: #2e2d29;
  border: none;
  color: #fafafa;
`;

const TryAgainButton = () => (
  <Button type="button" onClick={() => window.location.reload()}>
    Try again
  </Button>
);

export default TryAgainButton;
