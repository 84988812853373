import React from 'react';
import styled from 'styled-components';

const TimelineContent = styled.ul`
  padding: 0px;
`;

const TimelineItem = styled.li`
  display: flex;
  justify-content: stretch;
  gap: 115px;
`;

const TimelineSeparator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  @media (max-width: 992px) {
    display: none;
  }
`;

const Circle = styled.span`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #e98300;
  border: 16px solid #fcedd9;
`;

const VerticalLine = styled.span`
  width: 3px;
  height: ${(p) => (p.height ? p.height : '100%')};
  background: ${(p) => (p.background ? p.background : 'rgba(245, 150, 26, 0.3)')};
`;

const Timeline = ({ items }) => (
  <TimelineContent>
    {items.map((item, i) => (
      <TimelineItem key={item.key}>
        <TimelineSeparator>
          {i == 0 ? (
            <>
              <VerticalLine height="28px" background="transparent" />
            </>
          ) : (
            <VerticalLine height="28px" />
          )}
          <Circle />
          <VerticalLine />
        </TimelineSeparator>
        {item.render()}
      </TimelineItem>
    ))}
  </TimelineContent>
);

export default Timeline;
