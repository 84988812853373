import { default as styled } from 'styled-components';

const StepInput = styled.input`
  width: 100%;
  background: #f5f5f5;
  border: 1px solid #d7dbe3;
  border-radius: 4px;
  padding: 12px 16px;
  color: #2e2d29;
  font-family: IBM Plex Mono;
  font-size: 16px;
  font-weight: 400;
  ::placeholder {
    color: #969dab;
  }
`;

export default StepInput;
