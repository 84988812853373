import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Footer from '../parts/Footer';
import Annoncement from './Annoncement';
import Benefits from '../parts/Benefits';
import Team from '../parts/Team';
import Practice from '../parts/Practice';
import Testimonials from './Testimonials';
import Notice from '../parts/Notice';
import JoinStudents from './JoinStudents';
import SupportEmail from './parts/SupportEmail';
import Courses from './courses';

const Container = styled.div`
  overflow: hidden;
  font-family: 'IBM Plex Sans';
  color: #2e2d29;

  > div {
    background-color: white;
  }

  @media (max-width: 992px) {
    font-size: 10px;
  }
`;

const CertificateAnnouncement = () => {
  const [data, setData] = useState();

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(`${window.location.origin}/static/certificate-announcement/data.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
      const json = await response.json();
      setData(json);
    };

    getData();
  }, []);

  return data ? (
    <Container>
      <Annoncement title={data.title} description={data.description} registerUrl={data.registerUrl} />
      <Benefits title="Why is This Program for You?" benefits={data.benefits} />
      <Team title="Learn from Scientists at Stanford University" team={data.team} columns={2} />
      <Practice practiceNotes={data.practice} />
      <Testimonials testimonials={data.testimonials} />
      <Notice
        title={data.notice1.title}
        description={
          <>
            {data.notice1.description}
            <br /> <br />
            <SupportEmail email={data.supportEmail} />
          </>
        }
        actionText={data.notice1.actionText}
        url={data.registerUrl}
      />
      <JoinStudents />
      <Courses courses={data.courses} />
      <Notice
        title={data.notice2.title}
        description={
          <>
            You will receive a study planner if you thrive in structure.
            <br /> <br />
            {data.notice2.description}
            <br /> <br />
            <SupportEmail email={data.supportEmail} />
          </>
        }
        actionText={data.notice2.actionText}
        url={data.registerUrl}
      />
      <Footer />
    </Container>
  ) : (
    'Loading...'
  );
};

export default CertificateAnnouncement;
