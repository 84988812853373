import React from 'react';
import ResultTitle from './ResultTitle';
import ResultDescription from './ResultDescription';
import TryAgainButton from './TryAgainButton';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 150px 64px 150px;
  padding-bottom: 64px;
  border-bottom: 1px solid #969dab;
  > button {
    width: 150px;
  }
`;

const ResultSection = ({ submissionResult }) =>
  submissionResult.passed ? (
    <Container>
      <div>
        <ResultTitle>
          <span role="img" aria-label="party">
            🎉
          </span>{' '}
          You’re doing great! You have {submissionResult.correctAnswersCount} out of {submissionResult.questionsCount}{' '}
          correct.
        </ResultTitle>
        <ResultDescription>
          You can advance to the next module now. Feel free to give this quiz another try. The result will not affect
          your progress.
        </ResultDescription>
      </div>
    </Container>
  ) : (
    <Container>
      <div>
        <ResultTitle>
          <span role="img" aria-label="flexed biceps">
            💪
          </span>{' '}
          You have {submissionResult.correctAnswersCount} out of {submissionResult.questionsCount} correct.
        </ResultTitle>
        <ResultDescription>
          Study the concepts related to the questions you missed. You can take this quiz as many times as you need to.
        </ResultDescription>
      </div>
      <TryAgainButton />
    </Container>
  );

export default ResultSection;
