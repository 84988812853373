import styled from 'styled-components';

const ResultDescription = styled.p`
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
`;

export default ResultDescription;
