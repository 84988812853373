import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import Section from './Section';
import { Grid } from 'semantic-ui-react';
import Title from './Title';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8.571em 0px;
  align-items: center;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Benefits = ({ title, benefits }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Grid centered container>
        {_.chunk(benefits, 2).map((c, i) => (
          <Grid.Row key={`r-${i}`} centered columns={2}>
            {c.map((b) => (
              <Grid.Column key={b.title} computer={8} tablet={8} mobile={16}>
                <SectionContainer>
                  <Section title={b.title} icon={b.icon} description={b.description} useRelativePath={true} />
                </SectionContainer>
              </Grid.Column>
            ))}
          </Grid.Row>
        ))}
      </Grid>
    </Container>
  );
};

export default Benefits;
