/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import _ from 'lodash';
import React from 'react';
import { decorate, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Menu, Icon, Image } from 'semantic-ui-react';

import { createLink } from '../helpers/routing';
import { displayError } from '../helpers/notification';
import ChatBotPopup from './chatBot/ChatBotPopup';

// expected props
// - userStore (via injection)
class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasScrolled: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 0) {
      this.setState({ hasScrolled: true });
    } else {
      this.setState({ hasScrolled: false });
    }
  };

  goto = pathname => () => {
    const location = this.props.location;
    const link = createLink({
      location,
      pathname,
    });

    this.props.history.push(link);
  };

  handleLogout = async event => {
    event.preventDefault();
    event.stopPropagation();

    try {
      await this.props.authentication.logout();
    } catch (error) {
      displayError(error);
    }
  };

  getMenuItems() {
    return this.props.menuItems || [];
  }

  render() {
    const currentUser = this.props.userStore.user;
    const displayName = currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : 'Not Logged In';
    const pathname = _.get(this.props.location, 'pathname', '');
    const is = value => _.startsWith(pathname, value);

    const itemsArr = this.getMenuItems();

    return [
      <Menu vertical inverted fixed="left" icon="labeled" key="ml1">
        {_.map(_.sortBy(itemsArr, ['index']), (item, index) => {
          const show = (_.isFunction(item.shouldShow) && item.shouldShow()) || item.shouldShow;
          return (
            show &&
            (item.body ? (
              item.body
            ) : (
              <div className="nav-items">
                <Menu.Item key={index} active={is(item.url)} onClick={is(item.url) ? undefined : this.goto(item.url)}>
                  {/* if (item.name === 'Support') {
                      <Icon name={item.icon} size="mini" />
                      <span className="fs-9">{item.title}</span>
                  } */}
                  <Icon name={item.icon} size="mini" />
                  <span>{item.title}</span>
                </Menu.Item>
              </div>
            ))
          );
        })}
        <div className="nav-items">
          <a href="mailto:sdo-support@stanford.edu" className="item">
            <i aria-hidden="true" className="contact-icon menu-icon mini icon" />
            <span>Contact</span>
          </a>
        </div>
      </Menu>,

      <Menu inverted fixed="top" key="ml2" style={{ backgroundColor: '#f5f5f5' }}>
        <Menu.Item
          style={{
            height: '115px',
            backgroundColor: '#fff',
            width: '255px',
          }}
          className="px1, py0"
        >
          <Image
            size="mini"
            src={this.props.assets.images.logoImage}
            style={{ width: '100%' }}
            alt="Stanford Data Ocean Logo"
            href={window.location.origin}
          />
          {/* <span style={{ color: '#000', fontWeight: 'bold' }}>{branding.main.title}</span> */}
        </Menu.Item>
        <Menu.Menu className={`items-center w-100 ${this.state.hasScrolled ? 'with-shadow' : ''}`}>
          <Menu.Menu position='right'>
            {this.props.userStore.user.isBackOffice && (
              <>
                <Menu.Item style={{ color: '#000' }} onClick={this.goto('admin-page')}>
                  Back Office Page
                </Menu.Item>
                <div className="headerDivider" />
              </>
            )}
            <Menu.Item style={{ color: '#000' }}>
              <Icon name="user outline" />
              {displayName}
            </Menu.Item>
            <div className="headerDivider" />
            <Menu.Item style={{ color: '#000' }} onClick={this.handleLogout}>
              <Icon name="logout" /> Logout
            </Menu.Item>
          </Menu.Menu>
        </Menu.Menu>
      </Menu>,
      <div className="fit animated fadeIn" key="ml3">
        {this.props.children}
        <ChatBotPopup />
      </div>,
    ];
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(MainLayout, {
  handleLogout: action,
});

export default inject('authentication', 'userStore', 'assets')(withRouter(observer(MainLayout)));