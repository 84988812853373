import React from 'react';
import styled from 'styled-components';
import quoteLeftImg from '../../../../images/static/certificate-announcement/quote-left.svg';
import { Grid } from 'semantic-ui-react';
import Section from '../parts/Section';
import Title from '../parts/Title';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8.571em 4.285em 0px 4.285em;
  align-items: center;
`;

const CustomSection = styled(Section)`
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 30px 60px 0px rgba(146, 157, 165, 0.2);
  padding: 2.285em 4.571em;
  max-width: 560px;
  float: ${p => (Math.floor(p.i / 2) % 2 === 0 ? 'left' : 'right')};
  margin-top: ${p => (p.i % 2 === 1 ? '40px' : '0px')};
  border: 1px solid rgba(233, 131, 0, 0.2);
`;

const Testimonials = ({ testimonials }) => {
  return (
    <Container>
      <Title>What Did Students Say?</Title>
      <Grid>
        {testimonials.map((testimonial, i) => (
          <Grid.Column key={testimonial.title} computer={8} tablet={8} mobile={16}>
            <CustomSection i={i} icon={quoteLeftImg} title={testimonial.title} description={testimonial.description} />
          </Grid.Column>
        ))}
      </Grid>
    </Container>
  );
};

export default Testimonials;
