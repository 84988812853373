import _ from 'lodash';
import { values } from 'mobx';
import { types } from 'mobx-state-tree';
import { consolidateToMap } from '@aws-ee/base-ui/dist/helpers/utils';
import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import { ScEnvironment } from './ScEnvironment';
import { getCurrentUserScEnvironments } from '../../../dist/helpers/api';

// ==================================================================
// CurrentUserScEnvironmentsStore
// ==================================================================
const CurrentUserScEnvironmentsStore = BaseStore.named('CurrentUserScEnvironmentsStore')
  .props({
    environments: types.optional(types.map(ScEnvironment), {}),
    tickPeriod: 30 * 1000, // 30 seconds
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        const environments = await getCurrentUserScEnvironments();
        self.runInAction(() => {
          consolidateToMap(self.environments, environments, (existing, newItem) => {
            existing.setScEnvironment(newItem);
          });
        });
      },

      cleanup: () => {
        self.environments.clear();
        superCleanup();
      },
    };
  })

  .views(self => ({
    get list() {
      return _.orderBy(values(self.environments), ['createdAt', 'name'], ['desc', 'asc']);
    },
  }));

function registerContextItems(appContext) {
  appContext.currentUserScEnvironmentsStore = CurrentUserScEnvironmentsStore.create({}, appContext);
}

export { CurrentUserScEnvironmentsStore, registerContextItems };
