import React, { useState } from 'react';
import AccordionTitle from '../parts/AccordionTitle';
import { AccordionContent, ActionButton, StepContainer, StepInput, AccordionItem } from '../parts';
import PolicyConfiguration from './PolicyConfiguration';
import { inject } from 'mobx-react';
import { useEffect } from 'react';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';
import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';

const StepTitle = styled.p`
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
`;

const CustomCheckbox = styled(Checkbox)`
  label {
    font-family: IBM Plex Sans;
    font-size: 16px !important;

    ::before {
      width: 20px !important;
      height: 20px !important;
    }

    ::after {
      top: 2px !important;
      width: 20px !important;
      height: 20px !important;
    }
  }

  input:checked ~ label:before {
    background: #2e2d29 !important;
  }
  input:checked ~ label::after {
    color: #fff !important;
  }
`;

const SequenceDataStep = ({ active, onClick, onShowNextPage, onStepCompleted, newWorkflowStore }) => {
  const [inputPath, setInputPath] = useState();
  const [outputPath, setOutputPath] = useState();
  const [loading, setLoading] = useState();
  const [inputSaved, setInputSaved] = useState();
  const [outputSaved, setOutputSaved] = useState();
  const [startLoading, setStartLoading] = useState();
  const [stepCompleted, setStepCompleted] = useState();

  useEffect(() => {
    const getCurrentWorkflowconfig = async () => {
      await newWorkflowStore.getCurrentWorkflowconfig();
    };

    if (active) {
      setLoading(true);
      getCurrentWorkflowconfig()
        .then(() => {
          setLoading(false);
          setInputPath(newWorkflowStore.currentWorkflowConfig?.inputBucketPath);
          setOutputPath(newWorkflowStore.currentWorkflowConfig?.outputBucketPath);
        })
        .catch(error => {
          setLoading(false);
          displayError(error);
        });
    }
  }, [active]);

  const handleStartNowClick = async isInput => {
    let disableLoading = () => null;
    let paths = {};
    let inputSavedTemp = inputSaved;
    let outputSavedTemp = outputSaved;
    if (inputPath.length && outputPath.length) {
      setStartLoading(true);
      disableLoading = () => setStartLoading(false);
      paths.inputPath = inputPath;
      paths.outputPath = outputPath;
      inputSavedTemp = outputSavedTemp = true;
      try {
        const result = await newWorkflowStore.createWorkflowPolicy(paths);
        onShowNextPage(() => () => {
          return (
            <PolicyConfiguration
              paths={paths}
              policy={result.Policies}
              onBack={() => onShowNextPage(undefined)}
              onComplete={() => {
                if (inputSavedTemp && outputSavedTemp) {
                  onShowNextPage(undefined);
                  setStepCompleted(true);
                  onStepCompleted({
                    inputBucketPath: inputPath,
                    outputBucketPath: outputPath,
                  });
                } else {
                  onShowNextPage(undefined);
                  setInputSaved(inputSavedTemp);
                  setOutputSaved(outputSavedTemp);
                }
              }}
            />
          );
        });
      } catch (error) {
        displayError(error);
      }
    } else {
      displayError(`Please enter both Input and Output files location`);
    }
    disableLoading();
  };

  return (
    <AccordionItem>
      <AccordionTitle
        index={1}
        title="Select sequencing data"
        description={
          <>
            Follow the next two steps to provide us read and write access. Please contact{' '}
            <a href="mailto:sdo-support@stanford.edu" target="_blank" rel="noreferrer">
              support team
            </a>{' '}
            for questions.
          </>
        }
        active={active}
        completed={stepCompleted}
        onClick={onClick}
      />
      <AccordionContent active={active}>
        {loading ? (
          <BasicProgressPlaceholder segmentCount={3} />
        ) : (
          <>
            <StepContainer>
              <StepTitle>
                1. <b>Input files location:</b> Provide a location where we can access the data that needs to be
                processed.
              </StepTitle>
              <StepInput
                placeholder="Example: s3//bucket/prefix/objects "
                defaultValue={inputPath}
                onChange={e => {
                  setInputPath(e.target.value);
                }}
              />
              <StepTitle>
                2. <b>Output files location:</b> Provide a location where you want to store the processed data.
              </StepTitle>
              <StepInput
                placeholder="Example: s3//bucket/prefix/objects "
                defaultValue={outputPath}
                onChange={e => setOutputPath(e.target.value)}
              />
              <ActionButton
                disabled={startLoading}
                loading={startLoading}
                loadingText="Starting.."
                successText={inputSaved && outputSaved ? 'Saved' : undefined}
                onClick={() => handleStartNowClick()}
              >
                Give Access
              </ActionButton>
            </StepContainer>
          </>
        )}
      </AccordionContent>
    </AccordionItem>
  );
};

export default inject('newWorkflowStore')(SequenceDataStep);