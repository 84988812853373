import React from 'react';
import styled from 'styled-components';
import ActionButton from './ActionButton';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 590px;

  > {
    font-family: IBM Plex Sans;
    color: #2e2d29;
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.3px;
    margin: 0px 0px 16px 0px;
  }
  h4 {
    font-size: 16px;
    font-weight: 450;
    line-height: 20px;
    letter-spacing: 0.2px;
    margin: 0px !important;
  }
`;

const Join = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    > span {
      margin-left: 16px;
      font-size: 20px;
      font-weight: 700;
    }
  }

  > a {
    margin-top: 16px;
    color: #e98300;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-weight: 450;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
  }
`;

const Title = ({ description, joinUrl, price, applicationUrl }) => {
  const handleJoinClick = () => window.open(joinUrl, '_blank').focus();

  return (
    <Container>
      <Content>
        <h2>Unlock more learning content in the certificate track.</h2>
        <h4>{description}</h4>
      </Content>
      <Join>
        <ActionButton onClick={handleJoinClick}>
          Join now at<span>${price}</span>
        </ActionButton>
        {applicationUrl ? (
          <a href={applicationUrl} target="_blank" rel="noreferrer">
            Apply for the scholarship.
          </a>
        ) : (
          <></>
        )}
      </Join>
    </Container>
  );
};

export default Title;
