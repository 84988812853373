import React from 'react';
import { Image } from 'semantic-ui-react';
import styled from 'styled-components';
import joinStudentsImg from '../../../../images/static/certificate-announcement/join-students.png';
import Title from '../parts/Title';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5.714em 0px;
`;

const CustomImage = styled(Image)`
  margin-top: 4.642em;
  width: 1245px;
  height: 100%;
  padding: 0px 10px;
`;

const JoinStudents = () => {
  return (
    <Container>
      <Title>Join Fellow Students at</Title>
      <CustomImage src={joinStudentsImg} alt="join-students" />
    </Container>
  );
};

export default JoinStudents;
