import React from 'react';
import { Accordion, Header } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';
import CustomCheckbox from '../actions/CustomCheckbox';
import AccordionItem from './AccordionItem';
import AttachmentImage from '../../../../images/attachment.svg';

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #d7dbe3;
  border-radius: 8px;
`;

const Content = styled.div`
  width: 100%;
`;

const Description = styled.div`
  h2 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #2e2d29;
  }
  p {
    font-size: 16px;
    line-height: 135%;
    color: #2e2d29;
  }
`;

const Title = styled(Header)`
  font-size: 20px !important;
  letter-spacing: 0.4px;
`;

const Subtitle = styled.div`
  a {
    background: #f8f8f8;
    border: 1px solid #d7dbe3;
    border-radius: 4px;
    padding: 8px 19px;
    font-weight: 450;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #2e2d29;
    display: flex;

    &.disabled {
      pointer-events: none;
    }
    &::before {
      display: inline-block;
      content: '';
      width: 20px;
      height: 20px;
      background-color: #969dab;
      margin-right: 16px;
      -webkit-mask-image: url(${AttachmentImage});
      mask-image: url(${AttachmentImage});
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #969dab;
  }
`;

const DataStudy = ({ id, name, description, publication, dataTypes, phenotypes, createdAt, onClick }) => (
  <Container className="flex mt3 p3">
    <CustomCheckbox key={id} type="checkbox" value={id} onClick={onClick} />
    <Content className="flex justify-between ml3">
      <div className="col col-6">
        <Title>{name}</Title>
        <Subtitle className="flex items-center mt3">
          <a
            href={_.isNil(publication) ? '' : publication.url}
            target="_blank"
            rel="noreferrer"
            className={_.isNil(publication) ? 'disabled' : ''}
          >
            {_.isNil(publication) ? 'Not Available' : publication.source}
          </a>
          <span className="ml3">{moment(createdAt).format('MMMM, yyyy')}</span>
        </Subtitle>
        <Description className="mt3">
          <h2>Overview</h2>
          <p>{description}</p>
        </Description>
      </div>
      <div className="col col-4">
        <Accordion>
          <AccordionItem title="Data Types" active>
            {!_.isEmpty(dataTypes) ? (
              dataTypes.map((t, i) => (
                <span key={t.id} className={`tag datatype-${(i % 8) + 1} mb1 mr1 py1 px2`}>
                  {t.name}
                </span>
              ))
            ) : (
              <div>Not Available</div>
            )}
          </AccordionItem>
          <AccordionItem title="Phenotypes" active>
            {!_.isEmpty(phenotypes) ? (
              phenotypes.map(t => (
                <span key={t.id} className="tag phenotype mb1 mr1 py1 px2">
                  {t.name}
                </span>
              ))
            ) : (
              <div>Not Available</div>
            )}
          </AccordionItem>
        </Accordion>
      </div>
    </Content>
  </Container>
);

export default DataStudy;
