import React from 'react';
import { Modal } from 'semantic-ui-react';
import _ from 'lodash';
import styled from 'styled-components';
import ResultTitle from './ResultTitle';
import ResultDescription from './ResultDescription';
import TryAgainButton from './TryAgainButton';

const Subjects = styled.ul`
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
`;

const Actions = styled.div`
  width: calc(100% + 30px);
`;

const ResultModal = ({ isCertificatePath, open, submissionResult, userLearningId, onClose }) => {
  const renderResultModelContent = () => {
    const getFailedSubjects = () =>
      submissionResult.failedSubjects?.length > 0 ? (
        <>
          <br />
          <ResultDescription>
            For the questions you didn't answer correctly, review these concepts could help you get them right next
            time:
          </ResultDescription>
          <Subjects>
            {submissionResult.failedSubjects.map(s => (
              <li>{s}</li>
            ))}
          </Subjects>
        </>
      ) : (
        <></>
      );

    const getSuccessModalContent = () => (
      <>
        <ResultTitle>
          <span role="img" aria-label="party">
            🎉
          </span>{' '}
          Well done! You have {submissionResult.correctAnswersCount} out of {submissionResult.questionsCount} correct.
        </ResultTitle>
        <div className="my3">
          {isCertificatePath ? (
            <ResultDescription>
              Congratulations! You have passed the certificate exam.
            </ResultDescription>
          ) : (
            <>
              <ResultDescription>You can now access the next learning module on your study roadmap!</ResultDescription>
              {getFailedSubjects()}
            </>
          )}
        </div>
        <Actions className="custom-actions">
          <div className="col col-12 px2">
            <button
              type="button"
              className="primary-black"
              onClick={() => window.open(`/learn/${userLearningId}`, '_self').focus()}
            >
              {isCertificatePath ? 'Got it' : 'Awesome'}
            </button>
          </div>
        </Actions>
      </>
    );

    const getFailModalContent = () => (
      <>
        <ResultTitle>
          <span role="img" aria-label="flexed biceps">
            💪
          </span>{' '}
          You have {submissionResult.correctAnswersCount} out of {submissionResult.questionsCount} correct. Try again.
        </ResultTitle>
        <div className="my3">
          {isCertificatePath ? (
            <ResultDescription>
              You did not pass the exam this time. Make sure to spend time reviewing content and practice exercises.
            </ResultDescription>
          ) : (
            <>
              <ResultDescription>
                The secret to any success is to keep trying. We believe you will have an exellent outcome if you can.
              </ResultDescription>
              {getFailedSubjects()}
            </>
          )}
        </div>
        <Actions className="custom-actions">
          {isCertificatePath ? (
            <button
              type="button"
              className="primary-black"
              onClick={() => window.open(`/learn/${userLearningId}`, '_self').focus()}
            >
              Got it
            </button>
          ) : (
            <>
              <div className="px2">
                <TryAgainButton />
              </div>
            </>
          )}
        </Actions>
      </>
    );

    if (!_.isNil(submissionResult)) {
      return submissionResult.passed ? getSuccessModalContent() : getFailModalContent();
    }

    return null;
  };

  return (
    <Modal size="small" centered open={open} onClose={onClose}>
      <div className="flex flex-column items-center p3">
        <i className="close" onClick={onClose} />
        {renderResultModelContent()}
      </div>
    </Modal>
  );
};

export default ResultModal;
