import { inject } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8.571em 0px 6.428em 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  color: #53565a;

  a {
    color: #53565a;
    text-decoration: underline;
    margin: 0px 4px;
    @media (max-width: 992px) {
      margin: 4px;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    text-align: center;
  }
`;

const CustomLogo = styled(Logo)`
  position: absolute !important;
  left: 48px;
  @media (max-width: 992px) {
    position: relative !important;
    left: unset;
  }
`;

const Footer = () => {
  return (
    <Container>
      <CustomLogo /> © 2023{' '}
      <a href="https://deepdata.stanford.edu/" target="_blank">
        Stanford Deep Data Research Computing Center.
      </a>{' '}
      All rights reserved.
    </Container>
  );
};

export default Footer;
