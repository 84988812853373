import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

const CustomCheckbox = styled(Checkbox)`
  label {
    line-height: 24px;
    padding: 0px 0px 0px 56px !important;
  }
  label:before {
    border: 2px solid #d7dbe3 !important;
    border-radius: 4px !important;
    width: 24px !important;
    height: 24px !important;
  }
  &.checked {
    label:before {
      border: 2px solid #1b1b1b !important;
      background: #1b1b1b !important;
    }
    label:after {
      color: #fafafa !important;
      left: 3px !important;
    }
  }
`;

export default CustomCheckbox;
