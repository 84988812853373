import { applySnapshot, types } from 'mobx-state-tree';

const Application = types.model('Application', {
  createdAt: types.string,
  updatedAt: types.string,
});

const Bookmark = types.model('Bookmark', {
  createdAt: types.string,
  updatedAt: types.string,
  value: types.boolean,
});

const Job = types
  .model('Job', {
    id: types.identifier,
    title: types.string,
    description: types.maybeNull(types.string),
    location: types.string,
    companyName: types.string,
    salary: types.string,
    position: types.string,
    application: types.maybeNull(Application),
    bookmark: types.maybeNull(Bookmark),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
  })
  .actions(self => ({
    set(raw) {
      applySnapshot(self, raw);
    },
  })) // eslint-disable-line no-unused-vars
  .views(() => ({})); // eslint-disable-line no-unused-vars

export { Job };
