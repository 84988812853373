import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Tab } from 'semantic-ui-react';
import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import { isStoreLoading, isStoreReady } from '@aws-ee/base-ui/dist/models/BaseStore';
import CustomCheckbox from './CustomCheckbox';

const Phenotypes = props => {
  const [checked, setChecked] = useState([]);
  useEffect(() => {
    if (!isStoreReady(props.phenotypesStore)) swallowError(props.phenotypesStore.load());
  });

  const checkboxClickHandler = (_, data) => {
    let tempChecked = [];
    if (data.checked) tempChecked = [...checked, data.value];
    else {
      const index = checked.indexOf(data.value);
      if (index !== -1) {
        checked.splice(index, 1);
        tempChecked = checked;
      }
    }
    setChecked(tempChecked);
    props.onChange(tempChecked);
  };

  return (
    <Tab.Pane attached={false} loading={isStoreLoading(props.phenotypesStore)} className="flex flex-column">
      {props.phenotypesStore.phenotypes.map((d, i) => (
        <CustomCheckbox
          key={d.id}
          type="checkbox"
          value={d.id}
          label={d.name}
          checked={props.default.indexOf(d.id) !== -1}
          className={i === props.phenotypesStore.phenotypes.legnth - 1 ? '' : 'mb2'}
          onClick={checkboxClickHandler}
        />
      ))}
    </Tab.Pane>
  );
};

export default inject('phenotypesStore')(observer(Phenotypes));
