import styled from 'styled-components';

const ResultTitle = styled.h1`
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #2e2d29;
`;

export default ResultTitle;
