import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid ${p => (p.active ? ' #279989' : '#D7DBE3')};
  border-radius: 8px;
  margin-right: 16px;
  padding: 32px 30px;
  color: #2e2d29;
  cursor: pointer;

  hr {
    border: 1px solid #d7dbe3;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 0px;
  }
  .inverted.circular.icon {
    background-color: #279989 !important;
  }
`;

const Description = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin-bottom: 16px;
`;

const Statistics = styled.div`
  display: flex;
`;

const StatisticsItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 48px;
`;

const StatisticsItemValue = styled.span`
  font-weight: 700;
  font-size: 16px;
`;
const StatisticsItemName = styled.span`
  font-weight: 450;
  font-size: 14px;
  margin-top: 4px;
`;

const MenuItem = ({ id, name, description, pathCount, moduleCount, certificateCount, active, onClick }) => {
  return (
    <Container active={active} className="col col-4" onClick={() => onClick(id)}>
      <Title>
        <p>{name}</p>
        {active ? <Icon circular inverted size="small" name="check" /> : <></>}
      </Title>
      <Description>{description}</Description>
      <hr />
      <Statistics>
        <StatisticsItem>
          <StatisticsItemValue>{moduleCount}</StatisticsItemValue>
          <StatisticsItemName>Modules</StatisticsItemName>
        </StatisticsItem>
        <StatisticsItem>
          <StatisticsItemValue>{pathCount}</StatisticsItemValue>
          <StatisticsItemName>Badges</StatisticsItemName>
        </StatisticsItem>
        <StatisticsItem>
          <StatisticsItemValue>{certificateCount}</StatisticsItemValue>
          <StatisticsItemName>Certificate</StatisticsItemName>
        </StatisticsItem>
      </Statistics>
    </Container>
  );
};

export default MenuItem;
