import { inject } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Header, Icon, Segment, Table } from 'semantic-ui-react';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';

const CustomTable = styled(Table)`
  border: none !important;
  border-radius: 8px;
  * {
    font-family: IBM Plex Sans;
    font-size: 16px;
    line-height: 135%;
  }
  > tbody {
    ::before {
      content: '';
      display: block;
      height: 24px;
    }

    > tr {
      &.checked {
        > td {
          background: #e9f5f3 !important;
        }
        > :nth-child(1) {
          border-radius: 8px 0px 0px 8px;
        }
        > :last-child {
          border-radius: 0px 8px 8px 0px;
        }
      }
    }
  }

  th,
  td {
    padding: 16px !important;
    border: none !important;
    background: #fff !important;
  }

  th {
    color: #53565a !important;
    font-weight: 500 !important;
  }

  td {
    color: #2e2d29;
    font-weight: 400;

    &.title {
      overflow: hidden;
      color: #017e7c !important;
      text-overflow: ellipsis;
      text-decoration-line: underline;
    }

    &.w500 {
      font-weight: 500;
    }
  }
`;

const AllRuns = ({ newWorkflowStore }) => {
  const [loading, setLoading] = useState();
  const [runs, setRuns] = useState();

  useEffect(() => {
    const getWorkflowRuns = () => newWorkflowStore.getWorkflowRuns();

    setLoading(true);
    getWorkflowRuns()
      .then(runs => {
        setRuns(runs);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        displayError(error);
      });
  }, []);

  if (loading) return <BasicProgressPlaceholder segmentCount={5} />;
  else if (!runs || runs.length === 0)
    return (
      <Segment placeholder className="mt3">
        <Header icon className="color-grey">
          <Icon name="clipboard outline" />
          No workflow runs available.
        </Header>
      </Segment>
    );
  else
    return (
      <CustomTable singleLine>
        <Table.Header>
          <Table.HeaderCell>Run name</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Completion time</Table.HeaderCell>
          <Table.HeaderCell>Workflow Name</Table.HeaderCell>
          <Table.HeaderCell>Estimated Time</Table.HeaderCell>
          <Table.HeaderCell>Version</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {runs?.map(r => (
            <Table.Row key={r.jobId}>
              <Table.Cell collapsing className="w500">
                {r.event.runName}
              </Table.Cell>
              <Table.Cell collapsing>{r.status}</Table.Cell>
              <Table.Cell collapsing> - </Table.Cell>
              <Table.Cell className="title">{r.workflowName}</Table.Cell>
              <Table.Cell collapsing> - </Table.Cell>
              <Table.Cell collapsing textAlign="right">
                {' - '}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </CustomTable>
    );
};

export default inject('newWorkflowStore')(AllRuns);
