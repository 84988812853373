import { types } from 'mobx-state-tree';

const Question = types.model('Question', {
  id: types.identifier,
  statement: types.string,
  type: types.enumeration(['RatingScale', 'Text']),
  scale: types.maybeNull(types.number),
  keyword: types.maybeNull(types.enumeration(['Agree', 'Confident', 'Likely'])),
});

const Survey = types
  .model('Survey', {
    id: types.identifier,
    questions: types.optional(types.array(Question), []),
    questionsCount: types.optional(types.integer, 0),
  })
  .actions(self => ({})) // eslint-disable-line no-unused-vars
  .views(self => ({})); // eslint-disable-line no-unused-vars

export { Survey };
