import React from 'react';
import { Accordion } from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled(Accordion.Content)`
  display: ${p => (p.active ? 'flex' : 'none')} !important;
  flex-direction: column;
  gap: 32px;
  margin: 24px 78px 0px 78px;
  padding: 0px !important;
`;

const AccordionContent = ({ active, children }) => <Container active={active}>{children}</Container>;

export default AccordionContent;
