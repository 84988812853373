import React from 'react';
import styled from 'styled-components';
import { CopyButton, StepTitle } from '../parts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  pre {
    width: 650px;
    margin: 0px;
    max-height: 400px;
    padding: 20px 24px;
    overflow-y: auto;
    text-align: left;
    text-wrap: balance;
    code {
      font-family: IBM Plex Sans;
    }
  }
`;

const Header = styled.div`
  display: flex;
  padding: 20px 24px;
  background: #f5f5f5;
  > div {
    align-items: flex-start;
    color: #969dab;
    p {
      font-size: 14px !important;
      font-weight: 450 !important;
    }
  }
`;

const JsonExample = ({ example }) => {
  const code = JSON.stringify(example, null, 2);
  return (
    <Container>
      <Header>
        <StepTitle
          title="Example of fastq_pairs"
          description="Copy the JSON example below, and modify the green section."
        />
        <CopyButton onClick={() => navigator.clipboard.writeText(code)}>Copy</CopyButton>
      </Header>
      <pre>
        <code>{code}</code>
      </pre>
    </Container>
  );
};

export default JsonExample;
