import React from 'react';
import styled from 'styled-components';
import Timeline from '../parts/Timeline';

const Container = styled.div`
  display: flex;
  padding: 8.571em;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Course = styled.div`
  margin-bottom: ${(p) => (p.last ? '0px' : '5.714em')};
`;

const Schedule = styled.h4`
  font-family: 'IBM Plex Mono';
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  color: #e98300;
  margin: 0px 0px 16px 0px;
  min-width: 160px;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  margin: 0px 0px 24px 0px;
`;

const Topics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Topic = styled.div`
  display: flex;
  gap: 24px;
`;

const Time = styled.div`
  min-width: 164px;
  font-family: 'IBM Plex Mono';
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: rgba(233, 131, 0, 0.7);
`;

const TopicDetails = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
`;

const TopicTitle = styled.h4`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  margin: 0px 0px 4px 0px;
  color: ${(p) => (p.secondary ? 'rgba(233, 131, 0, 0.5)' : '#2e2d29')};
`;

const TopicDescription = styled.p`
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #53565a;
  margin: 0px;
`;

const SubTopics = styled.ul`
  list-style: disc;
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;

const Courses = ({ courses }) => {
  return (
    <Container>
      <Content>
        <Timeline
          items={courses.map((c, i) => ({
            key: `${c.title}-${i}`,
            render: () => (
              <Course last={i === courses.length - 1}>
                <Schedule>{c.schedule}</Schedule>
                <Title>{c.title}</Title>
                <Topics>
                  {c.topics.map((t, i) => (
                    <Topic key={`${t.title}-${i}`}>
                      <Time>{t.time}</Time>
                      <TopicDetails>
                        <TopicTitle secondary={t.break || t.lunch}>{t.title}</TopicTitle>
                        <TopicDescription>{t.description}</TopicDescription>
                        {t.subTopics ? (
                          <SubTopics>
                            {t.subTopics.map((st, i) => (
                              <li key={`${st}-${i}`}>{st}</li>
                            ))}
                          </SubTopics>
                        ) : (
                          <></>
                        )}
                      </TopicDetails>
                    </Topic>
                  ))}
                </Topics>
              </Course>
            ),
          }))}
        ></Timeline>
      </Content>
    </Container>
  );
};

export default Courses;
