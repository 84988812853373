import React, { useState } from 'react';
import { Button, Icon, Form, Card, Divider, Tab, Label, Header, Message } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  handleUpdateStudy,
  handleComingSoon,
  // handleNotebookUpload,
  handleUploadVideo,
} from './AdminPageHelpers.js/AdminPageHelpers';

const StudyManagementTab = ({ studiesStoresMap }) => {
  const [studyName, setStudyName] = useState('');
  const [studyDesc, setStudyDesc] = useState('');
  const [studyId, setStudyId] = useState('');
  const [comingSoonStatus, setComingSoonStatus] = useState(true);

  const [studyVideoObject, setStudyVideo] = useState();
  // const [notebookObject, setNotebook] = useState();

  const studiesList = studiesStoresMap.organization.list;

  const studiesListSelectOptions = [];

  // eslint-disable-next-line no-unused-vars
  const studiesListSelectOptionsMap = studiesList.map(study => {
    const studiesArray = {};
    studiesArray.key = study.id;
    studiesArray.text = study.id;
    studiesArray.value = study.id;
    return studiesListSelectOptions.push(studiesArray);
  });

  return (
    <Tab.Pane attached={false}>
      <Divider horizontal>
        <Header as="h4">
          <Icon name="book" />
          Study Management
        </Header>
      </Divider>
      {studiesListSelectOptions.length ? (
        <Message
          warning
          icon="info"
          header="Please dont refresh this page, the studies dropdown data will be lost."
          content="This is a work in progress feature."
        />
      ) : (
        <Message
          error
          icon="arrow circle left"
          header="Ooops."
          content="Please go to the Workspaces tab to re-load the studies data."
        />
      )}

      <Divider hidden />
      <Card.Group stackable itemsPerRow={3} className="p1">
        <Card fluid color="orange" className="mb3 p2">
          <Card.Header as="h3" className="mb2 custom-card-header">
            <Icon name="edit" size="large" /> | Edit study description
          </Card.Header>
          <Card.Meta className="mb1">
            <p>Here you can edit the study description</p>
          </Card.Meta>
          <Card.Content>
            <Form className="custom-card" onSubmit={() => handleUpdateStudy(studyId, '', studyDesc)}>
              <Form.Select
                label="Study ID:"
                id="studyid"
                name="studyid"
                placeholder="Select your study ID"
                options={studiesListSelectOptions}
                required
                onChange={(e, data) => setStudyId(data.value)}
              />
              <Form.TextArea
                placeholder="Enter study description..."
                label="Study description: "
                id="studydescription"
                required
                onChange={(e, data) => setStudyDesc(data.value)}
              />
              <Button color="orange" type="submit">
                Update Study
              </Button>
            </Form>
          </Card.Content>
        </Card>

        <Card fluid color="orange" className="mb3 p2">
          <Card.Header as="h3" className="mb2 custom-card-header">
            <Icon name="edit" size="large" /> | Edit study name
          </Card.Header>
          <Card.Meta className="mb1">
            <p>Here you can edit the study name</p>
          </Card.Meta>
          <Card.Content>
            <Form className="custom-card" onSubmit={() => handleUpdateStudy(studyId, studyName, '')}>
              <Form.Select
                label="Study ID:"
                id="studyid"
                name="studyid"
                placeholder="Select your study ID"
                options={studiesListSelectOptions}
                onChange={(e, data) => setStudyId(data.value)}
                required
              />
              <Form.Input
                placeholder="Enter study name..."
                label="Study Name: "
                id="studyname"
                onChange={e => setStudyName(e.target.value)}
                required
              />
              <Button color="orange" type="submit">
                Update Study
              </Button>
            </Form>
          </Card.Content>
        </Card>

        <Card fluid color="orange" className="mb3 p2">
          <Card.Header as="h3" className="mb2 custom-card-header">
            <Icon name="tag" size="large" /> | Toggle Coming Soon
          </Card.Header>
          <Card.Meta className="mb1">
            <p>Here you can edit the coming soon marker</p>
          </Card.Meta>
          <Card.Content>
            <Form className="custom-card" onSubmit={() => handleComingSoon(studyId, comingSoonStatus)}>
              <Form.Select
                label="Study ID:"
                id="studyid"
                name="studyid"
                placeholder="Select your study ID"
                options={studiesListSelectOptions}
                required
                onChange={(e, data) => setStudyId(data.value)}
              />
              Select coming soon status:
              <Form.Radio
                radio
                toggle
                label={comingSoonStatus ? 'True' : 'False'}
                name="checkboxRadioGroup"
                checked={comingSoonStatus === true}
                value={comingSoonStatus}
                onChange={(e, data) => setComingSoonStatus(!data.value)}
              />
              <Button color="orange" type="submit">
                Save marker state
              </Button>
            </Form>
          </Card.Content>
        </Card>

        <Card fluid color="orange" className="mb3 p2">
          <Card.Header as="h3" className="mb2 custom-card-header">
            <Icon name="file video" size="large" /> | Upload new study video
          </Card.Header>
          <Card.Meta className="mb1">
            <strong>(This may take a few minutes)</strong>
            <p>This will upload a video for your selected study</p>
          </Card.Meta>
          <Card.Content>
            <Form className="custom-card" onSubmit={() => handleUploadVideo(studyId, studyVideoObject)}>
              <Form.Select
                label="Study ID:"
                id="studyid"
                name="studyid"
                placeholder="Select your study ID"
                options={studiesListSelectOptions}
                required
                onChange={(e, data) => setStudyId(data.value)}
              />
              <Label for="video">Choose video:</Label>
              <input
                id="video"
                type="file"
                name="video"
                className="mb3"
                onChange={event => setStudyVideo(event.target.files[0])}
              />
              <Button color="orange" type="submit">
                Upload Video
              </Button>
            </Form>
          </Card.Content>
        </Card>

        {/* <Card fluid color="orange" className="mb3 p2">
          <Card.Header as="h3" className="mb2 custom-card-header">
            <Icon name="sticky note" size="large" /> | Upload new Notebook
          </Card.Header>
          <Card.Meta className="mb1">
            <strong>(This may take a few minutes)</strong>
            <p>This will upload a Juyptr notebook for your selected study</p>
          </Card.Meta>
          <Card.Content>
            <Form className="custom-card" onSubmit={() => handleNotebookUpload(studyId, notebookObject)}>
              <Form.Select
                label="Study ID:"
                id="studyid"
                name="studyid"
                placeholder="Select your study ID"
                options={studiesListSelectOptions}
                required
                onChange={(e, data) => setStudyId(data.value)}
              />

              <Label for="notebook">Choose notebook:</Label>
              <input
                id="notebook"
                type="file"
                name="notebook"
                className="mb3"
                onChange={event => setNotebook(event.target.files[0])}
              />
              <Button color="orange" type="submit">
                Upload notebook
              </Button>
            </Form>
          </Card.Content>
        </Card> */}
      </Card.Group>
    </Tab.Pane>
  );
};

export default inject('studiesStoresMap')(observer(StudyManagementTab));
