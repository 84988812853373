import React from 'react';
import styled from 'styled-components';
import Title5 from './Title5';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  > {
    color: #2e2d29;
    font-family: IBM Plex Sans;
    letter-spacing: 0.1px;
  }

  > p {
    font-size: 16px;
    font-weight: 400;
  }
`;

const StepTitle = ({ title, description }) => (
  <Container>
    <Title5>{title}</Title5>
    {description ? <p>{description}</p> : <></>}
  </Container>
);

export default StepTitle;
