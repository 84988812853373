import React, { useState, useEffect } from 'react';
import { Modal } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import _ from 'lodash';
import styled from 'styled-components';
import ProgressEnum from '../../../../../models/learning/ProgressEnum';
import levelUpImage from '../../../../../images/level-up.svg';

const CustomModal = styled(Modal)`
  font-family: 'IBM Plex Sans';
  color: #2e2d29;
`;

const LevelUpImg = styled.img`
  width: 435px;
  height: 295px;
`;

const Title = styled.h1`
  width: 100%;

  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  width: 100%;
  margin: 0;
`;

const Actions = styled.div`
  width: calc(100% + 30px);
`;

const QuizModal = ({ learningId, pathId, module, disabled, learningStore, quizStore }) => {
  const isCompleted = module.progress === ProgressEnum.Completed;
  const [modalOpen, setModalOpen] = useState(false);
  const [quiz, setQuiz] = useState(null);
  const [buttonInProgress, setButtonInProgress] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    swallowError(quizStore.load());
  }, []);

  useEffect(() => {
    const getQuiz = () => quizStore.getQuiz(module.quizId);

    if (modalOpen && !_.isNil(module.quizId)) getQuiz(module.quizId).then(q => setQuiz(q));
  }, [modalOpen]);

  const renderTrigger = () => {
    const handleQuizModalClick = async () => {
      if (isCompleted) {
        window.open(`/learn/${learningId}/path/${pathId}/${module.id}/quiz`, '_blank').focus();
      } else {
        setModalOpen(true);
        if (module.progress === ProgressEnum.NotStarted) {
          await learningStore.startUserLearningModule(learningId, pathId, module.id);
        }
      }
    };

    return isCompleted ? (
      <button type="button" className="secondary" onClick={handleQuizModalClick}>
        View Answers
      </button>
    ) : (
      <button
        type="button"
        className={`secondary ${disabled || buttonInProgress ? 'disabled' : ''}`}
        onClick={handleQuizModalClick}
      >
        Take Quiz
      </button>
    );
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setButtonInProgress(false);
  };

  const renderModalContent = () => {
    const handleTakeQuiz = async () => {
      setButtonInProgress(true);
      window.open(`/learn/${learningId}/path/${pathId}/${module.id}/quiz`, '_blank').focus();
    };
    var passingPercentage = Math.ceil(((quiz.minValidAnswers ?? 0) * 100) / quiz.questionsCount);
    return (
      <div className="flex flex-column items-center p3">
        <i className="close" onClick={() => handleCloseModal()} />
        <LevelUpImg src={levelUpImage} alt="level up" />
        <Title className="my3">
          <span role="img" aria-label="start">
            ✨
          </span>{' '}
          Ready to level up?
        </Title>
        <Description>
          Unlock access to the next learning module once you correctly answer{' '}
          {passingPercentage === 0 ? 100 : passingPercentage}% of the questions in this quiz.
        </Description>
        <Description className="my3">
          Most students will find the learning materials in precision medicine challenging. However, we believe anyone
          is capable of growing their abilities if they put in the time and effort, use strategies for success, and
          reach out for help when they are struggling.
        </Description>
        <Description>
          You could try the quiz for as many times as you want, and ask instructors and peers in the community for help.
        </Description>
        <Actions className="mt4 custom-actions">
          <div className="col col-6 px2">
            <button
              type="button"
              className={`primary-black ${buttonInProgress ? 'disabled' : ''}`}
              onClick={handleTakeQuiz}
            >
              Yes, take me to quiz
            </button>
          </div>
          <div className="col col-6 px2">
            <button type="button" className="secondary" onClick={() => handleCloseModal()}>
              No, do later
            </button>
          </div>
        </Actions>
      </div>
    );
  };

  let content = (
    <div className="flex flex-column items-center p3">
      <i className="close" onClick={() => handleCloseModal()} />
      <LevelUpImg src={levelUpImage} alt="level up" />
      <Title className="my3">
        <span role="img" aria-label="start">
          ✨
        </span>{' '}
        Ready to level up?
      </Title>
      <BasicProgressPlaceholder segmentCount={2} className="mt3 mr0 ml0 col-12" />
    </div>
  );
  if (!_.isNil(quiz)) {
    content = renderModalContent();
  }

  return (
    <CustomModal size="small" centered open={modalOpen} trigger={renderTrigger()} onClose={() => handleCloseModal()}>
      {content}
    </CustomModal>
  );
};

export default inject('learningStore', 'quizStore')(observer(QuizModal));
