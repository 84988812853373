import styled from 'styled-components';

const ActionButton = styled.button`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #969dab;
  border: none;
  background: transparent;
`;

export default ActionButton;
