import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Modal, Placeholder } from 'semantic-ui-react';
import _ from 'lodash';
import styled from 'styled-components';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import SurveyContent from './parts/SurveyContent';

const SurveyModal = styled(Modal)`
  width: 100% !important;
  height: 100%;
  margin: 0px !important;
  top: 0px;
  background: #f5f5f5 !important;
  overflow: hidden;
`;
const Survey = styled.div`
  height: 100%;
`;
const SurveyHeader = styled.div`
  height: 50px;
  margin: 0px 150px;

  img {
    width: 210px;
    position: absolute;
    left: 24px;
    cursor: pointer;
  }

  h1 {
    font-family: 'IBM Plex Sans';
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #2e2d29;
    margin: 0px;
  }
`;

const SurveyPage = ({ match, assets, learningStore, surveyStore }) => {
  const params = match.params;

  const [module, setModule] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const getUserLearning = async id => {
      setLoading(true);
      const userLearning = await learningStore.getUserLearning(id);
      if (!_.isNil(userLearning) && userLearning.id === params.userLearningId && userLearning.paths.length > 0) {
        const pathIndex = userLearning.paths.findIndex(m => m.id === params.pathId);
        if (pathIndex > -1) {
          const moduleIndex = userLearning.paths[pathIndex].modules.findIndex(m => m.id === params.moduleId);
          if (moduleIndex > -1) {
            const module = userLearning.paths[pathIndex].modules[moduleIndex];
            setModule(module);
          } else setError(`Module with id ${params.moduleId} doesn't exist`);
        } else setError(`Path with id ${params.pathId} doesn't exist`);
      } else setError(`User learning with id ${params.userLearningId} doesn't exist`);
      setLoading(false);
    };

    getUserLearning(params.userLearningId).catch(error => {
      setLoading(false);
      setError(error.message);
    });
  }, [params]);

  useEffect(() => {
    const getSurvey = async id => {
      setLoading(true);
      const survey = await surveyStore.getSurvey(id);
      setSurvey(survey);
      setLoading(false);
    };

    if (!_.isNil(module) && !_.isNil(module.surveyId))
      getSurvey(module.surveyId).catch(error => {
        setLoading(false);
        setError(error.message);
      });
  }, [module]);

  let content = null;
  if (error) {
    content = <ErrorBox error={error} className="mt3 mr0 ml0" />;
  } else if (loading) {
    content = <BasicProgressPlaceholder segmentCount={5} />;
  } else if (!_.isNil(survey)) {
    content = (
      <SurveyContent
        survey={survey}
        userLearningId={params.userLearningId}
        pathId={params.pathId}
        moduleId={params.moduleId}
      />
    );
  }

  return (
    <SurveyModal className="pt3 px3" open>
      <Survey className="flex flex-column">
        <SurveyHeader className="flex items-center justify-center pb4 pt3">
          <img
            src={assets.images.logoImage}
            alt="Stanford Data Ocean Logo"
            onClick={() => {
              window.location = window.location.origin;
            }}
          />
          {!_.isNil(module) ? (
            <h1>{module.name}</h1>
          ) : (
            <div className="col-3">
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </div>
          )}
        </SurveyHeader>
        {content}
      </Survey>
    </SurveyModal>
  );
};

export default inject('learningStore', 'surveyStore')(withRouter(observer(SurveyPage)));
