import React from 'react';
import styled from 'styled-components';
import Header from './Header';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 180px;
  padding: 12px;
  border-radius: 3px;
  border: 0.5px solid #f9a44a;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(255, 159, 71, 0.05);
`;

const Title = styled.h6`
  color: #2e2d29;
  font-size: 8px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  line-height: 8px;
  margin: 6px 0px 0px 0px;
`;

const Description = styled.p`
  color: #2e2d29;
  font-size: 6px;
  font-family: IBM Plex Sans;
  font-weight: 450;
  line-height: 8px;
  letter-spacing: 0px;
  margin: 6px 0px 0px 0px;
`;

const Img = styled.img`
  margin-top: 13px;
  max-width: 100%;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  width: 100%;
  gap: 10px;
  > button {
    width: 100%;
    height: 16px;
    font-size: 6px;
    border-radius: 1.5px;
    pointer-events: none;
    font-family: IBM Plex Mono;
    font-weight: 400;
    line-height: 8px;
  }
`;

const StartButton = styled.button`
  color: #fff;
  border: 0px;
  background: #2e2d29;
`;

const TakeQuizButton = styled.button`
  color: #2e2d29;
  border: 0.5px solid #2e2d29;
  background: #fff;
`;

const Study = ({ index, title, description, img, icon }) => (
  <Container>
    <Header index={index} icon={icon} />
    <Title>{title}</Title>
    <Description>{description}</Description>
    <Img src={`${window.location.origin}/static/courses/${title.replace(/[\/| ]/g, '-')}.png`} />
    <Actions>
      <StartButton>
        <span role="img" aria-label="rocket">
          🚀
        </span>{' '}
        Start module
      </StartButton>
      <TakeQuizButton>Take quiz</TakeQuizButton>
    </Actions>
  </Container>
);

export default Study;
