import React from 'react';
import Filter from '../actions/Filter';
import ResultCount from '../actions/ResultCount';
import Sort from '../actions/Sort';
import Title from '../actions/Title';

const DataActions = props => {
  return (
    <div className="data-actions pt2">
      <div className="col col-3">
        <Title className="pr3">Filter</Title>
        <Filter onChange={props.onFilterChange} />
      </div>
      <div className="col col-3 flex">
        <Title className="pr3">Sort</Title>
        <Sort value="Recently Published" onChange={props.onSortChange} />
      </div>
      <ResultCount className="col col-1 col-right">{props.resultsCount} results</ResultCount>
    </div>
  );
};

export default DataActions;
