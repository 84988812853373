import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  place-items: center;
  display: grid;
  grid-template: 1fr / 1fr;

  > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
`;
const StepProgressIcon = styled.span`
  -webkit-mask-image: url('${window.location.origin}/static/icons/step.svg');
  mask-image: url('${window.location.origin}/static/icons/step.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background: transparent;
  width: 63px;
  height: 64px;

  &.active {
    background: #ffe781;
  }
`;
const StepContainerIcon = styled.span`
  -webkit-mask-image: url('${window.location.origin}/static/icons/step.svg');
  mask-image: url('${window.location.origin}/static/icons/step.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background: #d7dbe3;
  width: 48px;
  height: 48px;

  &.active {
    background: #f9a44a;
  }
`;
const StepIcon = styled.span`
  background: #ffffff;
  width: 25px;
  height: 22px;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-image: url('${window.location.origin}/static/icons/${(p) => p.icon}.svg');
  mask-image: url('${window.location.origin}/static/icons/${(p) => p.icon}.svg');
  &.active {
    background: #fff5cb;
  }
`;

const PathIcon = ({ active, completed, icon }) => {
  return (
    <Container>
      <StepProgressIcon className={completed ? 'active' : ''} />
      <StepContainerIcon className={active || completed ? 'active' : ''} />
      <StepIcon icon={icon} className={`${active ? 'active' : ''}`} />
    </Container>
  );
};

export default PathIcon;
