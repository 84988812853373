import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 30px 60px rgba(146, 157, 165, 0.2);
  backdrop-filter: blur(50px);
  position: sticky;
  bottom: 10px;
`;

const Index = styled.div`
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
`;

const Item = styled.div`
  height: 12px;
  background: #d7dbe3;
  border-radius: 100px;
  min-width: 100px;

  &.active {
    background: #f9a44a;
  }
`;

const PaginatedProgress = ({
  pageIndex,
  progress,
  total,
  pageSize,
  loading,
  progressIds,
  totalIds,
  onNext,
  onSubmit,
}) => {
  const renderActionButton = () => {
    let clickHandler = () => {
      onNext(pageIndex + 1);
    };

    let text = 'Next';
    if (total <= pageSize || Math.ceil(total / pageSize) - 1 === pageIndex) {
      text = 'Submit';
      clickHandler = onSubmit;
    }

    const isDisabled = !(progress === pageIndex * pageSize + pageSize || progress === total);

    return (
      <button
        type="button"
        className={`primary-black justify-center ${isDisabled || loading ? 'disabled' : ''}`}
        onClick={clickHandler}
      >
        {loading ? (
          <div className="mr2">
            <i className="sync loading icon" />
          </div>
        ) : (
          ''
        )}
        {text}
      </button>
    );
  };

  return (
    <Container className="flex items-center justify-between mb3 px4 py3">
      <Index className="col-1">{`${progress} of ${total}`}</Index>
      <div className="flex items-center justify-between ">
        {totalIds.map(i => (
          <Item key={i} className={`mx1 col ${progressIds.has(i) ? 'active' : ''}`} />
        ))}
      </div>
      <div className="custom-actions col-1">{renderActionButton()}</div>
    </Container>
  );
};

export default PaginatedProgress;
