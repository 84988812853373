// const apiGatewayUrls = {
//   handleUpdateStudy: 'https://uwg153qwnl.execute-api.us-east-2.amazonaws.com/Dev/updatestudy',
//   handleComingSoon: 'https://uwg153qwnl.execute-api.us-east-2.amazonaws.com/Dev/updatecomingsoon',
//   handleAddNewDomain: 'https://uwg153qwnl.execute-api.us-east-2.amazonaws.com/Dev/addapproveddomain',
//   handleAddAdmin: 'https://uwg153qwnl.execute-api.us-east-2.amazonaws.com/Dev/addbackofficeuser',
//   handleUploadVideo: 'https://uwg153qwnl.execute-api.us-east-2.amazonaws.com/Dev/updatestudyvideo',
//   handleNotebookUpload: 'https://uwg153qwnl.execute-api.us-east-2.amazonaws.com/Dev/updatestudynotebook',
//   handleNewStudy: 'https://uwg153qwnl.execute-api.us-east-2.amazonaws.com/Dev/adduserstonewstudies',
// };

// PROD
const apiGatewayUrls = {
  handleUpdateStudy: 'https://bsrnhu4o5i.execute-api.us-east-1.amazonaws.com/Prod/updatestudynameordesc',
  handleComingSoon: 'https://bsrnhu4o5i.execute-api.us-east-1.amazonaws.com/Prod/updatecomingsoon',
  handleAddNewDomain: 'https://bsrnhu4o5i.execute-api.us-east-1.amazonaws.com/Prod/addapproveddomain',
  handleAddAdmin: 'https://bsrnhu4o5i.execute-api.us-east-1.amazonaws.com/Prod/addbackofficeuser',
  handleUploadVideo: 'https://bsrnhu4o5i.execute-api.us-east-1.amazonaws.com/Prod/uploadstudyvideo',
  handleNotebookUpload: 'https://bsrnhu4o5i.execute-api.us-east-1.amazonaws.com/Prod/uploadstudynotebook',
  handleNewStudy: 'https://bsrnhu4o5i.execute-api.us-east-1.amazonaws.com/Prod/adduserstonewstudies',
};

export default apiGatewayUrls;
