import { types } from 'mobx-state-tree';
import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import { getQuiz, createQuizInstance, createQuizInstanceSubmission, getQuizSubmissionResult } from '../../helpers/api';
import { Quiz } from './Quiz';

// ==================================================================
// QuizStore
// ==================================================================
const QuizStore = BaseStore.named('QuizStore')
  .props({
    quizzes: types.optional(types.map(Quiz), {}),
  })
  .actions(self => {
    return {
      async getQuiz(id) {
        if (self.quizzes.has(id)) return self.quizzes.get(id);

        const quiz = await getQuiz(id);
        self.runInAction(() => self.quizzes.set(id, quiz));

        return quiz;
      },

      async createQuizInstance(id) {
        return createQuizInstance(id);
      },

      async createQuizInstanceSubmission(id, submission) {
        return createQuizInstanceSubmission(id, submission);
      },

      async getQuizSubmissionResult(id) {
        return getQuizSubmissionResult(id);
      },
    };
  })
  .views(() => ({}));

function registerContextItems(appContext) {
  appContext.quizStore = QuizStore.create({}, appContext);
}

export { QuizStore, registerContextItems };
