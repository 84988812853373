import swal from '@sweetalert/with-react';
import sendtoAPIGateway from './SendtoAPIGateway';
import getPresignedUrl from './getPresignedUrl';
import apiGatewayUrls from './apiGatewayUrls';

export const handleUpdateStudy = (studyId, studyName, studyDesc) => {
  const apigateway = apiGatewayUrls.handleUpdateStudy;

  const data = {
    study_id: studyId,
    description: studyDesc,
    name: studyName,
  };

  swal({
    title: 'Updating study...',
    text: '',
    buttons: false,
  });

  sendtoAPIGateway(apigateway, data);
};

export const handleComingSoon = (studyId, comingSoonStatus) => {
  const apigateway = apiGatewayUrls.handleComingSoon;

  const data = {
    study_id: studyId,
    isComingSoon: comingSoonStatus,
  };

  swal({
    title: 'Updating coming soon marker...',
    text: '',
    buttons: false,
  });

  sendtoAPIGateway(apigateway, data);
};

export const handleAddNewDomain = domainName => {
  const apigateway = apiGatewayUrls.handleAddNewDomain;

  const data = {
    id: domainName,
    domain: domainName,
  };

  swal({
    title: 'Adding new domain...',
    text: '',
    buttons: false,
  });

  sendtoAPIGateway(apigateway, data);
};

export const handleAddAdmin = userId => {
  const apigateway = apiGatewayUrls.handleAddAdmin;

  const data = {
    id: userId,
    isBackOffice: true,
  };

  swal({
    title: 'Adding back office user...',
    text: '',
    buttons: false,
  });

  sendtoAPIGateway(apigateway, data);
};

export const handleUploadVideo = (studyId, videoObject) => {
  const apigateway = apiGatewayUrls.handleUploadVideo;
  swal({
    title: 'Prepping video file for upload...',
    text: '',
    buttons: false,
  });
  getPresignedUrl(videoObject, apigateway, studyId);
};

export const handleNotebookUpload = (studyId, notebookObject) => {
  const apigateway = apiGatewayUrls.handleNotebookUpload;
  swal({
    title: 'Prepping notebook file for upload...',
    text: '',
    buttons: false,
  });

  getPresignedUrl(notebookObject, apigateway, studyId);
};

export const handleNewStudy = studyId => {
  const apigateway = apiGatewayUrls.handleNewStudy;
  const data = {
    study_id: studyId,
  };
  swal({
    title: 'Prepping users for study...',
    text: '',
    buttons: false,
  });

  sendtoAPIGateway(apigateway, data);
};
