import _, { floor } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import linkImg from '../../../../images/static/campaign/link.svg';
import linkedinImg from '../../../../images/static/campaign/linkedin.svg';
import twitterImg from '../../../../images/static/campaign/twitter.svg';
import mailImg from '../../../../images/static/campaign/mail.svg';
import googleScholarImg from '../../../../images/static/campaign/google-scholar.svg';
import { Grid } from 'semantic-ui-react';
import Title from './Title';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8.571em 0px;
  align-items: center;
`;

const Member = styled.div`
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfilePicture = styled.img`
  max-width: 100%;
  border-radius: 20px;
  background-color: ${(p) => p.color};
  margin: 0px 0px 40px 0px;
`;

const Name = styled.h2`
  margin: 0px 0px 16px 0px;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
`;

const Position = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #53565a;
  margin: 0px 0px 44px 0px;
  max-width: 320px;
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  padding: 0 60px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const Team = ({ title, team, columns }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Grid centered>
        {_.chunk(team, columns).map((c, i) => (
          <Grid.Row key={`r-${i}`} columns={columns} stretched>
            {c.map((m) => (
              <Grid.Column key={m.name} computer={Math.floor(16 / columns)} mobile={16} className="items-center">
                <Member>
                  <ProfilePicture
                    src={`${window.location.origin}/static/${m.profilePicture}`}
                    alt={m.name}
                    color={m.color}
                  />
                  <Name>{m.name}</Name>
                  <Position>{m.position}</Position>
                  <Links>
                    {m.link ? (
                      <a href={m.link} target="_blank">
                        <Icon src={linkImg} alt="link" width={24} height={24} />
                      </a>
                    ) : (
                      <></>
                    )}
                    {m.linkedin ? (
                      <a href={m.linkedin} target="_blank">
                        <Icon src={linkedinImg} alt="linkedin" width={24} height={24} />
                      </a>
                    ) : (
                      <></>
                    )}
                    {m.twitter ? (
                      <a href={m.twitter} target="_blank">
                        <Icon src={twitterImg} alt="twitter" width={24} height={24} />
                      </a>
                    ) : (
                      <></>
                    )}
                    {m.mail ? (
                      <a href={m.mail} target="_blank">
                        <Icon src={mailImg} alt="mail" width={24} height={24} />
                      </a>
                    ) : (
                      <></>
                    )}
                    {m.googleScholar ? (
                      <a href={m.googleScholarImg} target="_blank">
                        <Icon src={googleScholarImg} alt="google-scholar" width={24} height={24} />
                      </a>
                    ) : (
                      <></>
                    )}
                  </Links>
                </Member>
              </Grid.Column>
            ))}
          </Grid.Row>
        ))}
      </Grid>
    </Container>
  );
};

export default Team;
