import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import EnvironmentStatus from '../../../models/EnvironmentStatus';
import { DropdownWrapper } from '../../components';
import DeleteAction from './actions/DeleteAction';
import PauseAction from './actions/PauseAction';

const ActionsButton = ({ study, workspace, scEnvironmentsStore }) => {
  const [buttonStatus, setButtonStatus] = useState();

  useEffect(() => {
    if (workspace?.status) setButtonStatus(undefined);
  }, [workspace?.status]);

  const handleAction = async fn => {
    try {
      await fn();
    } catch (error) {
      displayError(error);
      setButtonStatus(undefined);
    }
  };

  const handleStart = async () => {
    setButtonStatus(EnvironmentStatus.Starting);
    await handleAction(async () => {
      if (!_.isNil(workspace?.status)) {
        await scEnvironmentsStore.startScContainerEnvironment(workspace?.id);
      } else {
        await scEnvironmentsStore.launchScStatefullContainerEnvironment(study.studyId);
      }
    });
  };

  const handleConnect = async () => {
    await handleAction(async () => {
      const url = workspace?.url;
      window.open(url, '_blank', 'noopener,noreferrer');
    });
  };

  const handlePause = async () => {
    setButtonStatus(EnvironmentStatus.Stopping);
    await handleAction(async () => {
      await scEnvironmentsStore.stopScContainerEnvironment(workspace?.id);
    });
  };

  const handleDelete = async () => {
    setButtonStatus(EnvironmentStatus.Terminating);
    await handleAction(async () => {
      if (!_.isNil(workspace?.status)) {
        await scEnvironmentsStore.terminateScContainerEnvironment(workspace?.id);
      }
    });
  };

  let trigger = '';
  let className = '';
  if (
    buttonStatus === EnvironmentStatus.Starting ||
    workspace?.status === EnvironmentStatus.Pending ||
    workspace?.status === EnvironmentStatus.Starting
  ) {
    className = 'progress justify-center';
    trigger = (
      <button type="button" className={className}>
        <div className="mr2">
          <i className="sync loading icon" />
        </div>
        Setting up...
      </button>
    );
  } else if (
    buttonStatus === EnvironmentStatus.Terminating ||
    workspace?.status === EnvironmentStatus.Terminating ||
    workspace?.status === EnvironmentStatus.ShuttingDown
  ) {
    className = 'progress justify-center';
    trigger = (
      <button type="button" className={className}>
        <span role="img" aria-label="trash">
          🗑️
        </span>
        Deleting Module...
      </button>
    );
  } else if (_.isNil(workspace?.status) || workspace?.status === EnvironmentStatus.Stopped) {
    className = 'primary-black';
    trigger = (
      <button type="button" className={className} onClick={handleStart}>
        <span role="img" aria-label="rocket">
          🚀
        </span>
        Start module
      </button>
    );
  } else if (buttonStatus === EnvironmentStatus.Stopping || workspace?.status === EnvironmentStatus.Stopping) {
    className = 'progress justify-center';
    trigger = (
      <button type="button" className={className}>
        <span role="img" aria-label="pause">
          ⏸️
        </span>
        Pausing Module...
      </button>
    );
  } else if (workspace?.status === EnvironmentStatus.Running) {
    className = `primary-blue ${buttonStatus ? 'disabled' : ''}`;
    trigger = (
      <button type="button" className={className} onClick={handleConnect}>
        <span role="img" aria-label="rocket">
          ✨
        </span>
        Launch Module
      </button>
    );
  }

  return (
    <DropdownWrapper
      trigger={trigger}
      direction="left"
      openOnFocus={false}
      button
      icon={
        <Icon
          name="angle down"
          disabled={_.isNil(workspace.status) || workspace.status === EnvironmentStatus.Terminated}
        />
      }
      additionPosition="bottom"
      className={className}
    >
      {_.isNil(workspace.status) || workspace.status === EnvironmentStatus.Terminated ? (
        <></>
      ) : (
        <Dropdown.Menu>
          {_.isNil(workspace?.status) || workspace?.status === EnvironmentStatus.Stopped ? (
            <DeleteAction onDelete={handleDelete} />
          ) : (
            <>
              {<PauseAction onPause={handlePause} />}
              {<DeleteAction onDelete={handleDelete} />}
            </>
          )}
        </Dropdown.Menu>
      )}
    </DropdownWrapper>
  );
};

export default inject('scEnvironmentsStore')(observer(ActionsButton));
