import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0px 12px 0px;
  gap: 24px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  backdrop-filter: blur(5px);
  padding: 24px;
  gap: 16px;

  > {
    font-family: IBM Plex Sans;
    color: #2e2d29;
    line-height: 20px;
  }

  span {
    font-size: 20px;
  }

  h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0px !important;
  }
  p {
    font-size: 16px;
    font-weight: 450;
    letter-spacing: 0.2px;
    margin: 0px !important;
  }
`;

const QuestionsAndAnswers = ({ questionsAndAnswers }) => (
  <Container>
    {questionsAndAnswers.map(qa => (
      <Item>
        <span>{qa.icon}</span>
        <h4>{qa.question}</h4>
        <p>{qa.answer}</p>
      </Item>
    ))}
  </Container>
);

export default QuestionsAndAnswers;
