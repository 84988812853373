import React from 'react';
import styled from 'styled-components';
import { CopyBlock, dracula } from 'react-code-blocks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CodeSnippit = ({ language, code, beforeText, afterText }) => {
  return (
    <Container>
      <p>{beforeText}</p>
      <br />
      <CopyBlock
        text={code}
        language={language}
        showLineNumbers={true}
        theme={dracula}
        customStyle={{ display: 'flex', overflowX: 'auto' }}
        wrapLines
      />
      <br />
      <p>{afterText}</p>
    </Container>
  );
};

export default CodeSnippit;
