import { applySnapshot, types } from 'mobx-state-tree';
import MessageType from './MessageType';
import FeedbackCategory from './FeedbackType';

const Feedback = types.model('Feedback', {
  category: types.enumeration([FeedbackCategory.Like, FeedbackCategory.Dislike]),
  subCategories: types.maybeNull(
    types.array(
      types.enumeration([
        'Inaccurate',
        'Irrelevant',
        'Biased',
        'Difficult to understand',
        'Not engaging or interesting',
      ]),
    ),
  ),
  comment: types.maybeNull(types.string),
});

const Message = types
  .model('Message', {
    idx: types.string,
    type: types.enumeration([MessageType.Human, MessageType.AI, MessageType.AI_Intro, MessageType.Typing]),
    message: types.maybeNull(types.string),
    source: types.maybeNull(types.string),
    feedback: types.maybeNull(Feedback),
    disableFeedback: types.optional(types.boolean, false),
  })
  .actions(self => ({
    set(raw) {
      applySnapshot(self, raw);
    },
  })) // eslint-disable-line no-unused-vars
  .views(self => ({})); // eslint-disable-line no-unused-vars

export { Message };
