import { applySnapshot, types } from 'mobx-state-tree';

const NewWorkflowRun = types
  .model('WorkflowRun', {})
  .actions(self => ({
    set(raw) {
      applySnapshot(self, raw);
    },
  })) // eslint-disable-line no-unused-vars
  .views(() => ({})); // eslint-disable-line no-unused-vars

export { NewWorkflowRun };
