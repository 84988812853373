import { types } from 'mobx-state-tree';

const choice = types.model('Choice', {
  id: types.identifier,
  value: types.string,
});

const attachment = types.model('Attachment', {
  type: types.enumeration(['Image']),
  path: types.string,
  name: types.maybeNull(types.string),
});

const question = types.model('Question', {
  id: types.identifier,
  statement: types.string,
  type: types.enumeration(['SingleChoice', 'MultipleChoice']),
  choices: types.array(choice),
  attachments: types.array(attachment),
});

const QuizInstance = types
  .model('QuizInstance', {
    id: types.identifier,
    quizId: types.string,
    questions: types.array(question),
  })
  .actions(self => ({})) // eslint-disable-line no-unused-vars
  .views(self => ({})); // eslint-disable-line no-unused-vars

const Quiz = types
  .model('Quiz', {
    id: types.identifier,
    questions: types.optional(types.array(question), []),
    questionsCount: types.optional(types.integer, 0),
    maxNumberOfTrials: types.optional(types.number, 0),
    quizDuration: types.optional(types.number, 0),
  })
  .actions(self => ({})) // eslint-disable-line no-unused-vars
  .views(self => ({})); // eslint-disable-line no-unused-vars

export { Quiz, QuizInstance };
