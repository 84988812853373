import React, { useState } from 'react';
import styled from 'styled-components';
import { ActionButton, Title5 } from '../../parts';
import { inject } from 'mobx-react';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';
import TestAccessState from './TestAccessState';
import StepContainer from './StepContainer';
import CopyButton from '../../parts/CopyButton';

const Container = styled(StepContainer)`
  margin: 32px 0px 0px 0px;
`;

const SecondStep = ({ paths, onStateChange, newWorkflowStore }) => {
  const [testAccessLoading, setTestAccessLoading] = useState();
  const [testAccessFailed, setTestAccessFailed] = useState();
  const [testAccessSuccess, setTestAccessSuccess] = useState();

  const handleTestAccessClick = async () => {
    setTestAccessFailed(false);
    setTestAccessLoading(true);
    setTestAccessSuccess(false);
    try {
      const response = await newWorkflowStore.testWorkflowS3Access(paths);
      if (response.state === TestAccessState.ErrorKMS || response.state === TestAccessState.Error)
        setTestAccessFailed(true);
      else if (response.state === TestAccessState.Success) setTestAccessSuccess(true);
      onStateChange(response.state);
    } catch (error) {
      displayError(error);
    }
    setTestAccessLoading(false);
  };
  const code =
    '{\n  "Version": "2012-10-17",\n  "Id": "key-default-1",\n  "Statement": [\n    {\n      "Sid": "Allow use of the key",\n      "Effect": "Allow",\n      "Principal": {\n        "AWS": "[YourRoleARN]"\n      },\n      "Action": [\n        "kms:Decrypt",\n        "kms:Encrypt",\n        "kms:ReEncrypt*",\n        "kms:GenerateDataKey*",\n        "kms:DescribeKey"\n      ],\n      "Resource": "*"\n    },\n    {\n      "Sid": "Allow attachment of persistent resources",\n      "Effect": "Allow",\n      "Principal": {\n        "AWS": "[YourRoleARN]"\n      },\n      "Action": [\n        "kms:CreateGrant",\n        "kms:ListGrants",\n        "kms:RevokeGrant"\n      ],\n      "Resource": "*",\n      "Condition": {\n        "Bool": {\n          "kms:GrantIsForAWSResource": "true"\n        }\n      }\n    }\n  ]\n}';
  const codeParts = [
    '{\n  "Version": "2012-10-17",\n  "Id": "key-default-1",\n  "Statement": [\n    {\n      "Sid": "Allow use of the key",\n      "Effect": "Allow",\n      "Principal": {\n        "AWS": ',
    <b className="green">"[YourKMSKeyID]"</b>,
    '\n      },\n      "Action": [\n        "kms:Decrypt",\n        "kms:Encrypt",\n        "kms:ReEncrypt*",\n        "kms:GenerateDataKey*",\n        "kms:DescribeKey"\n      ],\n      "Resource": "*"\n    },\n    {\n      "Sid": "Allow attachment of persistent resources",\n      "Effect": "Allow",\n      "Principal": {\n        "AWS": ',
    <b className="green">"[YourRoleARN]"</b>,
    '\n      },\n      "Action": [\n        "kms:CreateGrant",\n        "kms:ListGrants",\n        "kms:RevokeGrant"\n      ],\n      "Resource": "*",\n      "Condition": {\n        "Bool": {\n          "kms:GrantIsForAWSResource": "true"\n        }\n      }\n    }\n  ]\n}',
  ];
  return (
    <Container>
      <Title5>2. Update the KMS Key Policy (only if your bucket is encrypted through KMS)</Title5>
      <p>
        If your data is encrypted with a KMS key, you need to update the KMS Key Policy to allow the IAM role to use the
        key.
      </p>
      <ol>
        <li>
          In the AWS Management Console, find the <b>“Services”</b> dropdown and select <b>“KMS”</b> under{' '}
          <b>“Security, Identity, & Compliance”</b>
        </li>
        <li>
          Click on <b>“Customer managed keys”</b> on the left sidebar.
        </li>
        <li>Click on the alias or the key ID of the KMS key you are using to encrypt the bucket.</li>
        <li>
          Click on the <b>“Edit”</b> button under <b>“Key policy”</b>.
        </li>
        <li>
          Paste the following policy into the policy editor, replacing <b className="green">[YourKMSKeyID]</b> with the
          ID of your KMS key and <b className="green">[YourRoleARN]</b> with the ARN of the IAM role:
          <pre>
            <CopyButton onClick={() => navigator.clipboard.writeText(code)}>Copy</CopyButton>
            <code>{codeParts}</code>
          </pre>
        </li>
        <li>
          Click <b>“Save”</b>.
        </li>
        <li>
          Now, the predefined IAM role has both read and write access to the S3 bucket (or prefix) and can use the KMS
          key to encrypt and decrypt the data. If you encounter any issues, please contact our support team:{' '}
          <a href="mailto:support@stanford.edu" target="_blank" rel="noreferrer">
            support@stanford.edu
          </a>
          .
        </li>
      </ol>
      <ActionButton
        loading={testAccessLoading}
        loadingText="Testing.."
        errorText={testAccessFailed ? 'Please check all information is correct, and try again.' : undefined}
        successText={testAccessSuccess ? 'Access granted.' : undefined}
        disabled={testAccessLoading}
        onClick={handleTestAccessClick}
      >
        Test access
      </ActionButton>
    </Container>
  );
};

export default inject('newWorkflowStore')(SecondStep);
