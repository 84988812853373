import React from 'react';
import { Image } from 'semantic-ui-react';
import styled from 'styled-components';
import logoImg from '../../../../images/logo.svg';

const CustomLogo = styled(Image)`
  width: 195px;
  height: 50px;
  cursor: pointer;
`;

const Logo = ({ className }) => (
  <CustomLogo src={logoImg} alt="Stanford Data Ocean Logo" href="http://dataocean.stanford.edu" className={className} />
);

export default Logo;
