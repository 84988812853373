import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  padding: 12px 16px;
  background: #e8f3fd;
  border-radius: 8px;
  font-family: 'IBM Plex Sans';
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
  align-self: ${p => (p.alignLeft ? 'flex-start' : 'flex-end')};
  width: fit-content;
  > p {
    white-space: pre-wrap;
  }
`;

const HumanMessage = ({ message, alignLeft }) => {
  return (
    <Container alignLeft={alignLeft}>
      <p>{message}</p>
    </Container>
  );
};

export default HumanMessage;
