import React from 'react';
import styled from 'styled-components';
import backgroundImg from '../../../../images/static/campaign/background.svg';
import RocketButton from '../parts/RocketButton';
import Logo from '../parts/Logo';

const Container = styled.div`
  background-color: #fffbf6 !important;
  width: 100%;
  min-height: 1024px;
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  padding: 0px 14px 8.571em 14px;
`;

const CustomLogo = styled(Logo)`
  position: absolute !important;
  top: 40px;
  left: 48px;
  @media (max-width: 992px) {
    position: unset;
    left: unset;
  }
`;

const ContentContainer = styled.div`
  background-image: url(${backgroundImg});
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  margin-top: 11.785em;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 733px;
  text-align: center;

  button {
    font-weight: 400;
    font-size: 24px;
    line-height: 16px;
    padding: 24px 32px;
    border-radius: 8px;
    height: 64px;
  }
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 54px;
  line-height: 127%;
  letter-spacing: -0.02em;
  margin: 16px 0px 0px 0px;
  span {
    font-family: 'IBM Plex Serif';
    font-weight: 700;
    font-style: italic;
  }
`;

const SubTitle = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.11em;
  color: #e98300;
  margin: 0px;
`;

const Schedule = styled.h2`
  font-family: 'IBM Plex Mono';
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  margin: 48px 0px 0px 0px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  margin: 48px;
`;

const RegisterDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  color: #53565a;
  max-width: 500px;
  margin: 32px 0px 0px 0px;
`;

const Annoncement = ({ title, duration, schedule, registerUrl }) => {
  return (
    <Container>
      <CustomLogo />
      <ContentContainer>
        <Content>
          <SubTitle>FREE IN-PERSON WORKSHOP</SubTitle>
          <Title>
            {title} <span>in {duration}</span>
          </Title>
          <Schedule>{schedule}</Schedule>
          <Description>
            Join us in lectures with Stanford University faculties to learn how big data is used to track and treat
            diseases.
          </Description>
          <RocketButton url={registerUrl}>Join Waitlist</RocketButton>
          <RegisterDescription>
            Workshop is full, and registration is closed. However, you can join the waitlist for Stanford Data Ocean, a
            virtual bioinformatics education platform we are launching for the broader community.
          </RegisterDescription>
        </Content>
      </ContentContainer>
    </Container>
  );
};

export default Annoncement;
