import React, { useEffect, useState } from 'react';
import { Header, Icon, Segment, Tab } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import { categories } from '@aws-ee/base-raas-ui/dist/models/studies/categories';
import Stores from '@aws-ee/base-ui/dist/models/Stores';
import { runInAction } from 'mobx';
import { isStoreReady } from '@aws-ee/base-ui/dist/models/BaseStore';
import _ from 'lodash';
import DataStudy from './DataStudy';
import DataActions from './DataActions';
import OrderType from '../../../../models/OrderType';

const DataStudies = props => {
  let stores;
  const studiesStore = props.studiesStoresMap[categories.organization.id];
  runInAction(() => {
    stores = new Stores([studiesStore, props.dataTypesStore, props.phenotypesStore]);
  });

  const [checked, setChecked] = useState([]);
  const [filter, setFilter] = useState({});
  const [sortOrder, setSortOrder] = useState(OrderType.Ascending);

  useEffect(() => {
    swallowError(stores.load());
  }, [stores.ready]);

  const filterAndSortStudies = studies => {
    if (!_.isEmpty(filter.selectedDataTypes))
      studies = studies.filter(
        s => !_.isEmpty(s.dataTypes) && _.some(s.dataTypes, x => filter.selectedDataTypes.includes(x)),
      );

    if (!_.isEmpty(filter.selectedPhenotypes))
      studies = studies.filter(
        s => !_.isEmpty(s.phenotypes) && _.some(s.phenotypes, x => filter.selectedPhenotypes.includes(x)),
      );
    return studies.sort((a, b) => {
      const order = sortOrder === OrderType.Ascending ? 1 : -1;
      return a.createdAt > b.createdAt ? order : -order;
    });
  };

  const renderNoStudies = () => {
    return (
      <Segment placeholder className="mt3">
        <Header icon className="color-grey">
          <Icon name="clipboard outline" />
          No studies available.
        </Header>
      </Segment>
    );
  };

  const renderStudies = studies => {
    const checkboxClickHandler = (data, study) => {
      let tempChecked = [];
      if (data.checked) tempChecked = [...checked, study];
      else {
        const index = checked.findIndex(s => s.id === study.id);
        if (index !== -1) {
          checked.splice(index, 1);
          tempChecked = checked;
        }
      }
      setChecked(tempChecked);
      props.onSelectedChange(tempChecked);
    };

    return studies.map(s => (
      <DataStudy
        key={s.id}
        {...s}
        dataTypes={
          !_.isEmpty(s.dataTypes) ? props.dataTypesStore.dataTypes.filter(i => s.dataTypes.includes(i.id)) : []
        }
        phenotypes={
          !_.isEmpty(s.phenotypes) ? props.phenotypesStore.phenotypes.filter(i => s.phenotypes.includes(i.id)) : []
        }
        onClick={(_, data) => checkboxClickHandler(data, s)}
      />
    ));
  };

  let content = null;
  let studies = [];
  if (stores.error) {
    content = <ErrorBox error={studiesStore.error.messsage} className="mt3 mr0 ml0" />;
  } else if (stores.loading) {
    content = <BasicProgressPlaceholder segmentCount={5} className="mt3 mr0 ml0" />;
  } else if (isStoreReady(studiesStore) && isStoreReady(props.dataTypesStore) && isStoreReady(props.phenotypesStore)) {
    studies = filterAndSortStudies(studiesStore.find('Data'));
    content = _.isNil(studies) || studies.length === 0 ? renderNoStudies() : renderStudies(studies);
  }

  return (
    <Tab.Pane loading={stores.loading} attached={false} className="flex flex-column">
      <DataActions
        resultsCount={studies.length}
        onFilterChange={e => setFilter(e)}
        onSortChange={e => setSortOrder(e)}
      />
      {content}
    </Tab.Pane>
  );
};

export default inject('studiesStoresMap', 'dataTypesStore', 'phenotypesStore')(observer(DataStudies));
