import React, { useRef, useState } from 'react';
import { Divider, Icon, Input, Modal, TextArea } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';
import CustomCheckbox from '../actions/CustomCheckbox';
import noDataStudiesImg from '../../../../images/no-data-studies.svg';

const Title = styled.h2`
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #2e2d29;
`;
const Subtitle = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #2e2d29;
`;
const Items = styled.div`
  border: 1px solid #d7dbe3;
  border-radius: 8px;
  max-height: 250px;
  overflow-y: auto;
`;

const Item = styled.div`
  :nth-child(odd) {
    background: #f5f5f5;
  }
  .checkbox {
    label {
      padding-left: 52px !important;
      font-weight: 700;
      font-size: 16px !important;
      line-height: 135%;
      color: #2e2d29 !important;
      span {
        font-weight: 400;
        font-style: italic;
        color: #969593;
      }
    }
  }
`;

const Form = styled.div`
  input,
  textarea {
    background: #f5f5f5 !important;
    border: 1px solid #d7dbe3 !important;
    border-radius: 8px !important;
  }
  label {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #2e2d29;
  }

  button {
    &.workspaceId {
      font-family: 'IBM Plex Sans' !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 21px !important;
      color: #2e2d29 !important;
      position: relative;
      left: -108px;
      border: none;
      margin: 4px;
      align-items: center;
      min-width: 100px;
      background: #e4e4e4;
      border-radius: 4px;
      padding: 5px 16px;
      cursor: pointer;
      i {
        margin-right: 14px;
        color: rgba(46, 45, 41, 0.5);
      }
    }
  }
`;

const DataProjectModal = ({
  isEdit = false,
  id = null,
  modalOpen,
  studies,
  projectName,
  projectDescription,
  onCheckboxClick,
  onProjectNameChange,
  onProjectDescriptionChange,
  onModalStateChange,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  let workspaceIdRef = useRef(null);

  const submitHandler = async () => {
    try {
      setLoading(true);
      await onSubmit();
      setLoading(false);
      onModalStateChange({ openModal: false, showCancel: false });
    } catch (error) {
      displayError(error);
      setLoading(false);
    }
  };

  const renderNoStudies = () => (
    <>
      <Subtitle className="my3">
        You have not selected any data. After you launch the project, make sure to upload your data in the “Shared”
        folder.
      </Subtitle>
      <img src={noDataStudiesImg} alt="no-data-studies" className="mb3" />
    </>
  );

  const renderStudies = () => (
    <>
      <Subtitle className="my3">Use the selected datasets to {isEdit ? 'update' : 'create'} a project.</Subtitle>
      <Items>
        {studies.map(s => (
          <Item className=" p3">
            <CustomCheckbox
              key={s.id}
              type="checkbox"
              value={s.id}
              defaultChecked
              disabled={isEdit}
              label={
                <label>
                  {s.name}.{' '}
                  <span>
                    {_.isNil(s.publication) ? 'Not Available' : s.publication.source},{' '}
                    {moment(s.createdAt).format('MMMM, yyyy')}
                  </span>
                </label>
              }
              onClick={onCheckboxClick}
            />
          </Item>
        ))}
      </Items>
      <Divider className="my3" />
    </>
  );

  const disabled = projectName.length === 0 || projectDescription.length === 0;

  return (
    <Modal
      size="small"
      centered
      open={modalOpen}
      onClose={() => {
        onModalStateChange({ openModal: false, showCancel: true });
      }}
    >
      <div className="flex flex-column p3">
        <i
          className={`close ${loading ? 'disabled' : ''} `}
          onClick={() => {
            onModalStateChange({ openModal: false, showCancel: true });
          }}
        />
        <Title className="m0 mt1">{isEdit ? 'Edit project.' : 'Confirm a few details.'}</Title>
        {_.isEmpty(studies) ? <>{renderNoStudies()}</> : <>{renderStudies()}</>}
        <Form className="flex flex-column">
          <div>
            <div className="flex flex-column col col-5">
              <label htmlFor="name" className="pb2">
                Project name
              </label>
              <Input
                id="name"
                type="text"
                placeholder="A New Project"
                value={projectName}
                onChange={e => onProjectNameChange(e.target.value)}
              />
            </div>
            {isEdit ? (
              <div className="flex flex-column col-right col-6">
                <label htmlFor="id" className="pb2">
                  Workspace ID
                </label>
                <div className="flex">
                  <Input id="id" type="text" value={id} readOnly className="fit" ref={x => (workspaceIdRef = x)} />
                  <button
                    type="button"
                    className="flex workspaceId"
                    onClick={() => {
                      workspaceIdRef.select();
                      document.execCommand('copy');
                    }}
                  >
                    <Icon name="copy" />
                    Copy
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-column pt3">
            <label htmlFor="description" className="pb2">
              Description
            </label>
            <TextArea
              id="description"
              rows={5}
              className="p1"
              value={projectDescription}
              onChange={e => onProjectDescriptionChange(e.target.value)}
            />
          </div>
        </Form>
        <div className="mt4 custom-actions">
          <div className="col col-6 pr2">
            <button
              type="button"
              className={`primary-black ${disabled ? 'disabled' : ''} ${loading ? 'progress justify-center' : ''}`}
              onClick={submitHandler}
            >
              {loading ? (
                <>
                  <div className="mr2">
                    <i className="sync loading icon" />
                  </div>
                  {isEdit ? 'Updating' : 'Creating'} project...
                </>
              ) : isEdit ? (
                'Confirm edits'
              ) : (
                '⭐ Create project'
              )}
            </button>
          </div>
          <div type="button" className="col col-6 pl2">
            <button
              disabled={loading}
              className={`secondary ${loading ? 'disabled' : ''}`}
              type="button"
              onClick={() => onModalStateChange({ openModal: false, showCancel: true })}
            >
              {isEdit ? 'Cancel' : 'No, do later'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default DataProjectModal;
