import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';
import styled from 'styled-components';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import SingleChoiceQuestion from './SingleChoiceQuestion';
import { QuestionResult, ResultModal, ResultSection } from './QuizResult';
import { PaginatedProgress } from '../../components';

const PageSize = 5;

const QuestionType = {
  MultipleChoice: 'MultipleChoice',
  SingleChoice: 'SingleChoice',
};

const QuestionsContainer = styled.div`
  height: 100%;
  padding: 0px 150px 30px 150px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const QuestionOnlyContainer = styled.div`
  border-top: 1px solid #d7dbe3;
  padding: 64px 0px;
`;

const QuestionWithResultContainer = styled.div`
  padding: 64px;
  border-radius: 8px;
  background: #fafafa;
  box-shadow: 0px 5.647058486938477px 13.176470756530762px 0px rgba(150, 157, 171, 0.05);
  margin-bottom: 32px;
`;

const QuizContent = ({
  isCertificatePath,
  userLearningId,
  pathId,
  moduleId,
  quizInstance,
  defaultSubmissionResult,
  quizStore,
}) => {
  const [answers, setAnswers] = useState(new Map());
  const [pageIndex, setPageIndex] = useState(0);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [submissionResult, setSubmissionResult] = useState(defaultSubmissionResult);
  const ref = useRef(null);

  useEffect(() => {}, [answers]);

  const nextHandler = async index => {
    setPageIndex(index);
    ref.current.scroll({ top: 0, behavior: 'smooth' });
  };

  const submitHandler = async () => {
    try {
      setSubmissionInProgress(true);
      const submission = {
        userLearningId,
        pathId,
        moduleId,
        questions: Array.from(answers, ([questionId, choiceIds]) => ({ questionId, choiceIds })),
      };
      const result = await quizStore.createQuizInstanceSubmission(quizInstance.id, submission);
      setSubmissionInProgress(false);
      setSubmissionResult(result);
      setPageIndex(0);
      setModalOpen(true);
      ref.current.scroll({ top: 0, behavior: 'smooth' });
    } catch (error) {
      setSubmissionInProgress(false);
      displayError(error);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const renderQuestion = (question, index) => {
    const handleAddAnswer = (questionId, choices) => {
      answers.set(questionId, choices);
      setAnswers(new Map([...answers]));
    };

    const handleRemoveAnswer = questionId => {
      answers.delete(questionId);
      setAnswers(new Map([...answers]));
    };

    if (question.type === QuestionType.MultipleChoice)
      return (
        <MultipleChoiceQuestion
          index={pageIndex * PageSize + index}
          {...question}
          disabled={submissionInProgress || submissionResult}
          defaultChecked={
            submissionResult
              ? submissionResult.questions.find(q => q.questionId === question.id).choiceIds
              : answers.get(question.id)
          }
          onAddAnswer={handleAddAnswer}
          onRemoveAnswer={handleRemoveAnswer}
        />
      );
    if (question.type === QuestionType.SingleChoice)
      return (
        <SingleChoiceQuestion
          index={pageIndex * PageSize + index}
          {...question}
          disabled={submissionInProgress || submissionResult}
          defaultChecked={
            submissionResult
              ? submissionResult.questions.find(q => q.questionId === question.id).choiceIds[0]
              : answers.get(question.id)?.[0]
          }
          onAddAnswer={handleAddAnswer}
          onRemoveAnswer={handleRemoveAnswer}
        />
      );
    return <></>;
  };

  const questions = submissionResult
    ? quizInstance.questions
    : quizInstance.questions.slice(pageIndex * PageSize, pageIndex * PageSize + PageSize);
  return (
    <>
      {submissionResult ? <ResultSection submissionResult={submissionResult} /> : <></>}
      <QuestionsContainer className="overflow-auto" ref={ref}>
        {questions.map((q, i) =>
          submissionResult ? (
            <QuestionWithResultContainer key={q.id}>
              {renderQuestion(q, i)}
              <QuestionResult correct={!submissionResult.failedSubjects.includes(q.subject)} subject={q.subject} />
            </QuestionWithResultContainer>
          ) : (
            <QuestionOnlyContainer key={q.id}>{renderQuestion(q, i)}</QuestionOnlyContainer>
          ),
        )}
        {!submissionResult ? (
          <PaginatedProgress
            pageIndex={pageIndex}
            progress={answers.size}
            total={quizInstance.questions.length}
            pageSize={PageSize}
            progressIds={answers}
            totalIds={questions.map(q => q.id)}
            loading={submissionInProgress}
            onNext={nextHandler}
            onSubmit={submitHandler}
          />
        ) : (
          <></>
        )}

        <ResultModal
          open={modalOpen}
          isCertificatePath={isCertificatePath}
          submissionResult={submissionResult}
          userLearningId={userLearningId}
          onClose={handleCloseModal}
        />
      </QuestionsContainer>
    </>
  );
};

export default inject('quizStore')(withRouter(observer(QuizContent)));
