import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Container, Header, Icon, Segment } from 'semantic-ui-react';
import { isStoreReloading } from '@aws-ee/base-ui/dist/models/BaseStore';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import _ from 'lodash';
import styled from 'styled-components';
import Jobs from './parts/Jobs';
import JobDetails from './parts/JobDetails';
import Pagination from './parts/Pagination';

const Content = styled.div`
  margin-top: 32px;
`;

const PageSize = 5;
const PaginationItemsCount = 4;

const JobsPage = ({ jobsStore }) => {
  const [selectedJobId, setSelectedJobId] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [lastJobKey, setLastJobKey] = useState();

  useEffect(() => {
    swallowError(jobsStore.load(PageSize, lastJobKey));
  }, [lastJobKey]);

  const handlePageChange = i => {
    setSelectedJobId(undefined);
    setCurrentPage(i);
    if (i === 0) setLastJobKey(undefined);
    else setLastJobKey(jobsStore.ids[i * PageSize - 1]);
  };

  const renderTitle = () => {
    return (
      <div className="flex justify-between">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Header.Content className="left-align">Jobs</Header.Content>
          <Header.Subheader className="left-align">
            Find your next opportunity with our partnered organizations.
          </Header.Subheader>
        </Header>
      </div>
    );
  };

  const renderContent = () => {
    if (jobsStore.error) {
      return <ErrorBox error={jobsStore.error} className="mt3 mr0 ml0" />;
    }
    if (jobsStore.loading || isStoreReloading(jobsStore)) {
      return <BasicProgressPlaceholder segmentCount={3} className="mt3 mr0 ml0" />;
    }
    if (jobsStore.ready && (jobsStore.jobs.length === 0 || jobsStore.ids.length === 0)) {
      return (
        <Segment placeholder className="mt3">
          <Header icon className="color-grey">
            <Icon name="clipboard outline" />
            No jobs available.
          </Header>
        </Segment>
      );
    }
    if (jobsStore.ready) {
      let job = null;
      if (selectedJobId) job = jobsStore.jobs.find(j => j.id === selectedJobId);
      else job = jobsStore.jobs[0];
      return (
        <>
          <Content>
            <div className="clearfix">
              <Jobs jobs={jobsStore.jobs} selectedJobId={job.id} onChange={id => setSelectedJobId(id)} />
              <JobDetails job={job} />
            </div>
            <div className="clearfix">
              <Pagination
                defaultPage={currentPage}
                pageCount={Math.ceil(jobsStore.ids.length / PageSize)}
                itemsCount={PaginationItemsCount}
                onChange={handlePageChange}
              />
            </div>
          </Content>
        </>
      );
    }
    return <></>;
  };

  return (
    <Container className="mt4 pb4">
      {renderTitle()}
      {renderContent()}
    </Container>
  );
};

export default withRouter(inject('jobsStore')(observer(JobsPage)));
