import React from 'react';
import { StepInput } from '.';
import styled from 'styled-components';
import { Icon, Popup, Radio } from 'semantic-ui-react';
import JsonExample from '../ParametersStep/JsonExample';
import { ParameterType } from '../../../../../models/workflow/ParameterType';
import { useState } from 'react';

const Container = styled.div`
  * {
    font-family: IBM Plex Sans;
    font-size: 16px;
    color: #2e2d29;
  }

  label {
    font-weight: 700;
    line-height: 16px;
    &.w400 {
      font-weight: 400;
    }
  }

  input {
    display: block;
    margin-top: 16px;
    font-family: IBM Plex Mono;
    &.wd-50 {
      width: 50%;
    }
  }

  .checkbox {
    display: block;
    margin-top: 16px;
    > label {
      font-weight: 400;
    }
  }

  textarea {
    display: block;
    width: 100%;
    min-height: 200px;
    margin-top: 16px;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #d7dbe3;
    background: #f5f5f5;
    font-weight: 400;
    line-height: 135%;
    resize: none;
    outline: none;

    &.error {
      border: 1px solid red;
    }
  }

  span {
    display: block;
    margin: 16px 0px 0px 18px;
  }

  button {
    height: 40px;
    margin-left: 16px;
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    background: #eaeaea;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.1px;
    cursor: pointer;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
`;

const CustomPopup = styled(Popup)`
  padding: 0px !important;
  border: none !important;
  border-radius: 4px !important;
  border: 1px solid #d7dbe3 !important;
  background: #fff !important;
  box-shadow: 0px 15px 40px 0px rgba(46, 45, 41, 0.15) !important;
`;

const JsonTextArea = ({ hint, readonly, defaultValue, onParameterChange }) => {
  const [error, setError] = useState();
  return (
    <textarea
      placeholder={hint}
      readOnly={readonly}
      defaultValue={defaultValue ? JSON.stringify(defaultValue, null, 2) : ''}
      className={error ? 'error' : ''}
      onChange={e => {
        try {
          const json = JSON.parse(e.target.value);
          onParameterChange(json);
          setError(false);
        } catch (error) {
          setError(true);
        }
      }}
    />
  );
};

const Parameter = ({
  index,
  parameterName,
  defaultValue,
  readonly,
  type,
  label,
  hint,
  values,
  members,
  example,
  isNested,
  onParameterChange,
}) => {
  const renderInput = () => {
    switch (type) {
      case ParameterType.String:
        return (
          <StepInput
            placeholder={hint}
            readOnly={readonly}
            defaultValue={defaultValue}
            className="wd-50"
            onChange={e => onParameterChange(parameterName, e.target.value)}
          />
        );
      case ParameterType.Radio:
        return !readonly ? (
          values.map(v => (
            <Radio key={v} label={v} checked={v == defaultValue} onClick={() => onParameterChange(parameterName, v)} />
          ))
        ) : (
          <span>{defaultValue}</span>
        );
      case ParameterType.Json:
        return (
          <JsonTextArea
            hint={hint}
            readonly={readonly}
            defaultValue={defaultValue}
            onParameterChange={v => onParameterChange(parameterName, v)}
          />
        );
      case ParameterType.Group:
        return (
          <Group>
            {Object.keys(members)
              .map(key => ({
                parameterName: key,
                ...members[key],
              }))
              .sort((a, b) => a.order - b.order)
              .map((member, index) => (
                <Parameter
                  key={member.parameterName}
                  index={index}
                  parameterName={member.parameterName}
                  isNested={true}
                  readonly={readonly}
                  defaultValue={defaultValue ? defaultValue[member.parameterName] : undefined}
                  {...member}
                  onParameterChange={(key, value) => onParameterChange(parameterName, { [key]: value })}
                />
              ))}
          </Group>
        );
    }
  };

  const labelText = isNested ? label : `${index + 1}. ${label}`;
  return (
    <Container index={index}>
      {label ? <label className={isNested ? 'w400' : ''}>{labelText}</label> : <></>}
      {example && !readonly ? (
        <CustomPopup
          trigger={
            <button>
              <Icon name="file alternate outline" />
              See example
            </button>
          }
          on="click"
          content={<JsonExample example={example} />}
          position="right center"
          pinned
          wide
          basic
        />
      ) : (
        <></>
      )}
      {type ? renderInput() : <></>}
    </Container>
  );
};

export default Parameter;
