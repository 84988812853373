import _ from 'lodash';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import ActionsButton from './ActionsButton';

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #d7dbe3;
  border-radius: 8px;
`;

const Title = styled.p`
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #2e2d29;
`;

const Discription = styled.p`
  font-family: 'IBM Plex Sans';
  font-weight: 450;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: 0.2px;
  color: #2e2d29;
  a {
    font-weight: 700;
    color: #2e2d29;
  }
`;

const VideoContainer = styled.div`
  height: 450px;
  video {
    height: 100%;
  }
`;

const Study = props => {
  useEffect(() => {}, [props.study.workspace.status]);

  const descriptionLinkRegex = /\((?:https?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_+.~#?&//=]*)\)/;
  const linkMatches = props.study.description.match(descriptionLinkRegex);
  const description =
    !_.isNil(linkMatches) && linkMatches.length > 0
      ? props.study.description.replace(descriptionLinkRegex, '')
      : props.study.description;

  return (
    <Container className="mt3 p3">
      <div className="flex justify-between">
        <div className="col-8">
          <Title>{props.study.name}</Title>
          <Discription>
            {description}{' '}
            {!_.isNil(linkMatches) && linkMatches.length > 0 ? (
              <a
                href={linkMatches[0].substring(1, linkMatches[0].length - 1)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Read Publication &gt;
              </a>
            ) : (
              ''
            )}
          </Discription>
        </div>
        <div className="col-right">
          <ActionsButton study={{ ...props.study }} workspace={{ ...props.study.workspace }} />
        </div>
      </div>
      <VideoContainer className="video mt3">
        <video controls className="col-8 pr3">
          <source
            src={`https://351869726285-sdo-prd-va-sdo-prd-study-videos.s3.us-east-2.amazonaws.com/${props.study.studyId}.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </VideoContainer>
    </Container>
  );
};

export default Study;
