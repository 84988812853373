import React, { useState } from 'react';
import _ from 'lodash';
import Choice from './Choice';
import CheckboxWrapper from './CheckboxWrapper';
import { QuestionHeader, QuestionIndex, QuestionStatement } from '../../../components';

const MultipleChoiceQuestion = ({
  id,
  index,
  statement,
  choices,
  attachments,
  disabled,
  defaultChecked,
  onAddAnswer,
  onRemoveAnswer,
}) => {
  const [checkboxs, setCheckboxs] = useState(defaultChecked ?? []);
  const checkboxChangeHandler = (_, data) => {
    let answers = [];
    if (data.checked) answers = [...checkboxs, data.value];
    else {
      const index = checkboxs.indexOf(data.value);
      if (index !== -1) {
        checkboxs.splice(index, 1);
        answers = checkboxs;
      }
    }
    setCheckboxs(answers);
    if (answers.length > 0) onAddAnswer(id, answers);
    else onRemoveAnswer(id);
  };

  return (
    <div>
      <QuestionHeader>
        <QuestionIndex>{`Question ${index + 1}`}</QuestionIndex>
        <QuestionStatement>{statement}</QuestionStatement>
        {!_.isNil(attachments) && attachments.length > 0 && attachments[0].type === 'Image' ? (
          <img className="col-9" src={attachments[0].path} alt="attachment" />
        ) : (
          ''
        )}
      </QuestionHeader>
      <div className="mt3">
        {choices.map(a => (
          <Choice className={`col-9 mt2 px3 py2 ${checkboxs.includes(a.id) ? 'checked' : ''}`}>
            <CheckboxWrapper
              id={a.id}
              key={a.id}
              type="checkbox"
              checked={checkboxs.includes(a.id)}
              value={a.id}
              disabled={disabled}
              onChange={checkboxChangeHandler}
            />
            <label htmlFor={a.id}>{a.value}</label>
          </Choice>
        ))}
      </div>
    </div>
  );
};

export default MultipleChoiceQuestion;
