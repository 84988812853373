import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import MenuItem from './MenuItem';
import UserLearning from './UserLearning';

const MenuHeader = styled.div`
  font-family: 'IBM Plex Sans';
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
`;

const CustomButton = styled.button`
  padding: 10px 16px;
  border: 1px solid #279989;
  border-radius: 100px;
  font-weight: 400;
  color: ${p => (p.showMenu ? '#FFFFFF' : '#279989')};
  background-color: ${p => (p.showMenu ? '#279989' : '#F5F5F5')};
  cursor: pointer;
  i {
    transform: ${p => (p.showMenu ? 'rotateZ(-90deg)' : 'none')};
    transition: all 0.4s ease;
    margin-right: 0px !important;
  }
`;

const MenuItems = styled.div`
  display: flex;
  margin-top: 16px;
`;

const LearningMenu = ({ userLearnings, defaultUserLearningId, learningStore }) => {
  const [selectedUserLearningId, setSelectedUserLearningId] = useState(defaultUserLearningId);
  const [selectedUserLearning, setSelectedUserLearning] = useState(
    userLearnings.find(l => l.id === selectedUserLearningId),
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const userLearning = await learningStore.getUserLearning(selectedUserLearningId);
      setSelectedUserLearning(userLearning);
      setLoading(false);
    };

    setLoading(true);
    fetchData().catch(e => {
      setLoading(false);
      setError(e.message);
    });
  }, [selectedUserLearningId]);

  const renderContent = () => {
    if (error) return <ErrorBox error={error} className="mt3 mr0 ml0" />;
    if (loading || !(selectedUserLearning.id === selectedUserLearningId && selectedUserLearning.paths))
      return <BasicProgressPlaceholder segmentCount={2} className="mt3 mr0 ml0" />;
    return (
      <UserLearning
        id={selectedUserLearning.id}
        name={selectedUserLearning.name}
        subscriptionRequired={selectedUserLearning.subscriptionRequired}
        subscription={selectedUserLearning.subscription}
        paths={selectedUserLearning.paths}
        certificateProgram={selectedUserLearning.certificateProgram}
      />
    );
  };

  return (
    <>
      <MenuHeader className="mt2">
        Select a study roadmap that best fits your goal.
        <CustomButton type="button" className="ml2" showMenu={showMenu} onClick={() => setShowMenu(!showMenu)}>
          {selectedUserLearning.name} <Icon name="angle down" />
        </CustomButton>
      </MenuHeader>
      {showMenu ? (
        <MenuItems>
          {userLearnings.map(ul => (
            <MenuItem
              key={ul.id}
              {...ul}
              active={ul.id === selectedUserLearningId}
              onClick={id => {
                setError(undefined);
                setSelectedUserLearningId(id);
              }}
            />
          ))}
        </MenuItems>
      ) : (
        <></>
      )}
      {renderContent()}
    </>
  );
};

export default inject('learningStore', 'currentUserScEnvironmentsStore')(withRouter(observer(LearningMenu)));
