import React from 'react';
import styled from 'styled-components';
import laptopCertificateImg from '../../../../images/static/certificate-announcement/laptop-certificate.png';
import backgroundImg1 from '../../../../images/static/certificate-announcement/background-1.svg';
import backgroundImg2 from '../../../../images/static/certificate-announcement/background-2.svg';
import backgroundImg3 from '../../../../images/static/certificate-announcement/background-3.svg';
import Logo from '../parts/Logo';
import RocketButton from '../parts/RocketButton';

const Container = styled.div`
  width: 100%;
  min-height: 1024px;
  display: grid;
  grid-template: 1fr / 1fr;
  > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
`;

const CustomLogo = styled(Logo)`
  margin: 41px 48px;
`;

const Background1 = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImg1});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Background2 = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImg2});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const Background3 = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImg3});
  background-repeat: no-repeat;
  background-size: cover;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.5em;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-left: 48px;
  width: 40%;
  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
    margin: 0px;
    padding: 0px 20px;
    text-align: center;
  }
`;

const Title = styled.h1`
  color: #2e2d29;
  font-size: 54px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -1.08px;
  margin: 24px 0px 0px 0px;
`;

const SubTitle = styled.h2`
  font-size: 24px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 2.64px;
  color: #e98300;
  margin: 0px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  margin: 32px 0px;
`;

const Note = styled.p`
  color: #53565a;
  font-size: 16px;
  line-height: 135%;
  margin: 32px 0px;
`;

const Img = styled.img`
  width: 50%;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

const Annoncement = ({ title, description, registerUrl }) => {
  return (
    <Container>
      <Background3 />
      <Background2 />
      <Background1 />
      <div>
        <CustomLogo />
        <ContentContainer>
          <Content>
            <SubTitle>SELF-PACED ONLINE CERTIFICATE</SubTitle>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <RocketButton url={registerUrl}>Register Today</RocketButton>
            <Note>Program registration is open on Eventbrite.</Note>
          </Content>
          <Img src={laptopCertificateImg} alt="laptop-certificate" />
        </ContentContainer>
      </div>
    </Container>
  );
};

export default Annoncement;
