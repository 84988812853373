import styled from 'styled-components';

const Title = styled.h1`
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 32px;
  line-height: 125%;
  font-size: 32px;
  letter-spacing: 3.52px;
  text-align: center;
  margin: 0px 0px 2.5em 0px;
`;

export default Title;
