import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { useRef } from 'react';
import TimeAgo from 'react-timeago';
import { Button, Divider, Grid, Header, Icon, Label, Modal, Popup } from 'semantic-ui-react';
import By from '../helpers/By';

const ScContainerEnvironmentCard = props => {
  const [loading, setLoading] = useState(false);
  let textArea = useRef(null);

  const renderStatus = state => {
    let stateIcon = <></>;
    switch (state.display) {
      case 'FAILED':
        stateIcon = <i aria-hidden="true" class="cancel icon"></i>;
        break;
      case 'AVAILABLE':
        stateIcon = <i aria-hidden="true" class="circle icon"></i>;
        break;
      case 'PAUSED':
        stateIcon = <i aria-hidden="true" class="pause icon"></i>;
        break;
      case 'STOPPED':
        stateIcon = <i aria-hidden="true" class="square icon"></i>;
        break;
      case 'TERMINATED':
        stateIcon = <i aria-hidden="true" class="ban icon"></i>;
        break;
      default:
        stateIcon = <span></span>;
        break;
    }
    return (
      <div style={{ cursor: 'default' }}>
        <Popup
          trigger={
            <Label attached="top left" size="mini" color={state.color}>
              {state.spinner && <Icon name="spinner" loading />}
              {stateIcon}
              {state.display}
            </Label>
          }
        >
          {state.tip}
        </Popup>
      </div>
    );
  };

  const renderTitle = env => {
    return (
      <Header as="h3" className="mt1">
        {env.name}
        <Header.Subheader>
          <span className="fs-8 color-grey">
            Created <TimeAgo date={env.createdAt} className="mr2" /> <By uid={env.createdBy} className="mr2" />
          </span>
        </Header.Subheader>
      </Header>
    );
  };

  const renderButtons = env => {
    const handleAction = async fn => {
      setLoading(true);
      try {
        await fn();
      } catch (error) {
        displayError(error);
      } finally {
        runInAction(() => {
          setLoading(false);
        });
      }
    };

    const handleStart = () => {
      handleAction(() => props.scEnvironmentsStore.startScContainerEnvironment(env.id));
    };

    const handleStop = () => {
      handleAction(() => props.scEnvironmentsStore.stopScContainerEnvironment(env.id));
    };

    const handleTerminate = () => {
      handleAction(() => props.scEnvironmentsStore.terminateScContainerEnvironment(env.id));
    };

    const handleConnect = () => {
      window.open(env.url, '_blank', 'noopener,noreferrer');
    };

    const canTerminate = env.state.canTerminate && env.envTypeConfigId === 'statefull-container';
    const canConnect = env.url !== null && env.state.display === 'AVAILABLE';
    const canStart = env.state.canStart;
    const canStop = env.state.canStop;

    return (
      <div class="flex-wrapper--row workspaces-flex flex--wrap">
        <div className="env-buttons--wrapper" style={{ minHeight: '42px' }}>
          {canTerminate && (
            <Modal
              trigger={
                <div>
                  <div className="headerDivider buttonsDivider"></div>
                  <Button
                    data-testid="sc-env-terminate"
                    floated="right"
                    basic
                    color="red"
                    size="mini"
                    className="mt1 mb1"
                    loading={loading}
                  >
                    Terminate
                  </Button>
                </div>
              }
              header="Are you sure?"
              content="This action can not be reverted."
              actions={['Cancel', { key: 'terminate', content: 'Terminate', negative: true, onClick: handleTerminate }]}
              size="mini"
              className="terminate-modal"
            />
          )}
          {canStart && (
            <div>
              <div class="headerDivider"></div>
              <Button
                data-testid="sc-env-start"
                floated="right"
                basic
                color="green"
                size="mini"
                className="mt1 mb1"
                onClick={handleStart}
                loading={loading}
              >
                Start
              </Button>
            </div>
          )}
          {canStop && (
            <div>
              <div class="headerDivider"></div>
              <Button
                data-testid="sc-env-stop"
                floated="right"
                basic
                color="orange"
                size="mini"
                className="mt1 mb1"
                onClick={handleStop}
                loading={loading}
              >
                Stop
              </Button>
            </div>
          )}
          {canConnect && (
            <Button floated="left" basic size="mini" className="mt1 mb1 ml2" toggle onClick={handleConnect}>
              Connect
            </Button>
          )}
        </div>
      </div>
    );
  };

  const handleCopy = () => {
    textArea.select();
    document.execCommand('copy');
  };

  const renderRow = (key, value, buttonName, buttonAction, buttonIcon) => (
    <div className="col col-6 workspace-details--wrapper">
      <span className="workspace-details--label">{key}</span>
      {buttonName ? (
        <div className="textarea--wrapper">
          <textarea className="workspace-details--content textarea" readonly ref={r => (textArea = r)} value={value}>
            {value}
          </textarea>
          <button onClick={buttonAction} className="workspace-details--button">
            <i aria-hidden="true" className={buttonIcon}></i>
            {buttonName}
          </button>
        </div>
      ) : (
        <div className="workspace-details--content">{value}</div>
      )}
    </div>
  );

  const studyIds = _.get(props.scEnvironment, 'studyIds', []);
  const studyCount = _.size(_.get(props.scEnvironment, 'studyIds', []));
  return (
    <div>
      <div className="flex-wrapper--row workspaces-flex flex--wrap" style={{ marginLeft: '-.75em' }}>
        {renderStatus(props.scEnvironment.state)}
        {renderButtons(props.scEnvironment)}
      </div>
      {renderTitle(props.scEnvironment)}
      <Divider className="mt1" />
      {props.scEnvironment.description || 'No description was provided for this workspace.'}
      <Grid columns={2} stackable className="mt2">
        <Grid.Row stretched>
          <Grid.Column>
            {renderRow('Data from', studyCount === 0 ? 'No studies linked to this workspace' : studyIds.join(', '))}
          </Grid.Column>
          <Grid.Column>
            {renderRow('Workspace ID', props.scEnvironment.id, 'Copy', handleCopy, 'copy outline icon')}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched>
          <Grid.Column>
            {renderRow('Instance ID', props.scEnvironment.instanceId, 'Copy', handleCopy, 'copy outline icon')}
          </Grid.Column>
          <Grid.Column>
            {renderRow('Instance IP', props.scEnvironment.instanceIp ?? 'N/A', 'Copy', handleCopy, 'copy outline icon')}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default inject('scEnvironmentsStore')(observer(ScContainerEnvironmentCard));
