import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 412px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const Title = styled.h2`
  margin: 20px 0px 24px 0px;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
`;

const Description = styled.p`
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;

const Section = ({ title, icon, description, useRelativePath, className }) => (
  <Container className={className}>
    <Icon src={useRelativePath ? `${window.location.origin}/static/${icon}` : icon} alt={icon} />
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Container>
);

export default Section;
