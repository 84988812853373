import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  font-family: 'IBM Plex Mono';
  font-weight: 400;
  font-size: 24px;
  line-height: 16px;
  padding: 24px 28px;
  height: 64px;
  border-radius: 8px;
  color: #ffffff;
  background: #2e2d29;
  border: none;
  cursor: pointer;
`;

const RocketButton = ({ url, children }) => (
  <Button onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}>
    <span role="img" aria-label="rocket">
      🚀
    </span>{' '}
    {children}
  </Button>
);

export default RocketButton;
