import React, { useState } from 'react';
import _ from 'lodash';
import { QuestionHeader, QuestionIndex, QuestionStatement } from '../../../components';
import Choice from '../MultipleChoiceQuestion/Choice';
import RadioWrapper from './RadioWrapper';

const SingleChoiceQuestion = ({
  id,
  index,
  statement,
  choices,
  attachments,
  disabled,
  defaultChecked,
  onAddAnswer,
}) => {
  const [radio, setRadio] = useState(defaultChecked);

  const radioChangeHandler = (_, data) => {
    if (data.checked) {
      setRadio(data.value);
      onAddAnswer(id, [data.value]);
    }
  };

  return (
    <div>
      <QuestionHeader>
        <QuestionIndex>{`Question ${index + 1}`}</QuestionIndex>
        <QuestionStatement>{statement}</QuestionStatement>
        {!_.isNil(attachments) && attachments.length > 0 && attachments[0].type === 'Image' ? (
          <img className="col-9" src={attachments[0].path} alt="attachment" />
        ) : (
          ''
        )}
      </QuestionHeader>
      <div className="mt3">
        {choices.map(a => (
          <Choice className={`col-9 mt2 px3 py2 ${radio === a.id ? 'checked' : ''}`}>
            <RadioWrapper
              id={a.id}
              key={a.id}
              name={id}
              value={a.id}
              checked={radio === a.id}
              disabled={disabled}
              onChange={radioChangeHandler}
            />
            <label htmlFor={a.id}>{a.value}</label>
          </Choice>
        ))}
      </div>
    </div>
  );
};

export default SingleChoiceQuestion;
