/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars
import { httpApiDelete, httpApiGet, httpApiPost, httpApiPut } from '@aws-ee/base-ui/dist/helpers/api';

function getUserLearnings() {
  return httpApiGet('api/user-learnings');
}

function getUserLearning(id) {
  return httpApiGet(`api/user-learnings/${id}`);
}

function startUserLearningModule(userLearningId, pathId, moduleId) {
  return httpApiPut(`api/user-learnings/${userLearningId}/path/${pathId}/${moduleId}/start`);
}

function activateUserLearningSubscription(userLearningId, subscription) {
  return httpApiPut(`api/user-learnings/${userLearningId}/subscription/activate`, {
    data: subscription,
  });
}
function getQuiz(id) {
  return httpApiGet(`api/quiz/${id}`);
}

function createQuizInstance(id) {
  return httpApiPost(`api/quiz/${id}/instance`);
}

function createQuizInstanceSubmission(id, submission) {
  return httpApiPost(`api/quiz/instance/${id}/submission`, { data: submission });
}

function getQuizSubmissionResult(id) {
  return httpApiGet(`api/quiz/${id}/submission/result`);
}

function getSurvey(id) {
  return httpApiGet(`api/survey/${id}`);
}

function createSurveySubmission(id, submission) {
  return httpApiPost(`api/survey/${id}/submission`, { data: submission });
}

function getDataTypes() {
  return httpApiGet(`api/datatypes`);
}

function getPhenotypes() {
  return httpApiGet(`api/phenotypes`);
}

function createDataProjects(dataProject) {
  return httpApiPost(`api/data-projects`, { data: dataProject });
}

function updateDataProject(id, dataProject) {
  return httpApiPut(`api/data-projects/${id}`, { data: dataProject });
}

function getDataProjects() {
  return httpApiGet(`api/data-projects`);
}

function deleteDataProject(id) {
  return httpApiDelete(`api/data-projects/${id}`);
}

function getWorkflows() {
  return httpApiGet(`api/workflows`);
}

function createWorkflowPolicy(policyConfig) {
  return httpApiPost(`api/workflows/policy`, { data: policyConfig });
}

function getCurrentWorkflowConfig() {
  return httpApiGet(`api/workflows/config/current`);
}

function testWorkflowS3Access(accessConfig) {
  return httpApiPost(`api/workflows/s3/test-access`, { data: accessConfig });
}

function createWorkflowRun(name, workflowRun) {
  return httpApiPost(`api/workflows/${name}/run`, { data: workflowRun });
}

function getWorkflowRuns() {
  return httpApiGet(`api/workflows/runs`);
}

function getJobIds() {
  return httpApiGet(`api/jobs/ids`);
}

function getJobs(limit = 10, startKey = null) {
  if (startKey) return httpApiGet(`api/jobs?limit=${limit}&startKey=${startKey}`);
  return httpApiGet(`api/jobs?limit=${limit}`);
}

function getJob(id) {
  return httpApiGet(`api/jobs/${id}`);
}

function createJobApplication(jobId) {
  return httpApiPost(`api/jobs/${jobId}/application`);
}

function toggleJobBookmark(jobId) {
  return httpApiPut(`api/jobs/${jobId}/toggle-bookmark`);
}

export {
  getUserLearnings,
  getUserLearning,
  startUserLearningModule,
  activateUserLearningSubscription,
  getQuiz,
  createQuizInstance,
  getQuizSubmissionResult,
  createQuizInstanceSubmission,
  getSurvey,
  createSurveySubmission,
  getDataTypes,
  getPhenotypes,
  createDataProjects,
  updateDataProject,
  deleteDataProject,
  getDataProjects,
  getWorkflows,
  createWorkflowPolicy,
  getCurrentWorkflowConfig,
  testWorkflowS3Access,
  createWorkflowRun,
  getWorkflowRuns,
  getJobIds,
  getJob,
  getJobs,
  createJobApplication,
  toggleJobBookmark,
};
