import styled from 'styled-components';
import copyImg from '../../../../../images/copy.svg';

const CopyButton = styled.button`
  position: absolute;
  right: 16px;
  display: inline-flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  border: none;
  background: linear-gradient(0deg, rgba(46, 45, 41, 0.15) 0%, rgba(46, 45, 41, 0.15) 100%), #fff;
  color: #2e2d29;
  font-family: IBM Plex Mono;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  ::before {
    width: 20px;
    height: 20px;
    content: url(${copyImg});
  }
`;

export default CopyButton;
