import React from 'react';
import { Modal } from 'semantic-ui-react';

const PauseConfirmationModal = ({ modalOpen, onSubmit, onCancel }) => (
  <Modal size="tiny" centered open={modalOpen} onClose={onCancel}>
    <div className="flex flex-column p3">
      <i className="close" onClick={onCancel} />
      <h2 className="m0 mt1 self-center">
        <span role="img" aria-label="pause">
          ⏸️
        </span>{' '}
        Are you sure you want to pause the VM?
      </h2>
      <p className="my3">
        Your work will be saved, and can be accessed at a later time.
        <br />
        <br />
        Please always pause the virtual machine when you are not actively working on the project. This allows Stanford
        Data Ocean to spend the saved cost to other students who are in need of the computing resources.
      </p>
      <div className="mt3 custom-actions">
        <div className="col col-6 pr2">
          <button type="button" className="primary-black" onClick={onSubmit}>
            Yes, pause VM
          </button>
        </div>
        <div className="col col-6 pl2">
          <button type="button" className="secondary" onClick={onCancel}>
            No, I’m still working
          </button>
        </div>
      </div>
    </div>
  </Modal>
);

export default PauseConfirmationModal;
